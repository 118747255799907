import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { EFullpitState, Fullpit, NotificationMessage } from 'fullpits-commons';

@Component({
  selector: 'app-dialog-reprogramar-servicio',
  templateUrl: './dialog-reprogramar-servicio.component.html',
  styleUrls: ['./dialog-reprogramar-servicio.component.scss'],
})
export class DialogReprogramarServicioComponent implements OnInit {
  @Input() fullpit: Fullpit;
  reprogramarServicioForm: UntypedFormGroup;
  notificationMessage: NotificationMessage;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogReprogramarServicioComponent>
  ) {}

  ngOnInit(): void {
    this.fullpit = this.data.fullpit;
    this.initFormulario();
  }

  initFormulario() {
    this.reprogramarServicioForm = this.fb.group({
      dateScheduled: [this.fullpit.dateScheduled, Validators.required],
    });
  }

  reprogramarServicio() {
    this.fullpit.dateScheduled = dayjs(
      this.reprogramarServicioForm.controls['dateScheduled'].value
    ).toDate();
    this.fullpit.stateId = EFullpitState.EN_ESPERA;
    this.fullpit.stateName = 'Programado';
    this.dialogRef.close(this.fullpit);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiciosComponent } from './servicios.component';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { MaterialModule } from 'src/app/material/material.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ServiciosComponent],
  imports: [CommonModule, SharedModule, MaterialModule, RouterModule],
  exports: [ServiciosComponent],
})
export class ServiciosModule {}

<div class="container-fluid bg">
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-center align-items-center pt-3 px-3">
        <div class="col-md-5">
          <!-- <button class="btn btn-light btn-lg fw-bold w-100">
            Ver todos los servicios
          </button> -->
          <button
            class="btn btn-dark btn-lg fw-bold w-100"
            (click)="traerOpcionSolicitud()"
          >
            Solicitar servicio
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid p-0">
  <div class="row bg2 py-4 py-lg-5">
    <div class="">
      <h1 class="text-center text-llegamos fw-bold">Llegamos donde estés</h1>
      <p class="text-center h3 text-llegamos-sub mt-lg-4 mt-1">
        Deja tu vehículo <span class="text-rojo">en manos de expertos</span>
      </p>
    </div>

    <app-img-circle [serviciosHome]="imagenesServiciosHome"></app-img-circle>
  </div>
</div>

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Taller } from 'fullpits-commons';
import { JwtUtils } from '../utils/jwt-utils';

@Injectable({
  providedIn: 'root',
})
export class VariableGlobalService {
  private ubicacion = new BehaviorSubject(null);
  currentMessage = this.ubicacion.asObservable();

  private listadoTalleres = new BehaviorSubject('');
  actualTalleres = this.listadoTalleres.asObservable();

  private direccion = new BehaviorSubject('');
  actualDireccion = this.direccion.asObservable();

  private webToken = new BehaviorSubject('');
  actualToken = this.webToken.asObservable();

  private user = new BehaviorSubject(null);
  actualUser = this.user.asObservable();

  private ocultarFooter = new BehaviorSubject(false);
  actualOcultarFooter = this.ocultarFooter.asObservable();

  private contarNotificaciones = new BehaviorSubject(0);
  actualContadorNotificacion = this.contarNotificaciones.asObservable();

  private misVehiculos = new BehaviorSubject({});
  actualVehiculos = this.misVehiculos.asObservable();
  private misSolicitudes = new BehaviorSubject('');
  actualSolicitud = this.misSolicitudes.asObservable();
  private notificacionPush = new BehaviorSubject({});
  actualNotificacion = this.notificacionPush.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    this.consultarUbicacion();
  }

  consultarUbicacion() {
    if (isPlatformBrowser(this.platformId)) {
      this.ubicacion.next(JSON.parse(`${sessionStorage.getItem('ubicacion')}`));
    }
  }

  consultarDireccion() {
    if (isPlatformBrowser(this.platformId)) {
      this.direccion.next(`${sessionStorage.getItem('direccion')}`);
    }
  }

  asignarListadoTalleres() {
    if (isPlatformBrowser(this.platformId)) {
      this.listadoTalleres.next(`${sessionStorage.getItem('busqueda')}`);
    }
  }
  consultarToken() {
    if (isPlatformBrowser(this.platformId)) {
      this.webToken.next(`${localStorage.getItem(JwtUtils.WEB_TOKEN)}`);
    }
  }
  consultarUsuario() {
    if (isPlatformBrowser(this.platformId)) {
      this.user.next(
        JSON.parse(
          `${
            sessionStorage.getItem('user')
              ? sessionStorage.getItem('user')
              : localStorage.getItem('user')
          }`
        )
      );
    }
  }
  consultarVehiculos() {
    this.misVehiculos.next({});
  }
  consultarSolicitudes(taller: Taller[]) {
    if (isPlatformBrowser(this.platformId)) {
      this.misSolicitudes.next(
        `${sessionStorage.setItem('talleres', JSON.stringify(taller))}`
      );
    }
  }
  consultarSiEsChat(isChat: boolean) {
    this.ocultarFooter.next(isChat);
  }
  consultarContadorNotificacion(contador: number) {
    this.contarNotificaciones.next(contador);
  }
}

import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Color } from 'src/app/models/color';
import { Menu } from 'src/app/models/menu';
import { Solicitar } from 'src/app/models/solicitar-servicios';
import { Fuel, Linea, Marca, Modelo } from 'src/app/models/vehiculo';
import { WorkShopNearUseRequest } from 'fullpits-commons';
import { ColorVehiculoComponent } from 'src/app/modulos/dialog/agregar-vehiculo/color-vehiculo/color-vehiculo.component';
import { CombustibleVehiculoComponent } from 'src/app/modulos/dialog/agregar-vehiculo/combustible-vehiculo/combustible-vehiculo.component';
import { LineaVehiculoComponent } from 'src/app/modulos/dialog/agregar-vehiculo/linea-vehiculo/linea-vehiculo.component';
import { MarcaVehiculoComponent } from 'src/app/modulos/dialog/agregar-vehiculo/marca-vehiculo/marca-vehiculo.component';
import { ModeloVehiculoComponent } from 'src/app/modulos/dialog/agregar-vehiculo/modelo-vehiculo/modelo-vehiculo.component';
import { TipoVehiculoComponent } from 'src/app/modulos/dialog/agregar-vehiculo/tipo-vehiculo/tipo-vehiculo.component';
import { SeleccionarLubricanteComponent } from 'src/app/modulos/dialog/solicitar-servicio/seleccionar-lubricante/seleccionar-lubricante.component';
import { SeleccionarServiciosComponent } from 'src/app/modulos/dialog/solicitar-servicio/seleccionar-servicios/seleccionar-servicios.component';
import { SeleccionarVehiculoComponent } from 'src/app/modulos/dialog/solicitar-servicio/seleccionar-vehiculo/seleccionar-vehiculo.component';
import { UbicacionActualComponent } from 'src/app/modulos/dialog/ubicacion-actual/ubicacion-actual.component';
import menuJSON from '../../../../../assets/json/menu-vehiculos.json';
import {
  Fullpit,
  FullpitService,
  NotificationService,
  PersonaService,
  WorkshopsService,
  Vehiculo,
  VehiculoService,
  Person,
  TypeLubricant,
  TypeService,
  Taller,
  Workshop,
  NotificationMessage,
} from 'fullpits-commons';
import { VariableGlobalService } from '../../services/variable-global.service';
import moment from 'moment';
import { EFullpitState } from 'fullpits-commons';
import { PushNotificationService } from '../../services/push-notification.service';
import { isPlatformBrowser } from '@angular/common';
import { SnackbarService } from '../../services/snackbar.service';
import { ECategoryService } from 'fullpits-commons';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { UserValidate } from '../../../../models/userValidate';
import { FullpitFirestore } from 'fullpits-commons';
import { ToastrService } from 'ngx-toastr';
import { ListadosTalleresService } from '../../services/listados-talleres.service';
import { TipoVehiculo } from 'src/app/models/tipo-vehiculos';
import { EditVehicleComponent } from 'src/app/modulos/dialog/edit-vehicle/edit-vehicle.component';
declare var $: any;

@Component({
  selector: 'app-interna-vehiculos',
  templateUrl: './interna-vehiculos.component.html',
  styleUrls: ['./interna-vehiculos.component.scss'],
})
export class InternaVehiculosComponent implements OnInit, OnDestroy {
  @Input() idInterna: number;
  @Input() vehiculos: Vehiculo[] = [];
  menuVehiculos: Menu[] = menuJSON;
  titulo: string;
  textoBoton: string;
  esVistaAgregarVehiculo: boolean = false;
  idTipoVehiculo: number = 0;
  idMarcaVehiculo: number = 0;
  idLineaVehiculo: number = 0;
  formulario: UntypedFormGroup;
  fullpit: Fullpit = {
    workshopNotificationCount: 0,
  };
  fullpitFirestore: FullpitFirestore;
  talleres: Taller[] = [];
  idTalleres: number[] = [];
  buscarWorkshop: WorkShopNearUseRequest = {
    latitude: 0,
    longitude: 0,
    idVehicle: 0,
    typeServiceList: [],
    radius: 0,
    homeService: false,
    idMarca: 0,
  };
  textoSolicitarServicio: Solicitar = {
    vehiculo: {
      id: 0,
      color: '',
      fuel: {
        id: 0,
        name: '',
      },
      linea: {
        id: 0,
        galones: 0,
        marca: {
          id: 0,
          name: '',
          typeVehicleId: 0,
        },
        name: '',
        tipoCombustible: '',
      },
      model: 0,
      placa: '',
      typeVehicle: {
        id: 0,
        name: '',
      },
    },
    lubricante: {
      id: 0,
      name: '',
    },
    servicio: [
      {
        id: 0,
        name: '',
      },
    ],
    homeService: false,
    latitude: 0,
    longitude: 0,
    comentario: '',
    fechaAgenda: '',
    hora: '',
    fecha: '',
    person: 0,
  };
  errorsSolicitar = {
    vehiculoId: false,
    lubricanteId: false,
    servicio: false,
    homeService: false,
    ubicacion: false,
  };
  textoFormulario = {
    tipoVehiculo: '',
    marca: '',
    linea: '',
    color: '',
    modelo: 0,
    fuel: '',
  };
  datosPersona: Person;
  inactivarBotonSolicitar: boolean = false;
  despliegueProd: boolean = environment.despliegue;
  minimaFecha: string = '';
  textoUbicacion: string | null = 'Dirección';
  tokenFirebase: string | null;
  notification: NotificationMessage;
  traerPersonaFullpit: Person;
  idAdmin: string = '';
  constructor(
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private variableGlobal: VariableGlobalService,
    private persona: PersonaService,
    private vehiculoService: VehiculoService,
    private talleresService: WorkshopsService,
    private router: Router,
    private fullpitService: FullpitService,
    private pushNotification: PushNotificationService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: any,
    private snackbarService: SnackbarService,
    private toastr: ToastrService,
    private listadoTalleresService: ListadosTalleresService
  ) {}
  ngOnDestroy(): void {
    this.formulario.reset();
    this.textoFormulario = {
      color: '',
      fuel: '',
      linea: '',
      marca: '',
      modelo: 0,
      tipoVehiculo: '',
    };
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('solicitar-unico')) {
        sessionStorage.removeItem('solicitar-unico');
      }
    }
  }

  ngOnInit(): void {
    this.pushNotification.requestPermission().subscribe((resp) => {
      if (resp === 'granted') {
        this.pushNotification.getToken().subscribe((token) => {
          this.tokenFirebase = token;
        });
      }
    });
    this.asignarTitulo();
    this.crearFormulario();
    this.traerDireccion();
    this.traerPersona();
    this.minimaFecha = moment().format('YYYY-MM-DD');
    this.ifExitIdAdmin();
  }

  ifExitIdAdmin() {
    this.listadoTalleresService.ifExistAdmin();
    this.idAdmin = this.listadoTalleresService.getIdAdmin();
  }
  scrollInicio() {
    if (isPlatformBrowser(this.platformId)) {
      $('html, body').animate({
        scrollTop: 0,
      });
    }
  }

  openEditVehicle(vehicle: Vehiculo, index: number) {
    this.dialog
      .open(EditVehicleComponent, {
        width: '400px',
        height: '400px',
        data: vehicle,
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) this.vehiculos[index] = resp;
      });
  }

  openDeleteVehicle(vehicle: Vehiculo, index: number) {
    this.vehiculoService.delete(vehicle.id!).subscribe(() => {
      this.vehiculos.splice(index, 1);
    });
  }

  asignarVehiculoRegistrado() {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('add-vehicle-general')) {
        let persona: Person = <Person>this.textoSolicitarServicio.person;
        this.vehiculoService.getByPersonId(persona!.id!).subscribe((resp) => {
          let vehiculo: Vehiculo = resp[resp.length - 1];
          this.textoSolicitarServicio.vehiculo = vehiculo;
          this.textoSolicitarServicio!.vehiculo!.fuel!.id = parseInt(
            `${vehiculo!.fuel!.id}`
          );
          this.textoSolicitarServicio!.vehiculo!.linea!.marca!.typeVehicleId =
            vehiculo!.linea!.marca!.typeVehicleId;
        });
        sessionStorage.removeItem('add-vehicle-general');
      }
    }
  }
  asignarTitulo() {
    this.menuVehiculos.forEach((menu) => {
      if (menu.id === this.idInterna) {
        this.titulo = menu.texto;
        this.textoBoton = 'Agregar nuevo vehículo';
      }
    });
  }
  vistaAgregarVehiculo() {
    this.esVistaAgregarVehiculo = !this.esVistaAgregarVehiculo;
    if (this.esVistaAgregarVehiculo) {
      this.titulo = 'Agregar vehículo';
      this.textoBoton = 'Cancelar';
    } else {
      this.asignarTitulo();
    }
    this.scrollInicio();
  }
  dialogDinamico(idDialog: number) {
    switch (idDialog) {
      case 1:
        const tipoVehiculo = this.dialog.open(TipoVehiculoComponent, {
          width: '500px',
        });
        tipoVehiculo.afterClosed().subscribe((tipos: TipoVehiculo) => {
          this.formulario.controls['typeVehicle'].setValue(tipos);
          this.textoFormulario.tipoVehiculo =
            this.formulario.get('typeVehicle')!.value.name;
          this.idTipoVehiculo = this.formulario.get('typeVehicle')!.value.id;
        });
        return;
      case 2:
        const marcaVehiculo = this.dialog.open(MarcaVehiculoComponent, {
          data: { typeVehicleId: this.idTipoVehiculo },
          height: '300px',
          width: '500px',
        });
        marcaVehiculo.afterClosed().subscribe((marcas: Marca) => {
          if (marcas === undefined && this.formulario.get('marca')!.value) {
          } else {
            if (this.textoFormulario.marca.length > 0) {
              if (this.textoFormulario.tipoVehiculo !== marcas.name) {
                this.idLineaVehiculo = 0;
                this.textoFormulario.linea = '';
              }
            }
            this.idMarcaVehiculo = marcas ? marcas.id : 0;
            this.textoFormulario.marca = marcas ? marcas.name : '';
            this.formulario.controls['marca'].setValue(this.idMarcaVehiculo);
          }
        });
        return;
      case 3:
        const lineaVehiculo = this.dialog.open(LineaVehiculoComponent, {
          width: '500px',
          height: '300px',
          data: { idmarcaVehiculo: this.idMarcaVehiculo },
        });
        lineaVehiculo.afterClosed().subscribe((lineas: Linea) => {
          if (lineas === undefined && this.formulario.get('marca')!.value) {
          } else {
            this.idLineaVehiculo = lineas ? lineas.id : 0;
            this.textoFormulario.linea = lineas ? lineas.name : '';
            this.formulario.controls['linea'].setValue(this.idLineaVehiculo);
          }
        });
        return;
      case 4:
        const modeloVehiculo = this.dialog.open(ModeloVehiculoComponent, {
          width: '500px',
          height: '300px',
        });
        modeloVehiculo.afterClosed().subscribe((modelos: Modelo) => {
          if (modelos === undefined && this.formulario.get('model')!.value) {
          } else {
            this.textoFormulario.modelo = modelos ? modelos.year : 0;
            this.formulario.controls['model'].setValue(modelos.year);
          }
        });
        return;
      case 5:
        const colorVehiculo = this.dialog.open(ColorVehiculoComponent, {
          width: '500px',
          height: '300px',
        });
        colorVehiculo.afterClosed().subscribe((color: Color) => {
          this.textoFormulario.color = color.color;
          this.formulario.controls['color'].setValue(color.color.split('#')[1]);
        });
        return;
      case 6:
        const combustibleVehiculo = this.dialog.open(
          CombustibleVehiculoComponent,
          {
            width: '500px',
            height: '300px',
          }
        );
        combustibleVehiculo.afterClosed().subscribe((fuel: Fuel) => {
          this.textoFormulario.fuel = fuel.name;
          this.formulario.controls['fuel'].setValue(fuel.id);
        });
        return;
    }
  }
  validarPlacaVehiculo(control: UntypedFormControl) {
    let placa: string = control.value;
    if (placa && placa.includes(' ')) {
      return {
        spacing: true,
      };
    }
    return null;
  }

  crearFormulario() {
    this.formulario = this.fb.group({
      placa: ['', [Validators.maxLength(6), this.validarPlacaVehiculo]],
      color: [''],
      model: [''],
      typeVehicle: ['', Validators.required],
      person: [''],
      linea: [''],
      marca: [''],
      fuel: [''],
    });
  }
  guardarVehiculo() {
    if (this.formulario.invalid) {
      this.snackbarService.mostrarSnackBar(
        'Debes llenar los campos requeridos'
      );
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
    let placas: string = this.formulario.get('placa')!.value;
    this.formulario.controls['placa'].setValue(placas.toUpperCase());
    this.consultarPersona();
  }
  consultarPersona() {
    this.variableGlobal.actualUser
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (
            localStorage.getItem('user') ||
            (sessionStorage.getItem('user') && this.formulario.valid)
          ) {
            let user: UserValidate;
            if (sessionStorage.getItem('user')) {
              user = JSON.parse(<string>sessionStorage.getItem('user'));
            } else {
              user = JSON.parse(`${localStorage.getItem('user')}`);
            }
            this.persona.getByUserId(user.user.id).subscribe(
              (resp) => {
                this.formulario.controls['person'].setValue(resp[0].id);
                this.snackbarService.mostrarSnackBar('Guardando vehiculo...');
                this.vehiculoService.save(this.formulario.value).subscribe(
                  (res) => {
                    this.formulario.reset();
                    this.textoFormulario = {
                      color: '',
                      fuel: '',
                      linea: '',
                      marca: '',
                      modelo: 0,
                      tipoVehiculo: '',
                    };
                    if (sessionStorage.getItem('solicitar-unico')) {
                      sessionStorage.setItem('add-vehicle', 'true');
                      this.router.navigateByUrl(
                        `/solicitar-servicio/${sessionStorage.getItem(
                          'solicitar-unico'
                        )}`
                      );
                    } else if (sessionStorage.getItem('solicitar-general')) {
                      sessionStorage.removeItem('solicitar-general');
                      sessionStorage.setItem('add-vehicle-general', 'true');
                      this.router.navigateByUrl(
                        '/mis-vehiculos/solicitar-servicio'
                      );
                    } else {
                      this.vistaAgregarVehiculo();
                    }
                    this.variableGlobal.consultarVehiculos();
                  },
                  (error: any) => {
                    this.snackbarService.mostrarSnackBar(
                      'La placa ya se encuentra registrada'
                    );
                  }
                );
              },
              (error: any) => {
                console.error(error);
                this.toastr.error('Error', 'Ha ocurrido un error');
              }
            );
          }
        }
      })
      .unsubscribe();
  }
  dialogDinamicoSolicitarServicio(idDialog: number) {
    switch (idDialog) {
      case 1:
        const seleccionarVehiculo = this.dialog.open(
          SeleccionarVehiculoComponent,
          {
            width: '400px',
            height: '400px',
          }
        );
        seleccionarVehiculo
          .afterClosed()
          .subscribe((vehiculoSeleccionado: Vehiculo) => {
            if (vehiculoSeleccionado !== undefined) {
              this.textoSolicitarServicio.vehiculo = vehiculoSeleccionado;
              this.textoSolicitarServicio!.vehiculo!.fuel!.id = parseInt(
                `${vehiculoSeleccionado!.fuel!.id}`
              );
              this.textoSolicitarServicio!.vehiculo!.linea!.marca!.typeVehicleId =
                vehiculoSeleccionado!.linea!.marca!.typeVehicleId;
            }
          });
        return;
      case 2:
        const seleccionarServicios = this.dialog.open(
          SeleccionarServiciosComponent,
          {
            width: '400px',
            height: '400px',
            data: { servicios: this.textoSolicitarServicio.servicio },
          }
        );
        seleccionarServicios
          .afterClosed()
          .subscribe((serviciosSeleccionados: TypeService[]) => {
            this.textoSolicitarServicio.servicio = serviciosSeleccionados;
          });
        return;
      case 3:
        const seleccionarLubricante = this.dialog.open(
          SeleccionarLubricanteComponent,
          {
            width: '400px',
            height: '400px',
          }
        );
        seleccionarLubricante
          .afterClosed()
          .subscribe((lubricante: TypeLubricant) => {
            if (lubricante !== undefined) {
              this.textoSolicitarServicio.lubricante = {
                id: parseInt(`${lubricante.id}`),
                name: lubricante!.name!,
              };
            }
          });
        return;
    }
  }
  eliminarServicioSeleccionado(index: number) {
    this.textoSolicitarServicio.servicio.splice(index, 1);
  }

  buscarTalleres(idFuncionBoton: number) {
    this.guardarUbicacion();
    this.validarCammposSolicitarServicio();
    if (
      this.errorsSolicitar.homeService ||
      this.errorsSolicitar.lubricanteId ||
      this.errorsSolicitar.servicio ||
      this.errorsSolicitar.vehiculoId ||
      this.errorsSolicitar.ubicacion
    ) {
      this.snackbarService.mostrarSnackBar('Verifique los campos esten llenos');
      return;
    }
    this.buscarWorkshop = {
      latitude: this.textoSolicitarServicio.latitude,
      longitude: this.textoSolicitarServicio.longitude,
      idVehicle: this.textoSolicitarServicio!.vehiculo!.typeVehicle!.id!,
      typeServiceList: this.textoSolicitarServicio.servicio,
      radius: 0.027,
      homeService: this.textoSolicitarServicio.homeService,
      idMarca: this.textoSolicitarServicio.vehiculo!.linea!.marca!.id!,
    };
    this.talleresService
      .getNearWorkshop(this.buscarWorkshop)
      .subscribe((resp: Taller[]) => {
        if (idFuncionBoton === 1) {
          this.router.navigate([`/listado-talleres/solicitar/5`]);
          this.variableGlobal.consultarSolicitudes(resp);
        } else {
          for (const taller of resp) {
            this.idTalleres.push(taller.id);
          }
        }
      });
  }
  abrirDialog() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      width: '400px',
      height: '400px',
    });
    dialogUbicacionActual.afterClosed().subscribe(() => {
      this.guardarUbicacion();
    });
  }

  guardarUbicacion() {
    this.variableGlobal.currentMessage.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.textoSolicitarServicio.latitude = JSON.parse(
          `${sessionStorage.getItem('ubicacion')}`
        ).latitud;
        this.textoSolicitarServicio.longitude = JSON.parse(
          `${sessionStorage.getItem('ubicacion')}`
        ).longitud;
      }
    });
  }
  traerDireccion() {
    this.variableGlobal.actualDireccion.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (sessionStorage.getItem('direccion')) {
          this.textoUbicacion = sessionStorage.getItem('direccion');
        }
      }
    });
  }
  validarCammposSolicitarServicio() {
    if (this.textoSolicitarServicio.homeService === false) {
      this.errorsSolicitar.homeService = true;
    } else {
      this.errorsSolicitar.homeService = false;
    }
    this.textoSolicitarServicio.servicio.forEach((serv) => {
      if (serv.id === 4) {
        if (this.textoSolicitarServicio.lubricante.id === 0) {
          this.errorsSolicitar.lubricanteId = true;
        } else {
          this.errorsSolicitar.lubricanteId = false;
        }
      }
    });
    if (this.textoSolicitarServicio.servicio[0].id === 0) {
      this.errorsSolicitar.servicio = true;
    } else {
      this.errorsSolicitar.servicio = false;
    }
    if (this.textoSolicitarServicio.vehiculo.id === 0) {
      this.errorsSolicitar.vehiculoId = true;
    } else {
      this.errorsSolicitar.vehiculoId = false;
    }
    if (
      this.textoSolicitarServicio.latitude === 0 &&
      this.textoSolicitarServicio.longitude === 0
    ) {
      this.errorsSolicitar.ubicacion = true;
    } else {
      this.errorsSolicitar.ubicacion = false;
    }
  }
  traerPersona() {
    this.variableGlobal.actualUser
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
            let user: UserValidate;
            if (sessionStorage.getItem('user')) {
              user = JSON.parse(<string>sessionStorage.getItem('user'));
            } else {
              user = JSON.parse(`${localStorage.getItem('user')}`);
            }
            this.persona.getByUserId(user.user.id).subscribe((usuario) => {
              this.textoSolicitarServicio.person = usuario[0];
              this.datosPersona = usuario[0];
              this.asignarVehiculoRegistrado();
            });
          }
        }
      })
      .unsubscribe();
  }
  async solicitarServicio() {
    this.inactivarBotonSolicitar = true;
    this.guardarUbicacion();
    this.validarCammposSolicitarServicio();
    if (
      this.errorsSolicitar.homeService ||
      this.errorsSolicitar.lubricanteId ||
      this.errorsSolicitar.servicio ||
      this.errorsSolicitar.vehiculoId ||
      this.errorsSolicitar.ubicacion
    ) {
      this.snackbarService.mostrarSnackBar('Verifique los campos esten llenos');
      this.inactivarBotonSolicitar = false;
      return;
    }
    if (
      this.textoSolicitarServicio.fecha.length > 0 &&
      this.textoSolicitarServicio.hora.length > 0
    ) {
      this.textoSolicitarServicio.fechaAgenda = moment(
        `${this.textoSolicitarServicio.fecha} ${this.textoSolicitarServicio.hora}`
      ).toString();
    } else if (
      this.textoSolicitarServicio.fecha.length > 0 &&
      this.textoSolicitarServicio.hora.length === 0
    ) {
      this.textoSolicitarServicio.fechaAgenda = moment(
        this.textoSolicitarServicio.fecha + ' 00:00:30'
      ).toString();
    } else {
      this.textoSolicitarServicio.fechaAgenda = moment('01/01/1900')
        .subtract(5, 'h')
        .toString();
    }
    if (isPlatformBrowser(this.platformId)) {
      this.fullpit = {
        additionalServices: this.textoSolicitarServicio.comentario,
        dateScheduled: this.textoSolicitarServicio.fechaAgenda
          ? moment(this.textoSolicitarServicio.fechaAgenda).toDate()
          : null,
        userAddress: `${sessionStorage.getItem('direccion')}`,
        latitude: this.textoSolicitarServicio.latitude,
        longitude: this.textoSolicitarServicio.longitude,
        typeServiceList: this.textoSolicitarServicio.servicio,
        vehiculo: this.textoSolicitarServicio.vehiculo,
        typeLubricant: this.textoSolicitarServicio.lubricante,
        person: this.textoSolicitarServicio.person,
        stateId: EFullpitState.PENDIENTE,
        stateName: 'Pendiente',
        firebaseMessagingToken: `${this.tokenFirebase}`,
        cotizacion: false,
        category: this.textoSolicitarServicio.homeService
          ? ECategoryService.A_DOMICILIO
          : ECategoryService.EN_TALLER,
      };
    }
    this.buscarWorkshop = {
      latitude: this.textoSolicitarServicio.latitude,
      longitude: this.textoSolicitarServicio.longitude,
      idVehicle: this.textoSolicitarServicio!.vehiculo!.typeVehicle!.id!,
      typeServiceList: this.textoSolicitarServicio.servicio,
      radius: 0.027,
      homeService: this.textoSolicitarServicio.homeService,
      idMarca: this.textoSolicitarServicio!.vehiculo!.linea!.marca!.id!,
    };
    // Traer talleres cercanos a la ubicación
    await this.getNearWorkshop()
      .then((resp) => {
        if (resp.length > 0) {
          this.talleres = resp;
          this.idTalleres = resp.map((value) => value.id);
          this.fullpit.workshopRequestList = <Workshop[]>(
            (<unknown>this.talleres)
          );
        } else {
          this.toastr.info(
            '¡Lo sentimos!',
            'Para este servicio no encontramos ningún taller cercano'
          );
        }
      })
      .catch((error) => {
        this.toastr.error(error);
      });
    // Guardar objeto Fullpit
    await this.saveFullpit()
      .then((fullpit) => {
        this.traerPersonaFullpit = <Person>fullpit.person;
        /*         this.fullpitFirestore = {
          id: `${fullpit.id}`,
          idUser: JSON.parse(
            `${
              sessionStorage.getItem('user')
                ? sessionStorage.getItem('user')
                : localStorage.getItem('user')
            }`
          ).user.id,
          additionalComments: fullpit.additionalServices,
          schedule: this.textoSolicitarServicio.fechaAgenda
            ? moment(this.textoSolicitarServicio.fechaAgenda).toDate()
            : null,
          person: fullpit.person,
          vehiculo: fullpit.vehiculo,
          typeService: this.textoSolicitarServicio.homeService
            ? ECategoryService.A_DOMICILIO
            : ECategoryService.EN_TALLER,
          services: fullpit.typeServiceList,
          typeLubricant: this.textoSolicitarServicio.lubricante,
          userAddress: fullpit.userAddress,
          newRequest: true,
          talleres: [],
          talleresOfertasEnviados: this.idTalleres,
          dateCreated: moment(`${fullpit.dateCreated}`).toDate(),
          propuestas: [],
          firebaseMessagingToken: `${this.tokenFirebase}`,
          stateId: EFullpitState.PENDIENTE,
        }; */
      })
      .catch((error) => {
        this.inactivarBotonSolicitar = false;
        this.toastr.error(error);
      });
    // Guardar propuesta en Firebase
    /*     await this.fullpitFirebase
      .guardarPropuesta(
        parseInt(this.fullpitFirestore.id!),
        this.fullpitFirestore
      )
      .catch((error) => {
        this.inactivarBotonSolicitar = false;
        this.toastr.error(error);
      }); */
    // Guardar solicitudes en Firebase
    /*     await this.idTalleres.map((idTaller) => {
      this.fullpitFirebase
        .guardarSolicitud(
          idTaller,
          parseInt(this.fullpitFirestore.id!),
          this.fullpitFirestore
        )
        .then(() => {
          this.snackbarService.mostrarSnackBar(
            'Algún taller se pondrá en contácto en cualquier momento'
          );
        })
        .catch((error) => {
          this.inactivarBotonSolicitar = false;
          console.error(error);
          this.toastr.error('Error', 'Ha ocurrido un error');
        });
    }); */
    // Guardar objecto para enviar notificación
    this.talleres.map(async (taller) => {
      /*  this.notification = {
        whatsappNotificationData: {
          body: 'solicitado un servicio',
          subTitle: (
            this.traerPersonaFullpit.nombres +
            ' ' +
            this.traerPersonaFullpit.apellidos
          ).trim(),
          title: 'Nueva Solicitud',
          templateName: 'taller_notificacion',
        },
        title: 'Nuevo servicio solicitado',
        body:
          this.textoSolicitarServicio!.vehiculo!.linea!.marca!.name +
          ', ' +
          this.textoSolicitarServicio!.vehiculo!.linea!.name,
        token: taller.firebaseMessagingToken,
        workshopId: taller.id,
        receiverType: EFullpitStateNotification.WORKSHOP,
        data: {
          title: 'Nuevo servicio solicitado',
          body:
            this.textoSolicitarServicio!.vehiculo!.linea!.marca!.name +
            ', ' +
            this.textoSolicitarServicio!.vehiculo!.linea!.name,
          navegacion: '4',
          personId: `${this.traerPersonaFullpit.id}`,
          idService: this.fullpitFirestore.id,
          roomChatId: '0',
          tokenAdmin: `${this.tokenFirebase}`,
          tokenWorkShop: taller.firebaseMessagingToken,
          workshop: JSON.stringify(this.fullpitFirestore),
        },
      }; */
      // Enviar notificación push, whatsapp, correo
      /*   await this.sendNotification().catch((error) => {
        this.inactivarBotonSolicitar = false;
        this.toastr.error(error);
      }); */
    });
    if (isPlatformBrowser(this.platformId)) {
      location.href = location.origin + '/analitica-solicitar-servicio';
    }
  }

  saveFullpit(): Promise<Fullpit> {
    return this.fullpitService.save(this.fullpit).toPromise();
  }

  sendNotification(): Promise<Object | NotificationMessage> {
    return this.notificationService.save(this.notification).toPromise();
  }

  getNearWorkshop(): Promise<Taller[]> {
    return this.talleresService
      .getNearWorkshop(this.buscarWorkshop)
      .pipe(
        map((transform: Taller[]) => {
          return transform;
        })
      )
      .toPromise();
  }
  restablecer() {
    this.buscarWorkshop = {
      latitude: 0,
      longitude: 0,
      idVehicle: 0,
      typeServiceList: [],
      radius: 0,
      homeService: false,
      idMarca: 0,
    };
    this.textoSolicitarServicio = {
      vehiculo: {
        id: 0,
        color: '',
        fuel: {
          id: 0,
          name: '',
        },
        linea: {
          id: 0,
          galones: 0,
          marca: {
            id: 0,
            name: '',
            typeVehicleId: 0,
          },
          name: '',
          tipoCombustible: '',
        },
        model: 0,
        placa: '',
        typeVehicle: {
          id: 0,
          name: '',
        },
      },
      lubricante: {
        id: 0,
        name: '',
      },
      servicio: [
        {
          id: 0,
          name: '',
        },
      ],
      homeService: false,
      latitude: 0,
      longitude: 0,
      comentario: '',
      fechaAgenda: '',
      hora: '',
      fecha: '',
      person: 0,
    };
    this.errorsSolicitar = {
      vehiculoId: false,
      lubricanteId: false,
      servicio: false,
      homeService: false,
      ubicacion: false,
    };
    this.textoSolicitarServicio;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TipoVehiculo } from 'src/app/models/tipo-vehiculos';
import { TypeVehicleService, TypeVehicle } from 'fullpits-commons';

@Component({
  selector: 'app-tipo-vehiculo',
  templateUrl: './tipo-vehiculo.component.html',
  styleUrls: ['./tipo-vehiculo.component.scss'],
})
export class TipoVehiculoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TipoVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private typeVehicleService: TypeVehicleService
  ) {}
  vehiculos: TipoVehiculo[] = [];
  ngOnInit(): void {
    this.crearTabla();
  }

  crearTabla() {
    this.typeVehicleService
      .getAll()
      .subscribe((type_vehiculo: TipoVehiculo[]) => {
        this.vehiculos = type_vehiculo;
      });
  }
  datosSeleccionados(vehiculo: TipoVehiculo) {
    this.data = vehiculo;
    this.dialogRef.close(this.data);
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-terminos-y-condiciones-bono',
  templateUrl: './terminos-y-condiciones-bono.component.html',
  styleUrls: ['./terminos-y-condiciones-bono.component.scss'],
})
export class TerminosYCondicionesBonoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TerminosYCondicionesBonoComponent>
  ) {}

  ngOnInit(): void {}
  cerrarDialog() {
    this.dialogRef.close();
  }
}

<div class="container-fluid">
  <div class="row justify-content-end align-items-center">
    <div class="col-1">
      <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
        <i class="far fa-times-circle mb-0 h5"></i>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <h1>T&C SORTEO</h1>
      <ul>
        <li>
          El número ganador será el que coincida con los tres (3) últimos
          dígitos del premio mayor de la lotería de Bogotá jugado el ultimo
          jueves del mes.
        </li>
        <li>
          Solo participan los números entregados dentro del mismo mes del
          sorteo.
        </li>
        <li>
          El premio no será entregado en efectivo. Si la tecnomecánica está
          cerca de vencer, se le pagará el monto a un CDA aliado o de lo
          contrario, se le agregará el saldo a la cuenta, para que pueda ser
          gastado en cualquier servicio(s) agendado(s) con talleres o
          autolavados aliados Fullpits.
        </li>
        <li>
          Si ningún número coincide en las tres (3) cifras, se escogerá quien
          acierte en las dos (2) últimas cifras y se dividirá el premio entre
          los ganadores.
        </li>
      </ul>
    </div>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuelService } from 'fullpits-commons';
import { Fuel } from 'fullpits-commons';

@Component({
  selector: 'app-combustible-vehiculo',
  templateUrl: './combustible-vehiculo.component.html',
  styleUrls: ['./combustible-vehiculo.component.scss'],
})
export class CombustibleVehiculoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CombustibleVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fuelService: FuelService
  ) {}

  fuels: Fuel[] = [];

  ngOnInit(): void {
    this.getFuel();
  }

  getFuel() {
    this.fuelService.getAll().subscribe((fuels) => {
      this.fuels = fuels;
    });
  }

  datosSeleccionados(fuel: Fuel) {
    this.data = fuel;
    this.dialogRef.close(this.data);
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}

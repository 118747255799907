<div>
  <h1>Editar precio</h1>
  <form [formGroup]="formPrice" (ngSubmit)="save()">
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>Precio base</mat-label>
        <input
          type="number"
          matInput
          placeholder="Precio base"
          (ngModelChange)="calculateTotal()"
          formControlName="precioBase"
        />
        <mat-error
          *ngIf="
            formPrice.get('precioBase')?.invalid &&
            (formPrice.get('precioBase')?.dirty ||
              formPrice.get('precioBase')?.touched)
          "
        >
          Precio base es requerido
        </mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-checkbox
        formControlName="tieneIva"
        (ngModelChange)="calculateTotal()"
        >¿Con IVA?</mat-checkbox
      >
    </div>
    <div class="form-group">
      <mat-form-field appearance="outline">
        <mat-label>Precio total</mat-label>
        <input
          type="number"
          matInput
          placeholder="Precio total"
          formControlName="total"
          readonly
        />
        <mat-error
          *ngIf="
            formPrice.get('total')?.invalid &&
            (formPrice.get('total')?.dirty || formPrice.get('total')?.touched)
          "
        >
          Precio total es requerido
        </mat-error>
      </mat-form-field>
    </div>

    <button
      mat-raised-button
      color="warn"
      [disabled]="formPrice.invalid"
      class="w-100"
    >
      Guardar
    </button>
  </form>
</div>

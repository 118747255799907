import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { SubirImagenGaleriaComponent } from 'src/app/modulos/dialog/subir-imagen-galeria/subir-imagen-galeria.component';
import { TomarFotoWebcamComponent } from 'src/app/modulos/dialog/tomar-foto-webcam/tomar-foto-webcam.component';

@Component({
  selector: 'app-subida-imagen-chat',
  templateUrl: './subida-imagen-chat.component.html',
  styleUrls: ['./subida-imagen-chat.component.scss'],
})
export class SubidaImagenChatComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<SubidaImagenChatComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: any
  ) {}

  ngOnInit(): void {}
  abrirDialog(opt: number) {
    switch (opt) {
      case 1:
        if (this.data.programado) {
          const subirImagenProgramado = this.dialog.open(
            SubirImagenGaleriaComponent,
            {
              width: '400px',
              height: '400px',
              data: {
                id: this.data.id,
                chat: this.data.chat,
                taller: this.data.idTaller,
                propuesta: this.data.idPropuesta,
                programado: this.data.programado,
              },
            }
          );
          subirImagenProgramado.afterClosed().subscribe(() => {
            this.bottomSheetRef.dismiss();
          });
          return;
        } else {
          const subirImagen = this.dialog.open(SubirImagenGaleriaComponent, {
            width: '400px',
            height: '400px',
            data: {
              id: this.data.id,
              chat: this.data.chat,
              taller: this.data.idTaller,
              propuesta: this.data.idPropuesta,
            },
          });
          subirImagen.afterClosed().subscribe(() => {
            this.bottomSheetRef.dismiss();
          });
          return;
        }

      case 2:
        if (this.data.programado) {
          const tomarFotoProgramado = this.dialog.open(
            TomarFotoWebcamComponent,
            {
              width: '400px',
              height: '400px',
              data: {
                id: this.data.id,
                chat: this.data.chat,
                taller: this.data.idTaller,
                propuesta: this.data.idPropuesta,
                programado: this.data.programado,
              },
            }
          );
          tomarFotoProgramado.afterClosed().subscribe(() => {
            this.bottomSheetRef.dismiss();
          });
          return;
        } else {
          const tomarFoto = this.dialog.open(TomarFotoWebcamComponent, {
            width: '400px',
            height: '400px',
            data: {
              id: this.data.id,
              chat: this.data.chat,
              taller: this.data.idTaller,
              propuesta: this.data.idPropuesta,
            },
          });
          tomarFoto.afterClosed().subscribe(() => {
            this.bottomSheetRef.dismiss();
          });
          return;
        }
    }
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TalleresComponent } from './talleres/talleres.component';


const routes: Routes = [
  {path: '', component: TalleresComponent}
]


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ProductosRoutingModule { }

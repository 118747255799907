<ul
  class="nav flex-column align-items-start align-items-lg-normal border-top my-5"
>
  <ng-container *ngFor="let menuObj of menu">
    <li class="nav-item" [ngClass]="{ 'd-none': menuObj.hidden }">
      <ng-container *ngIf="menuObj.href; else useHref">
        <a
          class="nav-link btn btn-link fw-bold text-black border-bottom px-2 p-lg-3 border-radius-0 text-start text-lg-center"
          [href]="[menuObj.ruta]"
          target="_blank"
          (click)="scrollInicio()"
          >{{ menuObj.texto }}</a
        >
      </ng-container>
      <ng-template #useHref>
        <a
          class="nav-link btn btn-link fw-bold text-black border-bottom px-2 p-lg-3 border-radius-0 text-start text-lg-center"
          [routerLink]="[menuObj.ruta]"
          (click)="scrollInicio()"
          routerLinkActive="active"
          >{{ menuObj.texto }}</a
        >
      </ng-template>
    </li>
  </ng-container>
</ul>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Person, PersonaService } from 'fullpits-commons';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { UbicacionActualComponent } from 'src/app/modulos/dialog/ubicacion-actual/ubicacion-actual.component';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserValidate } from '../../../../models/userValidate';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  show: boolean = false;
  buscador: string = '';
  textoUbicacion: string | null = 'Ingresa tu ubicación';
  usuario: Person;
  urlLogin: string = environment.urlLogin;
  idAdmin: string;
  contadorNotificacion: number = 0;
  direccion$ = new Subject<void>();
  busqueda$ = new Subject<void>();
  persona$ = new Subject<void>();
  contadorNotificacion$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private variableGlobal: VariableGlobalService,
    private router: Router,
    private persona: PersonaService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('busqueda');
    }
    this.direccion$.next();
    this.direccion$.complete();
    this.busqueda$.next();
    this.busqueda$.complete();
    this.persona$.next();
    this.persona$.complete();
    this.contadorNotificacion$.next();
    this.contadorNotificacion$.complete();
  }

  ngOnInit(): void {
    this.getAddress();
    this.consultarPersona();
    this.traerDatoBuscado();
    this.getCountNotification();
  }

  getCountNotification() {
    this.variableGlobal.actualContadorNotificacion
      .pipe(takeUntil(this.contadorNotificacion$))
      .subscribe((resp) => {
        this.contadorNotificacion = resp;
        this.cd.markForCheck();
      });
  }
  getAddress() {
    this.variableGlobal.actualDireccion
      .pipe(takeUntil(this.direccion$))
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.idAdmin = <string>sessionStorage.getItem('idAdmin');
          if (sessionStorage.getItem('direccion')) {
            this.textoUbicacion = sessionStorage.getItem('direccion');
            $('.texto-ubicacion').addClass('texto-direccion');
          }
        }
        this.cd.markForCheck();
      });
  }

  traerDatoBuscado() {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('busqueda')) {
        this.buscador = `${sessionStorage.getItem('busqueda')}`;
        if (this.buscador === '') {
          sessionStorage.removeItem('busqueda');
        }
      }
    }
  }

  async consultarPersona() {
    let user!: UserValidate;
    await this.variableGlobal.actualUser
      .pipe(takeUntil(this.persona$))
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
            if (sessionStorage.getItem('user')) {
              user = JSON.parse(<string>sessionStorage.getItem('user'));
            } else {
              user = JSON.parse(`${localStorage.getItem('user')}`);
            }
          }
        }
      })
      .unsubscribe();

    await this.persona
      .getByUserId(user?.user.id)
      .toPromise()
      .then((resp) => {
        this.usuario = resp[0];
        this.cd.markForCheck();
      })
      .catch((error) => {
        console.error(error);
        this.toastr.error('Error', 'Ha ocurrido un error');
      });
  }

  abrirDialog() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      maxWidth: '400px',
      maxHeight: '400px',
    });
  }
  getBuscar() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.buscador != '') {
        sessionStorage.setItem('busqueda', this.buscador.trim());
        this.router.navigateByUrl(`/${this.buscador}`);
      }
    }
  }
}

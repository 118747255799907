import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
import {
  Fuel,
  FuelService,
  LineaVehiculo,
  LineaVehiculoService,
  MarcaVehiculo,
  MarcaVehiculoService,
  TypeVehicle,
  TypeVehicleService,
  Vehiculo,
  VehiculoService,
} from 'fullpits-commons';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import Colores from '../../../../assets/json/menu-colores.json';
import { Color } from 'src/app/models/color';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-vehicle',
  templateUrl: './edit-vehicle.component.html',
  styleUrls: ['./edit-vehicle.component.scss'],
})
export class EditVehicleComponent implements OnInit {
  formEditVehicle: FormGroup;
  fb = inject(FormBuilder);
  typeVehicleService = inject(TypeVehicleService);
  marcaService = inject(MarcaVehiculoService);
  lineaService = inject(LineaVehiculoService);
  fuelService = inject(FuelService);
  vehiculoService = inject(VehiculoService);
  toastService = inject(ToastrService);
  selectTypeVehicle: TypeVehicle;
  selectMarca?: MarcaVehiculo;
  selectLinea?: LineaVehiculo;
  selectFuel?: Fuel;
  typeVehicles: TypeVehicle[] = [];
  typeVehicle$?: Observable<TypeVehicle[]>;
  marcas: MarcaVehiculo[] = [];
  marca$?: Observable<MarcaVehiculo[]>;
  lineas: LineaVehiculo[] = [];
  linea$?: Observable<LineaVehiculo[]>;
  fuels: Fuel[] = [];
  fuel$?: Observable<Fuel[]>;
  models: number[] = [];
  models$?: Observable<number[]>;
  colores: Color[] = Colores;
  constructor(
    private dialogRef: MatDialogRef<EditVehicleComponent>,
    @Inject(MAT_DIALOG_DATA) private vehicle: Vehiculo
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.getTypeVehicle();
    this.getModels();
    this.getFuels();
  }

  initData() {
    this.selectTypeVehicle = this.vehicle.typeVehicle!;
    this.selectMarca = this.vehicle.linea?.marca;
    this.selectLinea = this.vehicle.linea;
    this.selectFuel = this.vehicle.fuel;
    this.getMarcaByIdTypeVehicle(this.selectTypeVehicle.id!);
  }

  initForm() {
    this.initData();
    this.formEditVehicle = this.fb.group({
      id: [this.vehicle.id],
      typeVehicle: [this.vehicle.typeVehicle?.name, Validators.required],
      marca: [this.vehicle.linea?.marca?.name],
      linea: [this.vehicle.linea?.name],
      color: ['#' + this.vehicle.color || '#000000'],
      model: [this.vehicle.model],
      placa: [this.vehicle.placa],
      fuel: [this.vehicle.fuel?.name],
    });
  }

  getTypeVehicle() {
    this.typeVehicleService.getAll().subscribe((resp) => {
      this.typeVehicles = resp;
      this._filterTypeVehicle();
    });
  }

  private _filterTypeVehicle() {
    this.typeVehicle$ = this.formEditVehicle
      .get('typeVehicle')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterSearchTypeVehicle(value))
      );
  }

  private _filterSearchTypeVehicle(value: string) {
    const filterValue = value.toLowerCase();

    return this.typeVehicles.filter(
      (typeVehicle) =>
        typeVehicle.name?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  setTypeVehicle(typeVehicle: TypeVehicle) {
    this.selectTypeVehicle = typeVehicle;
    this.getMarcaByIdTypeVehicle(typeVehicle.id!);
  }

  getMarcaByIdTypeVehicle(idTypeVehicle: number) {
    this.marcaService.getByTypeVehicleId(idTypeVehicle).subscribe((resp) => {
      this.marcas = resp;
      this._filterMarca();
    });
  }

  private _filterMarca() {
    this.marca$ = this.formEditVehicle.get('marca')?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSearchMarca(value))
    );
  }

  private _filterSearchMarca(value: string) {
    const filterValue = value.toLowerCase();

    return this.marcas.filter(
      (marca) => marca.name?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  setMarca(marca: MarcaVehiculo) {
    this.selectMarca = marca;
    this.getLineaByIdMarca(marca.id!);
  }

  getLineaByIdMarca(idMarca: number) {
    this.lineaService.getByMarcaId(idMarca).subscribe((resp) => {
      this.lineas = resp;
      this._filterLinea();
    });
  }

  private _filterLinea() {
    this.linea$ = this.formEditVehicle.get('linea')?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSearchLinea(value))
    );
  }

  private _filterSearchLinea(value: string) {
    const filterValue = value.toLowerCase();
    return this.lineas.filter(
      (linea) => linea.name?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  setLinea(linea: LineaVehiculo) {
    this.selectLinea = linea;
  }

  getModels() {
    const FIRT_YEAR = 1930;
    for (let i = dayjs().year() - FIRT_YEAR + 1; i >= 0; i--) {
      this.models.push(i + FIRT_YEAR);
    }
    this.models$ = this.formEditVehicle.get('model')?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterModels(value))
    );
  }

  _filterModels(value: number) {
    return this.models.filter(
      (model) => model.toString().indexOf(value.toString()) === 0
    );
  }

  getFuels() {
    this.fuelService.getAll().subscribe((resp) => {
      this.fuels = resp;
      this._filterFuels();
    });
  }

  private _filterFuels() {
    this.fuel$ = this.formEditVehicle.get('fuel')?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterSearchFuels(value))
    );
  }

  private _filterSearchFuels(value: string) {
    const filterValue = value.toLowerCase();
    return this.fuels.filter(
      (fuel) => fuel.name?.toLowerCase().indexOf(filterValue) === 0
    );
  }

  setFuel(fuel: Fuel) {
    this.selectFuel = fuel;
  }

  guardarVehiculo() {
    this.vehicle = {
      id: this.vehicle.id,
      typeVehicle: this.selectTypeVehicle,
      linea: {
        ...this.selectLinea,
        marca: this.selectMarca,
      },
      color: this.formEditVehicle.get('color')?.value.split('#')[1],
      placa: this.formEditVehicle.get('placa')?.value.toUpperCase(),
      fuel: this.selectFuel,
      model: this.formEditVehicle.get('model')?.value,
    };
    this.vehiculoService.save(this.vehicle).subscribe(() => {
      this.toastService.success('Cambios realizados exitosamente');
      this.dialogRef.close(this.vehicle);
    });
  }
}

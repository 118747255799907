<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <h1 class="text-center my-3">Asignar persona</h1>
      <form
        class="w-100"
        [formGroup]="formulario"
        (ngSubmit)="enviarAsignado()"
        autocomplete="off"
      >
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Ingrese nombre</mat-label>
          <input
            type="text"
            placeholder="Ingrese nombre operadores"
            matInput
            [formControl]="myControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option
              *ngFor="let operator of filteredOperators | async"
              [value]="operator.name"
            >
              {{ operator.name + " " + operator.lastName }}
            </mat-option>
          </mat-autocomplete>
          <!-- <input matInput formControlName="nombre" type="text" /> -->
        </mat-form-field>
        <button mat-raised-button color="warn">Guardar persona</button>
      </form>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificacionFirebase } from 'src/app/models/notificaciones-firebase';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificacionesFirebaseService {
  constructor(private firestore: AngularFirestore) {}

  traerNotificaciones(idUser: number): Observable<DocumentChangeAction<any>[]> {
    return this.firestore
      .collection<any>(
        `${environment.coleccionNotificaciones}/${idUser}/${environment.documentoNotificaciones}`
      )
      .snapshotChanges()
      .pipe(
        map((notificaciones) => {
          notificaciones.sort((a, b) => {
            if (
              a.payload.doc.data().dateCreated <
              b.payload.doc.data().dateCreated
            ) {
              return 1;
            }
            if (
              a.payload.doc.data().dateCreated >
              b.payload.doc.data().dateCreated
            ) {
              return -1;
            }
            return 0;
          });
          return notificaciones.map((notificacion) => {
            const data = notificacion.payload.doc.data();
            data.id = notificacion.payload.doc.id;
            return data;
          });
        })
      );
  }
  actualizarViewedNotificacion(
    idUser: number,
    notificacion: NotificacionFirebase
  ) {
    return this.firestore
      .doc(
        `${environment.coleccionNotificaciones}/${idUser}/${environment.documentoNotificaciones}/${notificacion.id}`
      )
      .set(notificacion);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { Action, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { EFullpitStateNotification } from 'fullpits-commons';
import { Fullpit } from 'fullpits-commons';
import { NotificationMessage } from 'fullpits-commons';
import { FullpitFirebaseService } from 'src/app/core/shared/services/fullpit-firebase.service';
import { FullpitService } from 'fullpits-commons';
import { NotificationService } from 'fullpits-commons';
import { PushNotificationService } from 'src/app/core/shared/services/push-notification.service';
import { Chat } from 'src/app/models/chat';
import { FullpitFirestore, Taller } from 'fullpits-commons';
declare var $: any;
@Component({
  selector: 'app-subir-imagen-galeria',
  templateUrl: './subir-imagen-galeria.component.html',
  styleUrls: ['./subir-imagen-galeria.component.scss'],
})
export class SubirImagenGaleriaComponent implements OnInit {
  files: File[] = [];
  mensaje: Chat;
  propuestaColeccion: FullpitFirestore;
  servicioProgramado: Fullpit;
  notificationMessage: NotificationMessage;
  tokenFirebase: string | null;

  constructor(
    private fullpitsFirebase: FullpitFirebaseService,
    private dialogRef: MatDialogRef<SubirImagenGaleriaComponent>,
    private fullpitFirebaseService: FullpitFirebaseService,
    private pushNotification: PushNotificationService,
    private notificacion: NotificationService,
    private fullpitService: FullpitService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.permisosDeNotificacion();
  }

  traerPropuestas() {
    if (this.propuestaColeccion === undefined) {
      this.fullpitFirebaseService
        .traerPropuestas(this.data.propuesta)
        .subscribe((resp: Action<DocumentSnapshot<any>>) => {
          this.propuestaColeccion = resp.payload.data();
          this.fullpitService
            .getById(parseInt(`${this.propuestaColeccion.id}`))
            .subscribe((fullpit: Fullpit) => {
              let fechaCreada: any = this.propuestaColeccion.dateCreated;
              this.propuestaColeccion.dateCreated = moment
                .unix(fechaCreada.seconds)
                .toDate();
              if (this.propuestaColeccion.propuestas?.length === 0) {
                this.propuestaColeccion.propuestas.push(<any>fullpit.workshop);
              }
              this.enviarNotificacion();
            });
        });
    }
  }
  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  enviarFotos() {
    if (this.files.length === 0) {
      alert('Debes subir al menos una imagen');
    }
    this.files.forEach((file) => {
      this.mensaje = {
        audio: false,
        image: true,
        message: '',
        roomChatId: null,
        timeStamp: new Date(),
        user: true,
        userId: `${this.data.id}`,
      };
      this.fullpitsFirebase
        .uploadImage(this.mensaje, file, this.data.chat)
        .subscribe(() => {
          this.bajarScrollChat();
          this.traerPropuestas();
          this.dialogRef.close();
        });
    });
  }

  permisosDeNotificacion() {
    this.pushNotification.getToken().subscribe((resp) => {
      if (resp === 'granted') {
        this.pushNotification.getToken().subscribe((token) => {
          this.tokenFirebase = token;
        });
      }
    });
  }

  enviarNotificacion() {
    this.propuestaColeccion.propuestas?.forEach((propuesta) => {
      let propuestaProgramada: Taller = <any>propuesta;
      if (
        propuesta.idWorkShop == this.data.taller ||
        propuestaProgramada.id == this.data.taller
      ) {
        this.notificationMessage = {
          whatsappNotificationData: {
            body: 'enviado una imagen',
            subTitle:
              this.propuestaColeccion.person.nombres +
              ' ' +
              this.propuestaColeccion.person.apellidos,
            title: 'Nuevo mensaje',
          },
          title:
            this.propuestaColeccion.person.nombres +
            ' ' +
            this.propuestaColeccion.person.apellidos,
          body: 'Imagen',
          workshopId: propuesta.idWorkShop!,
          receiverType: EFullpitStateNotification.WORKSHOP,
          token: propuesta.firebaseMessagingToken!,
          data: {
            title:
              this.propuestaColeccion.person.nombres +
              ' ' +
              this.propuestaColeccion.person.apellidos,
            body: 'Imagen',
            personId: this.propuestaColeccion.person.id,
            idService: this.propuestaColeccion.id,
            navegacion: '1',
            roomChatId: `${this.data.chat}`,
            tokenAdmin: `${this.tokenFirebase}`,
            tokenWorkShop: propuesta.firebaseMessagingToken,
            workshop: JSON.stringify(this.propuestaColeccion),
          },
        };
      }
    });
    this.notificacion.save(this.notificationMessage).subscribe();
  }

  bajarScrollChat() {
    //Fijo el scroll al fondo
    $('#contenedorChat').animate(
      { scrollTop: $('#contenedorChat').prop('scrollHeight') },
      1000
    );
  }
}

<div class="d-block mx-auto">
  <h1>{{ textPaymentMethod }}</h1>
  <form [formGroup]="formPaymentMethod" (ngSubmit)="save()">
    <mat-form-field appearance="fill">
      <mat-label>{{ textPaymentMethod }}</mat-label>
      <mat-select
        formControlName="paymentMethod"
        [placeholder]="textPaymentMethod"
      >
        <ng-container *ngFor="let paymentMethod of paymentMethod$ | async">
          <mat-option [value]="paymentMethod">{{
            paymentMethod.name
          }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <div class="form-group">
      <button mat-raised-button type="submit">Guardar</button>
    </div>
  </form>
</div>

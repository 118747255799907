import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WorkshopTypeServiceLocal } from 'src/app/models/workshopTypeService';
import { DialogOpcionSolicitudComponent } from '../dialog-opcion-solicitud/dialog-opcion-solicitud.component';

@Component({
  selector: 'app-dialog-servicios',
  templateUrl: './dialog-servicios.component.html',
  styleUrls: ['./dialog-servicios.component.scss'],
})
export class DialogServiciosComponent implements OnInit {
  @Input() servicioObj!: WorkshopTypeServiceLocal;
  @Input() idTaller: number = 0;
  @Input() nombreTaller: string;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {}

  traerOpcionSolicitud(servicio?: string) {
    this.dialog
      .open(DialogOpcionSolicitudComponent, {
        width: '400px',
        height: 'max-content',
        data: {
          nombreTaller: this.nombreTaller
            .replace(/&/g, '%26')
            .replace(/ /g, '+'),
          servicio: servicio?.replace(/ /g, '+'),
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.router.navigateByUrl(`/solicitar-servicio/${this.idTaller}`);
        }
      });
  }

  agendarServicio(servicio: WorkshopTypeServiceLocal) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('servicio', JSON.stringify(servicio));
      this.traerOpcionSolicitud(<string>servicio.title);
    }
  }
}

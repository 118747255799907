<!-- Header para pc -->
<nav
  class="navbar navbar-dark bg-negro sticky-top py-1 d-lg-block d-none"
  style="z-index: 2"
>
  <div class="container-fluid align-items-lg-center">
    <div>
      <a
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
      >
        <img
          src="./assets/iconos-header/menu-fullpits.webp"
          alt="menu-fullpits"
          class="img-fluid"
          style="width: 45px; height: auto"
        />
      </a>
    </div>
    <div class="flex-grow-sm-1">
      <div class="cursor-pointer" [routerLink]="['/']">
        <img
          src="./assets/iconos-header/logo-naranja-fullpits.webp"
          alt="logo-naranja-fullpits"
          class="img-fluid"
          style="width: 165px"
        />
      </div>
    </div>
    <div
      class="text-white d-flex align-items-center me-3 p-0 cursor-pointer"
      (click)="abrirDialog()"
    >
      <img
        src="./assets/iconos-header/ubicacion-fullpits.webp"
        alt="ubicacion-fullpits"
        class="img-fluid mx-1"
        style="width: 19px; height: auto"
      />
      <span class="ml-1 fw-bold line-height-1 me-2 texto-ubicacion">{{
        textoUbicacion
      }}</span>
      <i class="fas fa-caret-down"></i>
    </div>
    <div class="text-white d-flex align-items-center flex-grow-1 me-3">
      <input
        type="search"
        placeholder="Buscar en Fullpits, ejemplo: Cambio de aceite"
        class="form-control"
        name="buscador"
        [(ngModel)]="buscador"
        (keyup.enter)="getBuscar()"
      />
      <button
        class="btn btn-orange p-1"
        type="button"
        (click)="getBuscar()"
        aria-label="Buscar"
      >
        <img
          src="./assets/iconos-header/buscador-fullpits.webp"
          alt="buscador-fullpits.webp"
          class="icono-lupa-buscador"
        />
      </button>
    </div>
    <ng-container *ngIf="usuario; else noNombre">
      <a
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
        class="nav-link p-0"
      >
        <div class="text-white d-flex align-items-center me-3">
          <img
            src="./assets/iconos-header/usuario-fullpits.webp"
            alt="usuario-fullpits"
            class="img-fluid mx-1"
            style="width: 19px; height: auto"
          />
          <span class="ml-1 fw-bold line-height-1"
            >Hola {{ usuario.nombres }}</span
          >
        </div>
      </a>
    </ng-container>
    <ng-template #noNombre>
      <a [href]="urlLogin" class="nav-link p-0">
        <div class="text-white d-flex align-items-center me-3">
          <img
            src="./assets/iconos-header/usuario-fullpits.webp"
            alt="usuario-fullpits"
            class="img-fluid mx-1"
            style="width: 19px; height: auto"
          />
          <span class="ml-1 fw-bold line-height-1">Ingresar</span>
        </div>
      </a>
    </ng-template>
    <ng-container *ngIf="usuario; else NoLogueadoServicio">
      <a [routerLink]="['/mis-servicios']" class="nav-link p-0">
        <div class="text-white d-flex align-items-center me-3">
          <img
            src="./assets/iconos-header/mis-servicios-fullpits.webp"
            alt="mis-servicios-fullpits"
            class="img-fluid mx-1"
            style="width: 23px; height: auto"
          />
          <span class="ml-1 fw-bold line-height-1">Mis servicios</span>
        </div>
      </a>
      <a *ngIf="idAdmin" [routerLink]="['/notificaciones']">
        <div class="position-relative">
          <i class="fa-regular fa-bell text-white"></i>
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-naranja"
            [ngClass]="{ 'd-none': this.contadorNotificacion == 0 }"
          >
            {{
              this.contadorNotificacion <= 99
                ? this.contadorNotificacion
                : "99+"
            }}
            <span class="visually-hidden">unread messages</span>
          </span>
        </div>
      </a>
    </ng-container>
    <ng-template #NoLogueadoServicio>
      <a [href]="urlLogin" class="nav-link p-0">
        <div class="text-white d-flex align-items-center me-3">
          <img
            src="./assets/iconos-header/mis-servicios-fullpits.webp"
            alt="mis-servicios-fullpits"
            class="img-fluid mx-1"
            style="width: 23px; height: auto"
          />
          <span class="ml-1 fw-bold line-height-1">Mis servicios</span>
        </div>
      </a>
    </ng-template>
  </div>
</nav>

<!-- Header para movil -->
<nav
  id="header-movil"
  class="navbar navbar-dark bg-negro py-1 d-lg-none d-block sticky-top"
  style="z-index: 2"
>
  <div
    class="container-fluid align-items-lg-center justify-content-center px-2"
  >
    <!-- Se en medidas inferiores a 449px de ancho -->
    <div class="d-flex align-items-center d-xs-none flex-grow-1">
      <div>
        <a
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
        >
          <img
            src="./assets/iconos-header/menu-fullpits.webp"
            alt="menu-fullpits"
            class="img-fluid"
            style="width: 30px; height: auto"
          />
        </a>
      </div>
      <div class="flex-grow-1">
        <div class="cursor-pointer" [routerLink]="['/']">
          <img
            src="./assets/iconos-header/logo-naranja-fullpits.webp"
            alt="logo-naranja-fullpits"
            class="img-fluid"
            style="width: 110px"
          />
        </div>
      </div>
      <ng-container *ngIf="usuario; else noNombreMovil">
        <a
          data-bs-toggle="offcanvas"
          href="#offcanvasExample"
          role="button"
          aria-controls="offcanvasExample"
          class="nav-link p-0 d-flex d-xs-none"
        >
          <div class="text-white d-flex align-items-center ms-5 me-1">
            <img
              src="./assets/iconos-header/usuario-fullpits.webp"
              alt="usuario-fullpits"
              class="img-fluid"
              style="width: 25px; height: auto"
            />
            <span class="ml-1 fw-bold line-height-1"
              >Hola {{ usuario.nombres }}</span
            >
          </div>
        </a>
      </ng-container>
      <ng-template #noNombreMovil>
        <a [href]="urlLogin" class="nav-link p-0">
          <div
            class="text-white d-flex align-items-center ms-5 me-1 d-flex d-xs-none"
          >
            <img
              src="./assets/iconos-header/usuario-fullpits.webp"
              alt="usuario-fullpits"
              class="img-fluid"
              style="width: 25px; height: auto"
            />
            <span class="ml-1 fw-bold line-height-1">Ingresar</span>
          </div>
        </a>
      </ng-template>
      <ng-container *ngIf="usuario; else noLogueadoServicioMovil">
        <a
          [routerLink]="['/mis-servicios']"
          class="nav-link p-0 d-block d-xs-none"
        >
          <div class="text-white d-flex align-items-center mx-2">
            <img
              src="./assets/iconos-header/mis-servicios-fullpits.webp"
              alt="mis-servicios-fullpits"
              class="img-fluid"
              style="width: 30px; height: auto"
            />
          </div>
        </a>
        <a *ngIf="idAdmin" [routerLink]="['/notificaciones']">
          <div class="d-block d-xs-none mx-1 mt-1 position-relative">
            <i class="fa-regular fa-bell text-white fs-3"></i>
            <span
              class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-naranja"
              [ngClass]="{ 'd-none': this.contadorNotificacion == 0 }"
            >
              {{
                this.contadorNotificacion <= 99
                  ? this.contadorNotificacion
                  : "99+"
              }}
              <span class="visually-hidden">unread messages</span>
            </span>
          </div>
        </a>
      </ng-container>
      <ng-template #noLogueadoServicioMovil>
        <a [href]="urlLogin" class="nav-link p-0 d-block d-xs-none">
          <div class="text-white d-flex align-items-center mx-2">
            <img
              src="./assets/iconos-header/mis-servicios-fullpits.webp"
              alt="mis-servicios-fullpits"
              class="img-fluid"
              style="width: 30px; height: auto"
            />
          </div>
        </a>
      </ng-template>
    </div>
    <!-- Se muestra en superiores o igual a 450px de ancho -->
    <div class="d-none d-xs-block">
      <a
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
      >
        <img
          src="./assets/iconos-header/menu-fullpits.webp"
          alt="menu-fullpits"
          class="img-fluid"
          style="width: 40px; height: auto"
        />
      </a>
    </div>
    <div class="flex-grow-1 d-none d-xs-block">
      <div class="cursor-pointer" [routerLink]="['/']">
        <img
          src="./assets/iconos-header/logo-naranja-fullpits.webp"
          alt="logo-naranja-fullpits"
          class="img-fluid"
          style="width: 147px"
        />
      </div>
    </div>
    <ng-container *ngIf="usuario; else noNombreMovil2">
      <a
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        role="button"
        aria-controls="offcanvasExample"
        class="nav-link p-0 d-none d-xs-block"
      >
        <div class="text-white d-flex align-items-center ms-5 me-1">
          <img
            src="./assets/iconos-header/usuario-fullpits.webp"
            alt="usuario-fullpits"
            class="img-fluid"
            style="width: 25px; height: auto"
          />
          <span class="ml-1 fw-bold line-height-1"
            >Hola {{ usuario.nombres }}</span
          >
        </div>
      </a>
    </ng-container>
    <ng-template #noNombreMovil2>
      <a [href]="urlLogin" class="nav-link p-0 d-none d-xs-block">
        <div class="text-white d-flex align-items-center ms-5 me-1">
          <img
            src="./assets/iconos-header/usuario-fullpits.webp"
            alt="usuario-fullpits"
            class="img-fluid"
            style="width: 25px; height: auto"
          />
          <span class="ml-1 fw-bold line-height-1">Ingresar</span>
        </div>
      </a>
    </ng-template>
    <ng-container *ngIf="usuario; else noLogueadoServicioMovil2">
      <a
        [routerLink]="['/mis-servicios']"
        class="nav-link p-0 d-none d-xs-block"
      >
        <div class="text-white d-flex align-items-center mx-2">
          <img
            src="./assets/iconos-header/mis-servicios-fullpits.webp"
            alt="mis-servicios-fullpits"
            class="img-fluid"
            style="width: 30px; height: auto"
          />
        </div>
      </a>
      <a *ngIf="idAdmin" [routerLink]="['/notificaciones']">
        <div class="d-none d-xs-block mx-1 mt-1 position-relative">
          <i class="fa-regular fa-bell text-white fs-3"></i>
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-naranja"
            [ngClass]="{ 'd-none': this.contadorNotificacion == 0 }"
          >
            {{
              this.contadorNotificacion <= 99
                ? this.contadorNotificacion
                : "99+"
            }}
            <span class="visually-hidden">unread messages</span>
          </span>
        </div>
      </a>
    </ng-container>
    <ng-template #noLogueadoServicioMovil2>
      <a [href]="urlLogin" class="nav-link p-0 d-none d-xs-block">
        <div class="text-white d-flex align-items-center mx-2">
          <img
            src="./assets/iconos-header/mis-servicios-fullpits.webp"
            alt="mis-servicios-fullpits"
            class="img-fluid"
            style="width: 30px; height: auto"
          />
        </div>
      </a>
    </ng-template>
    <div class="text-white d-flex align-items-center flex-grow-1">
      <input
        type="search"
        placeholder="Buscar en Fullpits, ej: Cambio de aceite"
        class="form-control mt-0"
        [(ngModel)]="buscador"
        (keyup.enter)="getBuscar()"
      />
      <button
        class="btn btn-orange"
        type="button"
        (click)="getBuscar()"
        style="padding: 0.3rem 0.3rem"
        aria-label="Buscar"
      >
        <img
          src="./assets/iconos-header/buscador-fullpits.webp"
          alt="buscador-fullpits.webp"
          class="icono-lupa-buscador"
        />
      </button>
    </div>
    <div
      class="text-white d-flex align-items-center m-2 p-0 cursor-pointer"
      (click)="abrirDialog()"
    >
      <img
        src="./assets/iconos-header/ubicacion-fullpits.webp"
        alt="ubicacion-fullpits"
        class="img-fluid mx-1"
        style="width: 19px; height: auto"
      />
      <span class="ml-1 fw-bold line-height-1 me-2 texto-ubicacion">{{
        textoUbicacion
      }}</span>
      <i class="fas fa-caret-down"></i>
    </div>
  </div>
</nav>
<app-offcanvas [usuario]="usuario"></app-offcanvas>

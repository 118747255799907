import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject } from 'rxjs';
import { RespuestaNotificacion } from 'src/app/models/notificacion-respuesta';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private afMessaging: AngularFireMessaging,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  requestPermission() {
    return this.afMessaging.requestPermission;
  }
  getToken() {
    return this.afMessaging.getToken;
  }

  escucharNotificaciones() {
    if (isPlatformBrowser(this.platformId)) {
      navigator.serviceWorker.addEventListener('message', (mensaje) => {
        let notificacion: RespuestaNotificacion =
          mensaje.data.firebaseMessagingData;
      });
    }
  }
}

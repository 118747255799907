<div class="row">
  <div
    class="col-lg-4 d-lg-block d-none text-center bg-listado"
    style="
      background-image: url('./assets/images/home/listado-de-talleres-y-autolavados.webp');
    "
  ></div>
  <div class="col-lg-8 bg-white min-vh-100">
    <ng-container *ngIf="talleres.length === 0">
      <h2 class="fw-bold">No se encontraron resultados para "{{ search }}"</h2>
    </ng-container>
    <ng-container *ngFor="let taller of talleres; let i = index">
      <div class="row my-lg-5 mb-3 align-items-center card-effect mx-lg-3">
        <div class="col-lg-3 col-5 col-sm-3 p-0">
          <a [routerLink]="['/talleres', taller.id + '-' + slugTalleres[i]]">
            <img
              [src]="taller.profilePicture"
              alt="taller"
              class="img-fluid imagen_card height-lg-175 height-138"
              loading="lazy"
            />
          </a>
        </div>
        <div
          class="col-lg-9 col-7 col-sm-9 px-1 ps-lg-4 height-lg-175 height-138"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <a
                [routerLink]="['/talleres', taller.id]"
                class="nav-link text-dark p-0"
              >
                <h2
                  class="fw-bold text-start mb-0 texto-titulo"
                  style="line-height: 1"
                >
                  {{ taller.name }}
                </h2>
              </a>
            </div>
            <div class="me-1">
              <div
                class="d-none mostrar sombra px-1"
                style="background-color: rgba(255, 255, 255, 0.8)"
              >
                <a class="nav-link fw-bold text-black p-1"
                  ><i class="far fa-heart"></i
                ></a>
              </div>
            </div>
          </div>
          <ng-container *ngIf="taller.qualification! > 0">
            <div class="d-flex justify-content-start align-items-center">
              <!-- INICIA ESTRELLAS -->
              <div class="d-md-block d-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 0.3 &&
                      taller.qualification! <= 0.7;
                    else nuevaValidacion1
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion1>
                  <ng-container
                    *ngIf="taller.qualification! >= 0.8; else vacio1"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio1>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-md-block d-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 1.3 &&
                      taller.qualification! <= 1.7;
                    else nuevaValidacion2
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion2>
                  <ng-container
                    *ngIf="taller.qualification! >= 1.8; else vacio2"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio2>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-md-block d-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 2.3 &&
                      taller.qualification! <= 2.7;
                    else nuevaValidacion3
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion3>
                  <ng-container
                    *ngIf="taller.qualification! >= 2.8; else vacio3"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio3>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-md-block d-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 3.3 &&
                      taller.qualification! <= 3.7;
                    else nuevaValidacion4
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion4>
                  <ng-container
                    *ngIf="taller.qualification! >= 3.8; else vacio4"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-md-block d-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 4.3 &&
                      taller.qualification! <= 4.7;
                    else nuevaValidacion5
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion5>
                  <ng-container
                    *ngIf="taller.qualification! >= 4.8; else vacio4"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <!-- FIN ESTRELLAS -->
              <!-- INICIA ESTRELLAS MOVIL -->
              <div class="d-block d-md-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 0.3 &&
                      taller.qualification! <= 0.7;
                    else nuevaValidacion1movil
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 15px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion1movil>
                  <ng-container
                    *ngIf="taller.qualification! >= 0.8; else vacio1movil"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio1movil>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-block d-md-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 1.3 &&
                      taller.qualification! <= 1.7;
                    else nuevaValidacion2movil
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 15px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion2movil>
                  <ng-container
                    *ngIf="taller.qualification! >= 1.8; else vacio2movil"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio2movil>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-block d-md-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 2.3 &&
                      taller.qualification! <= 2.7;
                    else nuevaValidacion3movil
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 15px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion3movil>
                  <ng-container
                    *ngIf="taller.qualification! >= 2.8; else vacio3movil"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio3movil>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-block d-md-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 3.3 &&
                      taller.qualification! <= 3.7;
                    else nuevaValidacion4movil
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 15px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion4movil>
                  <ng-container
                    *ngIf="taller.qualification! >= 3.8; else vacio4movil"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4movil>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div class="d-block d-md-none">
                <ng-container
                  *ngIf="
                    taller.qualification! >= 4.3 &&
                      taller.qualification! <= 4.7;
                    else nuevaValidacion5movil
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 15px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion5movil>
                  <ng-container
                    *ngIf="taller.qualification! >= 4.8; else vacio4movil"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4movil>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 15px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <!-- FIN ESTRELLAS MOVIL -->
              <div>
                <span class="text-naranja fw-bold ms-1 numero-calificacion">{{
                  taller.qualification! | number: "1.1-1"
                }}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="taller.esCercano; else verDistancia">
            <div class="mb-lg-2">
              <a
                class="cursor-pointer nav-link p-0 text-dark"
                (click)="abrirDialog()"
              >
                A {{ taller.distancia }} de tu ubicación
              </a>
            </div>
          </ng-container>
          <ng-template #verDistancia>
            <ng-container
              *ngIf="taller.esCercano === false; else noUbicacionActual"
            >
              <div
                class="d-flex justify-content-start align-items-center mb-lg-2"
              >
                <a
                  class="cursor-pointer nav-link p-0 text-dark text-overflow-distancia"
                  (click)="abrirDialog()"
                  >Está a mas de {{ distancia }}km de tu ubicación</a
                >
              </div>
            </ng-container>
            <ng-template #noUbicacionActual>
              <div class="d-flex justify-content-start align-items-center mb-2">
                <a class="cursor-pointer text-dark" (click)="abrirDialog()"
                  >Ver distancia</a
                >
              </div>
            </ng-template>
          </ng-template>
          <div class="me-1">
            <p
              class="text-secondary text-lg-start text-overflow-descripcion w-100 texto-servicio mb-lg-2 mb-0"
              style="height: 20px"
            >
              <ng-container
                *ngFor="
                  let servicio of taller.workshopTypeServices;
                  let i = index;
                  let ultimo = last
                "
              >
                {{ servicio.title }} {{ ultimo ? "" : "-" }}
              </ng-container>
            </p>
          </div>
          <div class="text-start me-1">
            <a
              class="btn btn-naranja texto-boton px-2 px-lg-5 w-100"
              (click)="traerOpcionSolicitud(taller)"
            >
              <div
                class="d-flex justify-content-center align-items-center gap-3"
              >
                <div>
                  <img
                    src="./assets/images/footer/whatsapp-fullpits.webp"
                    class="width-imagen"
                    alt="solicitar-servicio"
                    loading="lazy"
                  />
                </div>
                <div style="line-height: 1">
                  <p class="mb-0">Cotizar o agendar servicios</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

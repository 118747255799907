<h1 class="fw-bold">
  <a href="javascript: history.go(-1)" class="text-black d-inline d-lg-none"
    ><i class="fa-solid fa-arrow-left"></i
  ></a>
  Editar Perfil
</h1>
<div class="row">
  <div class="col-lg-12">
    <form
      class="example-form"
      [formGroup]="formulario"
      (ngSubmit)="guardarPerfil()"
    >
      <div class="mb-3">
        <label for="nombre" class="form-label">Nombres *</label>
        <input
          type="text"
          placeholder="Ingrese aquí su nombre"
          class="form-control"
          formControlName="nombres"
          id="nombre"
          [ngClass]="{
            invalid:
              formulario.get('nombres')!.invalid &&
              formulario.get('nombres')!.touched
          }"
        />
      </div>
      <div class="mb-3">
        <label for="apellido" class="form-label">Apellidos *</label>
        <input
          type="text"
          placeholder="Ingrese aquí su apellido"
          class="form-control"
          formControlName="apellidos"
          id="apellido"
          [ngClass]="{
            invalid:
              formulario.get('apellidos')!.invalid &&
              formulario.get('apellidos')!.touched
          }"
        />
      </div>
      <div class="mb-3">
        <label for="identidad" class="form-label">Número de identidad *</label>
        <input
          type="number"
          placeholder="Ingrese aquí su número de identidad"
          class="form-control"
          formControlName="identificacion"
          id="identidad"
          [ngClass]="{
            invalid:
              formulario.get('identificacion')!.invalid &&
              formulario.get('identificacion')!.touched
          }"
        />
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Correo electrónico *</label>
        <input
          type="email"
          placeholder="Ingrese aquí su correo electrónico"
          class="form-control"
          formControlName="email"
          id="email"
          [ngClass]="{
            invalid:
              formulario.get('email')!.invalid &&
              formulario.get('email')!.touched
          }"
        />
      </div>
      <div class="mb-3">
        <label for="pais" class="form-label">País</label>
        <select
          (change)="cambioPais($event)"
          class="form-select"
          aria-label="Default select example"
        >
          <option selected>Seleccione país de residencia</option>
          <ng-container *ngFor="let country of countries | async">
            <option [value]="country.id">{{ country.name }}</option>
          </ng-container>
        </select>
      </div>
      <div class="mb-3">
        <label for="celular" class="form-label">Celular</label>
        <div class="d-flex align-items-center gap-4">
          <mat-form-field class="w-25">
            <mat-label>Indicativo</mat-label>
            <input
              type="string"
              matInput
              formControlName="countryCallCode"
              [matAutocomplete]="auto"
            />

            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of filterOptions | async"
                [value]="option.flag"
                (onSelectionChange)="saveCode(option.code)"
                >{{ option.code }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
          <input
            type="number"
            placeholder="Ingrese aquí su número de celular"
            class="form-control"
            formControlName="phoneNumber"
            id="celular"
          />
        </div>
      </div>
      <div class="mb-3">
        <label for="fecha_nacimiento" class="form-label"
          >Fecha de nacimiento</label
        >
        <input
          type="date"
          placeholder="Ingrese aquí su fecha de nacimiento"
          class="form-control"
          formControlName="fechaNacimiento"
          id="fecha_nacimiento"
        />
      </div>
      <div class="mb-3">
        <label for="sexo" class="form-label">Sexo</label>
        <div
          class="d-flex justify-content-center align-items-center flex-wrap"
          style="gap: 13px"
        >
          <div class="flex-grow-1">
            <button
              type="button"
              class="btn-negro btn w-100"
              (click)="cambiarGenero(1)"
              [ngClass]="{ active: formulario.get('genero')!.value == 1 }"
            >
              Masculino
            </button>
          </div>
          <div class="flex-grow-1">
            <button
              type="button"
              class="btn-negro btn w-100"
              (click)="cambiarGenero(2)"
              [ngClass]="{ active: formulario.get('genero')!.value == 2 }"
            >
              Femenino
            </button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <button type="submit" class="btn-orange btn text-white w-100">
          Guardar cambios
        </button>
      </div>
    </form>
  </div>
</div>

<div
  class="container-fluid bg py-3"
  [ngClass]="{ 'bg-gris-claro': idBussiness === 4 }"
  style="position: relative"
>
  <div class="d-flex justify-content-between align-items-center">
    <div style="flex-grow: 8">
      <h2 class="mb-0">
        <div class="text-servicio-moto h2" *ngIf="idBussiness === 3">
          {{ titleTaller }}
        </div>
      </h2>
      <h2 class="mb-0">
        <div class="text-servicio-moto h2" *ngIf="idBussiness === 4">
          {{ titleTaller }}
        </div>
      </h2>
    </div>
    <ng-container *ngIf="idBussiness === 3">
      <div class="flex-grow-1">
        <div
          class="cursor-pointer text-dark nav-link"
          (click)="listadoTaller()"
        >
          <span class="text-ver-mas">Ver más</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="idBussiness === 4">
      <div class="flex-grow-1">
        <div
          class="cursor-pointer text-dark nav-link"
          (click)="listadoTaller()"
        >
          <span class="text-ver-mas">Ver más</span>
        </div>
      </div>
    </ng-container>
    <div></div>
  </div>
  <div class="row mt-3">
    <div>
      <app-card
        [listTalleres]="listTalleres"
        *ngIf="idBussiness === 3"
        [imagenDefault]="'./assets/images/talleres-interna/autolavados.webp'"
        [idBussiness]="idBussiness"
        [slugTalleres]="slugTalleres"
      ></app-card>
      <app-card
        [listTalleres]="listTalleres"
        *ngIf="idBussiness === 4"
        [imagenDefault]="'./assets/images/talleres-interna/motolavados.webp'"
        [idBussiness]="idBussiness"
        [slugTalleres]="slugTalleres"
      ></app-card>
    </div>
  </div>
</div>

<!--app-banner></app-banner-->
<!--app-servicios></app-servicios-->
<div class="talleres-carros">
  <app-talleres
    titleTaller="Talleres para carros"
    categoria="talleres-para-carros"
    tipo="carros"
    [idBussiness]="1"
  ></app-talleres>
</div>
<hr class="m-0" />

<!-- <img
  class="img-fluid"
  src="assets/images/home/promocion-fullpits.webp"
  alt="promocion-fullpits"
/> -->

<!-- Registrar -->

<div class="row guideLine bg-color container-fluid m-0 align-items-center">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 p-4 guideLine">
    <div class="text-center">
      <img
        class="img-fluid"
        src="assets/images/home/registra-tu-negocio.webp"
        alt="registra-tu-negocio"
        loading="lazy"
      />
    </div>
  </div>
  <ng-container *ngIf="produccion; else pruebas">
    <div class="col-12 col-lg-6">
      <div class="text-center centrar-grupo">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-0 registra-negocio">
              <span class="mt-5">Registra </span>
              <span class="text-naranja fw-bold mt-2">tu negocio</span>
            </h2>
          </div>
        </div>
        <div class="row justify-content-center align-items-center mt-4 mt-lg-5">
          <div class="col-sm-8 p-2 h5 h2-mobile" style="line-height: normal">
            Digitaliza tu negocio y aumenta el numero de clientes. Llevamos tus
            servicios a más personas y hacemos que les resulte fácil solicitar
            cualquier servicio
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-6 my-2 mt-xl-5">
            <a
              [routerLink]="['/registra-tu-negocio']"
              class="btn btn-conoce w-100"
            >
              <span class="h5 fw-bold">Conoce más</span>
            </a>
          </div>

          <div class="col-12 col-xl-6 my-2 mt-xl-5">
            <a
              href="https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0AEstoy+interesad@+en+vincular+mi+negocio+a+esta+plataforma.&app_absent=0"
              target="_blank"
              type="button"
              class="btn btn-registrate w-100"
            >
              <span class="h5 fw-bold">Contáctanos</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #pruebas>
    <div class="col-12 col-lg-6">
      <div class="text-center centrar-grupo">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-0 registra-negocio">
              <span class="mt-5">Recogemos </span>
              <span class="text-naranja fw-bold mt-2">tu vehículo</span>
            </h2>
          </div>
        </div>
        <div class="row justify-content-center align-items-center mt-4 mt-lg-5">
          <div class="col-sm-8 p-2 h5 h2-mobile" style="line-height: normal">
            ¿Te quedaste parado? ¿no tienes tiempo de llevar tu carro al taller?
            ¡No te preocupes! nosotros recogemos tu vehículo donde quieras que
            estés, si necesitas un servicio de grúa o un conductor para llevarlo
            al taller, nosotros lo hacemos por ti.
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-6 my-2 mt-xl-5">
            <a
              [routerLink]="['/registra-tu-negocio']"
              class="btn btn-conoce w-100"
            >
              <span class="h5 fw-bold">Mas información</span>
            </a>
          </div>

          <div class="col-12 col-xl-6 my-2 mt-xl-5">
            <a
              href="https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0AEstoy+interesad@+en+vincular+mi+negocio+a+esta+plataforma.&app_absent=0"
              target="_blank"
              type="button"
              class="btn btn-registrate w-100"
            >
              <span class="h5 fw-bold">Solicitar servicio</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

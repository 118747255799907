<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <mat-radio-group [(ngModel)]="lubricante" name="lubricantes">
      <ng-container *ngFor="let tipoLubricante of tiposLubricantes">
        <div class="col-lg-12 p-2 border">
          <mat-radio-button [value]="tipoLubricante">{{
            tipoLubricante.name
          }}</mat-radio-button>
        </div>
      </ng-container>
    </mat-radio-group>
  </div>
</div>
<div class="row fixed-bottom bg-white d-lg-none d-block">
  <div class="col-lg-12 d-flex justify-content-end align-items-center">
    <div class="flex-grow-1">
      <button
        mat-button
        color="warn"
        class="w-100"
        type="button"
        (click)="cerrarDialog()"
      >
        Cancelar
      </button>
    </div>
    <div class="flex-grow-1">
      <button
        mat-raised-button
        color="warn"
        class="w-100"
        type="button"
        (click)="seleccionarLubricante()"
      >
        Aceptar
      </button>
    </div>
  </div>
</div>

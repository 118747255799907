import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  PersonaService,
  TypeVehicleService,
  Vehiculo,
  VehiculoService,
} from 'fullpits-commons';
import { SnackbarService } from 'src/app/core/shared/services/snackbar.service';
import { JwtUtils } from 'src/app/core/shared/utils/jwt-utils';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-seleccionar-vehiculo-unico',
  templateUrl: './seleccionar-vehiculo-unico.component.html',
  styleUrls: ['./seleccionar-vehiculo-unico.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeleccionarVehiculoUnicoComponent implements OnInit, OnDestroy {
  misVehiculos: Vehiculo[];
  misVehiculos$ = new Subject<void>();
  persona$ = new Subject<void>();
  constructor(
    private dialogRef: MatDialogRef<SeleccionarVehiculoUnicoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: number,
    private personaService: PersonaService,
    private snackBarService: SnackbarService,
    private vehiculoService: VehiculoService,
    private cd: ChangeDetectorRef,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private typeVehicleService: TypeVehicleService
  ) {}
  ngOnDestroy(): void {
    this.misVehiculos$.next();
    this.misVehiculos$.complete();
    this.persona$.next();
    this.persona$.complete();
  }

  ngOnInit(): void {
    this.traerPersona();
  }

  validarToken() {
    if (isPlatformBrowser(this.platformId)) {
      if (JwtUtils.validarToken().message) {
        this.snackBarService.mostrarSnackBar(JwtUtils.validarToken().message);
        return;
      }
      return JwtUtils.validarToken();
    }
  }

  traerPersona() {
    forkJoin({
      persona: this.personaService.getByUserId(this.validarToken().user.id),
    })
      .pipe(takeUntil(this.persona$))
      .pipe(
        map((vehiculosPersona) => {
          return this.vehiculoService.getByPersonId(
            vehiculosPersona.persona[0].id
          );
        })
      )
      .subscribe((persona) => {
        persona.pipe(takeUntil(this.misVehiculos$)).subscribe((vehiculos) => {
          this.misVehiculos = vehiculos;
          this.cd.markForCheck();
        });
        this.cd.markForCheck();
      });
  }
  agregarVehiculo() {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('solicitar-unico', this.data.toString());
    }
    this.router.navigateByUrl('/mis-vehiculos/lista-vehiculos');
  }
  seleccionarVehiculo(vehiculo: Vehiculo) {
    this.typeVehicleService.getByWorkShopId(this.data).subscribe((resp) => {
      let existeTipoVehiculo = resp.find((filtro) => {
        return filtro.id === vehiculo.typeVehicle?.id;
      });
      if (!existeTipoVehiculo) {
        this.snackBarService.mostrarSnackBar(
          'Este taller no atiende este tipo de vehiculo'
        );
        this.dialogRef.close();
        return;
      }
      this.dialogRef.close(vehiculo);
    });
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}

<div class="d-flex justify-content-between align-items-center">
  <div>
    <a
      class="btn btn-link text-naranja shadow-none"
      (click)="agregarVehiculo()"
      (click)="cerrarDialog()"
    >
      Agregar vehiculo
    </a>
  </div>
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="row">
  <ng-container *ngIf="vehiculos.length > 0; else noVehiculos">
    <ng-container *ngFor="let vehiculo of vehiculos">
      <div
        class="col-lg-12 p-3 border bg-gris cursor-pointer"
        [id]="vehiculo?.id"
        (click)="datosSeleccionados(vehiculo)"
      >
        <div class="d-flex justify-content-center align-items-center gap-13">
          <div>
            <div
              class="circle"
              [style]="{ background: '#' + vehiculo.color }"
            ></div>
          </div>
          <div>
            <p class="mb-0 fw-bold">
              {{ vehiculo!.linea!.marca!.name }}, {{ vehiculo!.linea!.name }},
              {{ vehiculo!.model }}, {{ vehiculo!.placa }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noVehiculos>
    <div class="col-lg-12 p-3 border bg-gris mx-auto">
      <p class="text-center mb-0 text-primary">Agregar vehiculo</p>
    </div>
  </ng-template>
</div>

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { UserValidate } from '../../../models/userValidate';
import { Person, PersonaService } from 'fullpits-commons';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoleUserGuard implements CanActivate {
  user: UserValidate;
  validateUser: boolean = false;
  idAdmin: string;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private persona: PersonaService,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.validateRoleUser();
    return this.validatePersonType();
  }

  validateRoleUser() {
    if (isPlatformBrowser(this.platformId)) {
      this.idAdmin = <string>sessionStorage.getItem('idAdmin');
      if (this.idAdmin) {
        this.user = <UserValidate>(
          JSON.parse(<string>sessionStorage.getItem('user'))
        );
      } else {
        this.user = <UserValidate>(
          JSON.parse(<string>localStorage.getItem('user'))
        );
      }
    }
  }

  validatePersonType(): Observable<boolean> {
    return this.persona.getByUserId(this.user?.user.id).pipe(
      map((resp: Person[]) => {
        if (resp[0]?.type === 1 || this.idAdmin) {
          return true;
        } else {
          this.router.navigateByUrl('/');
          return false;
        }
      })
    );
  }
}

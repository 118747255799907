import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CardComponent } from './components/card/card.component';
import { MaterialModule } from 'src/app/material/material.module';
import { CommonModule } from '@angular/common';
import { ServiciosCardComponent } from './components/servicios/servicios.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OffcanvasComponent } from './components/offcanvas/offcanvas.component';
import { RouterModule } from '@angular/router';
import { ImageViewerModule } from 'ngx-image-viewer';
import { SwiperModule } from 'swiper/angular';
import { MenuComponent } from './components/menu/menu.component';
import { TextoDinamicoComponent } from './components/texto-dinamico/texto-dinamico.component';
import { InternaVehiculosComponent } from './components/interna-vehiculos/interna-vehiculos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubidaImagenChatComponent } from './components/subida-imagen-chat/subida-imagen-chat.component';
import { ChatAudioComponent } from './components/chat-audio/chat-audio.component';
import { ImgCircleComponent } from './components/img-circle/img-circle.component';
import { InternaMiPerfilComponent } from './components/interna-mi-perfil/interna-mi-perfil.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SolicitarServicioUnicoComponent } from './components/solicitar-servicio-unico/solicitar-servicio-unico.component';
import { LightboxModule } from 'ngx-lightbox';
import { MenuChatComponent } from './components/menu-chat/menu-chat.component';
import { TarjetasListadosComponent } from './components/tarjetas-listados/tarjetas-listados.component';
import { FilterCityByFranchiseComponent } from './components/filter-city-by-franchise/filter-city-by-franchise.component';
@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    MaterialModule,
    NgImageSliderModule,
    CarouselModule,
    RouterModule,
    SwiperModule,
    ReactiveFormsModule,
    FormsModule,
    ImageViewerModule.forRoot(),
    LightboxModule,
  ],
  declarations: [
    NotFoundComponent,
    CardComponent,
    ServiciosCardComponent,
    OffcanvasComponent,
    MenuComponent,
    TextoDinamicoComponent,
    InternaVehiculosComponent,
    SubidaImagenChatComponent,
    ChatAudioComponent,
    ImgCircleComponent,
    InternaMiPerfilComponent,
    SnackbarComponent,
    SolicitarServicioUnicoComponent,
    MenuChatComponent,
    TarjetasListadosComponent,
    FilterCityByFranchiseComponent,
  ],
  exports: [
    CardComponent,
    NotFoundComponent,
    ServiciosCardComponent,
    OffcanvasComponent,
    MenuComponent,
    TextoDinamicoComponent,
    InternaVehiculosComponent,
    ChatAudioComponent,
    ImgCircleComponent,
    InternaMiPerfilComponent,
    SolicitarServicioUnicoComponent,
    MenuChatComponent,
    TarjetasListadosComponent,
    FilterCityByFranchiseComponent,
  ],
  providers: [],
})
export class SharedModule {
  constructor() {}
}

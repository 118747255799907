import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize, map } from 'rxjs/operators';
import { Chat } from 'src/app/models/chat';
import { environment } from 'src/environments/environment';
import { FullpitFirestore } from 'fullpits-commons';

@Injectable({
  providedIn: 'root',
})
export class FullpitFirebaseService {
  firebaseCollection: AngularFirestoreCollection<FullpitFirestore>;
  filePath: any;
  downloadURL: string;
  constructor(
    private firebase: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  guardarSolicitud(
    idTaller: number,
    idSolicitud: number | undefined,
    fullpitFirebase: FullpitFirestore
  ) {
    return this.firebase
      .doc(
        `${environment.coleccionFirebaseTalleres}/${idTaller}/${environment.documentosFirebaseTalleres}/${idSolicitud}`
      )
      .set(fullpitFirebase);
  }
  guardarPropuesta(
    idSolicitud: number | undefined,
    fullpitFirebase: FullpitFirestore
  ) {
    return this.firebase
      .doc(`${environment.colleccionPropuestas}/${idSolicitud}`)
      .set(fullpitFirebase);
  }

  traerSolicitud(idTaller: number, idSolicitud: number) {
    return this.firebase
      .doc(
        `${environment.coleccionFirebaseTalleres}/${idTaller}/${environment.documentosFirebaseTalleres}/${idSolicitud}`
      )
      .snapshotChanges();
  }
  eliminarSolicitud(idTaller: number, idSolicitud: number) {
    this.firebase
      .doc(
        `${environment.coleccionFirebaseTalleres}/${idTaller}/${environment.documentosFirebaseTalleres}/${idSolicitud}`
      )
      .delete();
  }
  // TRAER LA INFORMACIÓN DE PROPUESTAS DE FIREBASE
  traerPropuestas(idSolicitud: number) {
    return this.firebase
      .doc(`${environment.colleccionPropuestas}/${idSolicitud}`)
      .snapshotChanges();
  }
  traerChat(idChat: number) {
    return this.firebase
      .collection(`${environment.coleccionChat}/${idChat}/mensajes`)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<any>[]) => {
          return actions.map((a) => {
            return a.payload.doc.data();
          });
        })
      );
  }
  enviarMensajeAlChat(idChat: number, mensaje: Chat) {
    return this.firebase
      .collection(`${environment.coleccionChat}/${idChat}/mensajes`)
      .add(mensaje);
  }

  uploadImage(mensaje: Chat, image: File, idChat: number) {
    this.filePath = `${environment.storageRoute}/${new Date()}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, image);
    return task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((urlImage) => {
          this.downloadURL = urlImage;
          mensaje.message = this.downloadURL;
          this.enviarMensajeAlChat(idChat, mensaje);
        });
      })
    );
  }
  uploadAudio(mensaje: Chat, image: File, idChat: number) {
    this.filePath = `${environment.storageRouteAudio}/${new Date()}`;
    const fileRef = this.storage.ref(this.filePath);
    const task = this.storage.upload(this.filePath, image);
    return task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((urlImage) => {
          this.downloadURL = urlImage;
          mensaje.message = this.downloadURL;
          this.enviarMensajeAlChat(idChat, mensaje);
        });
      })
    );
  }
}

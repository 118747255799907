import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
} from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Acumulable } from 'src/app/models/acumulable';
import { environment } from 'src/environments/environment';
import { Fullpit } from 'fullpits-commons';

@Injectable({
  providedIn: 'root',
})
export class BonoFidelizacionService {
  constructor(private firebase: AngularFirestore) {}

  traerFidelizaciones(idUser: number) {
    return this.firebase
      .collection(`${environment.coleccionFidelizacion}/${idUser}/servicios`)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<any>[]) => {
          return actions.map((a) => {
            return a.payload.doc.data();
          });
        })
      );
  }

  saveFidelizacion(idUser: number, fullpit: Fullpit) {
    this.firebase
      .doc(
        `${environment.coleccionFidelizacion}/${idUser}/servicios/${fullpit.id}`
      )
      .set(fullpit);
  }
  eliminarFidelizacion(idUser: number, idFullpit: number) {
    this.firebase
      .doc(
        `${environment.coleccionFidelizacion}/${idUser}/servicios/${idFullpit}`
      )
      .delete();
  }
  saveAcumuladoDisponible(idUser: number, bono: Acumulable) {
    this.firebase
      .doc(`${environment.coleccionFidelizacion}/${idUser}`)
      .set(bono);
  }
  traerAcumuladoDisponible(idUser: number) {
    return this.firebase
      .doc(`${environment.coleccionFidelizacion}/${idUser}`)
      .snapshotChanges();
  }
  crearAncla(idUser: number, idFullpit: number) {
    this.firebase
      .doc(`${environment.coleccionFidelizacion}/${idUser}/ancla/ancla1`)
      .set({ id: idFullpit });
  }
}

import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Menu } from 'src/app/models/menu';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() menu: Menu[] = [];
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit(): void {}

  scrollInicio() {
    if (isPlatformBrowser(this.platformId)) {
      $('html, body').animate({
        scrollTop: 0,
      });
    }
  }
}

import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificacionesFirebaseService } from './core/shared/services/notificaciones-firebase.service';
import { PushNotificationService } from './core/shared/services/push-notification.service';
import { VariableGlobalService } from './core/shared/services/variable-global.service';
import { JwtUtils } from './core/shared/utils/jwt-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  installEvent: any = null;
  isChat: boolean = false;
  isChat$ = new Subject<void>();
  notificaciones$ = new Subject<void>();
  constructor(
    private swUpdate: SwUpdate,
    private pushNotification: PushNotificationService,
    @Inject(PLATFORM_ID) private platformId: any,
    private notificacionesFirebaseService: NotificacionesFirebaseService,
    private variableGlobalService: VariableGlobalService
  ) {}

  ngOnInit() {
    this.validarToken();
    this.escucharNotificaciones();
    this.updatePWA();
    this.permitirNotiicaciones();
    this.ocultarMostrarWpp();
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem('notification-wpp')) {
        localStorage.setItem('notification-wpp', 'true');
      }
    }
  }
  ocultarMostrarWpp() {
    this.variableGlobalService.actualOcultarFooter
      .pipe(takeUntil(this.isChat$))
      .subscribe((resp) => {
        this.isChat = resp;
      });
  }

  @HostListener('window: beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: Event) {
    event.preventDefault();
    this.installEvent = event;
  }
  installByUser() {
    if (this.installEvent) {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((rta: any) => {});
    }
  }

  permitirNotiicaciones() {
    this.pushNotification.requestPermission().subscribe((resp) => {
      if (resp === 'granted') {
        this.pushNotification.getToken().subscribe((token) => {});
      }
    });
  }
  escucharNotificaciones() {
    this.pushNotification.escucharNotificaciones();
  }
  traerNotificaciones(idUser: number) {
    this.notificacionesFirebaseService
      .traerNotificaciones(idUser)
      .subscribe((resp: any) => {
        let contador: number = 0;
        resp.splice(environment.cantidadNotificacionMostrar);
        resp.forEach((notificacion: any) => {
          if (!notificacion.viewed) {
            contador++;
          }
        });
        this.variableGlobalService.consultarContadorNotificacion(contador);
      });
  }
  validarToken() {
    if (isPlatformBrowser(this.platformId)) {
      if (!JwtUtils.validarToken().message) {
        this.traerNotificaciones(JwtUtils.validarToken().user.id);
      }
    }
  }
  updatePWA() {
    this.swUpdate.available.subscribe((valor) => {
      if (isPlatformBrowser(this.platformId)) {
        window.location.reload();
      }
    });
  }
}

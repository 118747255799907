<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" style="margin-top: 100px">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div
          class="d-flex justify-content-center align-items-center flex-column flex-lg-row"
        >
          <div>
            <!--img
              [src]="servicioObj.typeService?.image"
              [alt]="servicioObj.typeService?.name"
              width="119"
            /-->
          </div>
          <div>
            <h3
              class="fw-bold mb-0 text-center text-lg-start"
              style="font-size: 21px"
            >
              {{ servicioObj.title }}
            </h3>
            <p
              class="text-center text-lg-start"
              [innerHTML]="servicioObj.description"
            ></p>
            <ng-container *ngIf="servicioObj?.price">
              <h3 class="mb-0 fw-bold text-center text-lg-start">
                Precio COP
                {{ servicioObj.price | currency : "$" : "symbol" : "1.0-0" }}
              </h3>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-around align-items-center">
        <div>
          <h3 class="fw-bold mb-0 text-center text-lg-start">
            Disponible para:
          </h3>
          <div
            class="d-flex justify-content-between align-items-center flex-wrap gap-lg-30"
          >
            <ng-container *ngFor="let vehicle of servicioObj?.typeVehicleList">
              <div
                class="d-flex justify-content-start align-items-center"
                style="gap: 4px"
              >
                <div>
                  <img
                    [src]="vehicle.imagen"
                    alt="servicio_domicilio"
                    class="img-fluid"
                    width="40"
                  />
                </div>
                <div>
                  <p class="mb-0">{{ vehicle.name }}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div>
          <h3 class="fw-bold mb-0 text-center text-lg-start">
            Servicio prestado en:
          </h3>
          <div
            class="d-flex justify-content-between align-items-center"
            style="gap: 30px"
          >
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 4px"
              *ngIf="servicioObj ? servicioObj.homeService : 'false'"
            >
              <div>
                <img
                  src="./assets/images/talleres-interna/servicio_domicilio.webp"
                  alt="servicio_domicilio"
                  class="img-fluid"
                  width="40"
                />
              </div>
              <div>
                <p class="mb-0">A domicilio</p>
              </div>
            </div>
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 4px"
              *ngIf="servicioObj ? servicioObj.onSiteService : false"
            >
              <div>
                <img
                  src="./assets/images/talleres-interna/servicio-en-taller.webp"
                  alt="servicio-en-taller"
                  class="img-fluid"
                  width="40"
                />
              </div>
              <div>
                <p class="mb-0">En local</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <a
          class="btn btn-naranja"
          (click)="agendarServicio(servicioObj)"
          data-bs-dismiss="modal"
        >
          <div class="d-flex justify-content-center align-items-center gap-3">
            <div>
              <img
                src="./assets/images/footer/whatsapp-fullpits.webp"
                alt="solicitar-servicio"
                style="width: 40px; height: 40px"
              />
            </div>
            <div>Cotizar o agendar servicio</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

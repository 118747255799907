<div class="position-relative">
  <swiper
    [loop]="true"
    [autoplay]="{ delay: 3000, disableOnInteraction: false }"
    [navigation]="true"
  >
    <ng-template swiperSlide>
      <div
        style="
          background-image: url(assets/banners-home/autolavado-calidad-movil.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-block d-sm-none"
      ></div>
      <div
        style="
          background-image: url(assets/banners-home/autolavado-calidad.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-sm-block d-none"
      ></div>
    </ng-template>
    <ng-template swiperSlide>
      <div
        style="
          background-image: url(assets/banners-home/cuidamos-cada-detalle-movil.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-block d-sm-none"
      ></div>
      <div
        style="
          background-image: url(assets/banners-home/cuidamos-cada-detalle.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-sm-block d-none"
      ></div>
    </ng-template>
    <ng-template swiperSlide>
      <div
        style="
          background-image: url(assets/banners-home/latoneria-y-pintura-movil.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-block d-sm-none"
      ></div>
      <div
        style="
          background-image: url(assets/banners-home/latoneria-y-pintura.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-sm-block d-none"
      ></div>
    </ng-template>
    <ng-template swiperSlide>
      <div
        style="
          background-image: url(assets/banners-home/mecanica-automotriz-movil.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-block d-sm-none"
      ></div>
      <div
        style="
          background-image: url(assets/banners-home/mecanica-automotriz.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-sm-block d-none"
      ></div>
    </ng-template>
    <ng-template swiperSlide>
      <div
        style="
          background-image: url(assets/banners-home/mercedes-benz-movil.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-block d-sm-none"
      ></div>
      <div
        style="
          background-image: url(assets/banners-home/mercedes-benz.webp);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="vh-banner text-end d-sm-block d-none"
      ></div>
    </ng-template>
  </swiper>
  <div
    style="
      background-image: linear-gradient(
        180deg,
        rgba(102, 102, 102, 0) 68%,
        rgba(102, 102, 102, 0.3) 72%,
        rgba(244, 75, 45, 1) 100%
      );
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
    "
    class="position-absolute bottom-0 start-50 translate-middle-x w-100"
  >
    <app-servicios-card></app-servicios-card>
  </div>
</div>

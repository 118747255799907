import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EPaymentMethod, PaymentMethodService } from 'fullpits-commons';
import { PaymentMethod } from 'fullpits-commons';
import { Observable } from 'rxjs';
import { Constantes } from 'src/app/core/shared/utils/constantes';

@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent implements OnInit {
  formPaymentMethod: FormGroup;
  paymentMethod$: Observable<PaymentMethod[]>;
  textPaymentMethod: string = Constantes.TEXT_PAYMENT_METHOD;

  constructor(
    private dialogRef: MatDialogRef<SelectPaymentMethodComponent>,
    private fb: FormBuilder,
    private paymentMethodService: PaymentMethodService
  ) {}

  ngOnInit(): void {
    this.createFormPaymentMethod();
    this.getPaymentMethod();
  }

  getPaymentMethod() {
    this.paymentMethod$ = this.paymentMethodService.getByIdIn([
      EPaymentMethod.EFECTIVO,
      EPaymentMethod.PAGO_EN_LINEA,
      EPaymentMethod.TARJETA_CREDITO_DEBITO,
    ]);
  }

  createFormPaymentMethod() {
    this.formPaymentMethod = this.fb.group({
      paymentMethod: ['', Validators.required],
    });
  }

  save() {
    if (this.formPaymentMethod.invalid) return;
    this.dialogRef.close(this.formPaymentMethod.get('paymentMethod')!.value);
  }
}

<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-lg-10">
      <ng-container *ngIf="boolRegistraNegocio; else circleHome">
        <!-- Registra tu negocio -->
        <div
          class="position-relative"
          [ngClass]="{
            'd-none': boolRegistraNegocio,
            'd-xl-block': boolRegistraNegocio
          }"
          style="margin-bottom: 7rem; margin-top: 7rem"
        >
          <div class="progress" style="height: 1px">
            <div
              class="progress-bar bg-naranja"
              role="progressbar"
              style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <button
            type="button"
            class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
            style="width: 7rem; height: 7rem"
          >
            <p class="mb-0 fw-bold fs-1">{{ registraNegocio[0].number }}</p>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
            style="width: 7rem; height: 7rem"
          >
            <p class="mb-0 fw-bold fs-1">{{ registraNegocio[1].number }}</p>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
            style="width: 7rem; height: 7rem"
          >
            <p class="mb-0 fw-bold fs-1">{{ registraNegocio[2].number }}</p>
          </button>
        </div>
        <div
          class="position-relative"
          [ngClass]="{
            'd-none': boolRegistraNegocio,
            'd-xl-block': boolRegistraNegocio
          }"
          style="margin-bottom: 6rem; margin-top: 9rem"
        >
          <div class="progress" style="height: 1px">
            <div
              class="progress-bar bg-white"
              role="progressbar"
              style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <button
            type="button"
            class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
            style="width: 13rem"
          >
            <div class="mt-3 mb-2 text-step text-center">
              <span [innerText]="registraNegocio[0].texto"></span>
            </div>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
            style="width: 13rem"
          >
            <div class="mt-3 mb-2 text-step text-center">
              <span [innerText]="registraNegocio[1].texto"></span>
            </div>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
            style="width: 13rem"
          >
            <div class="mt-3 mb-2 text-step text-center">
              <span [innerText]="registraNegocio[2].texto"></span>
            </div>
          </button>
        </div>
        <div
          class="flex-column align-items-center justify-content-center"
          [ngClass]="{
            'd-xl-none': boolRegistraNegocio,
            'd-flex': boolRegistraNegocio
          }"
        >
          <div class="text-center">
            <button
              type="button"
              class="btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
              style="width: 4rem; height: 4rem"
            >
              <p class="mb-0 fw-bold fs-1">{{ registraNegocio[0].number }}</p>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-link nav-link shadow-none p-0"
            >
              <div class="mt-2 mb-3 text-step text-center">
                <span [innerText]="registraNegocio[0].texto"></span>
              </div>
            </button>
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
              style="width: 4rem; height: 4rem"
            >
              <p class="mb-0 fw-bold fs-1">{{ registraNegocio[1].number }}</p>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-link nav-link shadow-none p-0"
            >
              <div class="mt-2 mb-3 text-step text-center">
                <span [innerText]="registraNegocio[1].texto"></span>
              </div>
            </button>
          </div>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
              style="width: 4rem; height: 4rem"
            >
              <p class="mb-0 fw-bold fs-1">{{ registraNegocio[2].number }}</p>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-link nav-link shadow-none p-0"
            >
              <div class="mt-2 mb-3 text-step text-center">
                <span [innerText]="registraNegocio[2].texto"></span>
              </div>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #circleHome>
        <!-- Inicio -->
        <div
          class="position-relative"
          [ngClass]="{
            'd-none': !boolRegistraNegocio,
            'd-xl-block': !boolRegistraNegocio
          }"
          style="margin-bottom: 7rem; margin-top: 7rem"
        >
          <div class="progress" style="height: 1px">
            <div
              class="progress-bar bg-naranja"
              role="progressbar"
              style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <button
            type="button"
            class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
            style="width: 7rem; height: 7rem"
          >
            <img
              class="img-fluid"
              [src]="serviciosHome[0].src"
              alt="cerca-de-ti"
            />
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
            style="width: 7rem; height: 7rem"
          >
            <img
              class="img-fluid"
              [src]="serviciosHome[1].src"
              alt="atencion-especializada"
            />
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-outline-naranja-circle rounded-pill shadow-none"
            style="width: 7rem; height: 7rem"
          >
            <img
              class="img-fluid"
              [src]="serviciosHome[2].src"
              alt="ahorra-dinero"
            />
          </button>
        </div>
        <div
          class="position-relative"
          [ngClass]="{
            'd-none': !boolRegistraNegocio,
            'd-xl-block': !boolRegistraNegocio
          }"
          style="margin-bottom: 3rem; margin-top: -2rem"
        >
          <div class="progress" style="height: 1px">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 100%; background-color: #e3e3e3"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <button
            type="button"
            class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
          >
            <div class="mt-3 mb-2 text-step fw-bold text-center">
              <span [innerText]="serviciosHome[0].texto"></span>
            </div>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
          >
            <div class="mt-3 mb-2 text-step fw-bold text-center">
              <span [innerText]="serviciosHome[1].texto"></span>
            </div>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-link nav-link shadow-none p-0 w-100"
          >
            <div class="mt-3 mb-2 text-step fw-bold text-center">
              <span [innerText]="serviciosHome[2].texto"></span>
            </div>
          </button>
        </div>
        <div
          class="position-relative"
          [ngClass]="{
            'd-none': !boolRegistraNegocio,
            'd-xl-block': !boolRegistraNegocio
          }"
          style="margin-bottom: 3rem; margin-top: 4rem"
        >
          <div class="progress" style="height: 1px">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: 100%; background-color: #e3e3e3"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <button
            type="button"
            class="position-absolute top-0 start-0 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
          >
            <div class="row justify-content-center align-items-center">
              <div class="col-4">
                <div class="mt-3 mb-2 text-secondary text-center h6">
                  <span [innerHTML]="serviciosHome[0].descripcion"></span>
                </div>
              </div>
            </div>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-50 translate-middle btn btn-sm btn-link nav-link shadow-none p-0"
          >
            <div class="row justify-content-center align-items-center">
              <div class="col-9">
                <div class="mt-3 mb-2 text-secondary text-center h6">
                  <span [innerHTML]="serviciosHome[1].descripcion"></span>
                </div>
              </div>
            </div>
          </button>
          <button
            type="button"
            class="position-absolute top-0 start-100 translate-middle btn btn-sm btn-link nav-link shadow-none p-0 w-100"
          >
            <div class="row justify-content-center align-items-center">
              <div class="col-4">
                <div class="mt-3 mb-2 text-secondary text-center h6">
                  <span [innerHTML]="serviciosHome[2].descripcion"></span>
                </div>
              </div>
            </div>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div class="col-12 my-3">
  <div class="d-flex flex-wrap align-items-center gap-3">
    <div>
      <span class="h4 fw-bold">Ciudad:</span>
    </div>
    <div *ngIf="editCity">
      <form [formGroup]="formFilterCity" (ngSubmit)="saveCity()">
        <mat-form-field class="w-100">
          <mat-label>Ciudad</mat-label>
          <input
            type="text"
            matInput
            formControlName="city"
            placeholder="Cambiar operario"
            [matAutocomplete]="autoCity"
          />
          <mat-autocomplete #autoCity="matAutocomplete">
            <mat-option
              *ngFor="let city of filteredOptions | async"
              [value]="city.name"
              (click)="selectCity(city)"
              (keydown.enter)="selectCity(city)"
            >
              {{ city.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button
          mat-raised-button
          class="bg-naranja text-white"
          type="submit"
          [disabled]="formFilterCity.invalid"
        >
          Guardar
        </button>
      </form>
    </div>
    <div *ngIf="!editCity">
      <span class="h5">{{ city.name }}</span>
    </div>
  </div>
</div>

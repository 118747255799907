<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div>
  <div class="row">
    <div class="col-md-12 text-center">
      <p class="h2 fw-bold">Ingresa tu ubicación</p>
    </div>
    <div class="col-lg-12 my-4">
      <p class="h6">
        Ingresa tu ubicación para encontrar las mejores talleres mécanicos y
        autolavados en tu zona
      </p>
    </div>
    <div class="col-md-12">
      <input
        ngx-google-places-autocomplete
        #placesRef="ngx-places"
        (onAddressChange)="handleAddressChange($event)"
        class="form-control"
        placeholder="Escribe tu dirección"
      />
    </div>
    <div class="col-md-12 text-center my-4">O continúa con</div>
    <div class="col-md-12 text-center">
      <button class="btn btn-naranja-light" (click)="ubicacionActualGPS()">
        <img
          src="./assets/images/home/ubicar-talleres.webp"
          alt="ubicar-talleres"
          class="img-fluid icono-ubicar-btn"
        />
        Usar mi ubicación actual
      </button>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TalleresComponent } from './pages/talleres/talleres.component';
import { AutolavadosComponent } from './pages/autolavados/autolavados.component';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/core/shared/shared.module';

@NgModule({
  declarations: [TalleresComponent, AutolavadosComponent],
  imports: [CommonModule, MaterialModule, SharedModule],
  exports: [TalleresComponent, AutolavadosComponent],
})
export class CategoriasModule {}

import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { JwtUtils } from 'src/app/core/shared/utils/jwt-utils';
import { ServiciosHome } from 'src/app/models/servicios-home';
import { environment } from 'src/environments/environment';
import { UbicacionActualComponent } from '../dialog/ubicacion-actual/ubicacion-actual.component';
import ServiciosHomeJSON from '../../../assets/json/servicios-home.json';
import { isPlatformBrowser } from '@angular/common';
import { DialogOpcionSolicitudComponent } from '../dialog/dialog-opcion-solicitud/dialog-opcion-solicitud.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss'],
})
export class ServiciosComponent implements OnInit {
  idUser: string | null;
  urlLogin: string = environment.urlLogin;
  imagenesServiciosHome: ServiciosHome[] = ServiciosHomeJSON;
  idAdmin: string;
  constructor(
    private dialog: MatDialog,
    private variableGlobal: VariableGlobalService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    this.variableGlobal.actualToken.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.idAdmin = <string>sessionStorage.getItem('idAdmin');
        if (
          localStorage.getItem(JwtUtils.WEB_TOKEN) ||
          sessionStorage.getItem(JwtUtils.WEB_TOKEN)
        ) {
          if (sessionStorage.getItem(JwtUtils.WEB_TOKEN)) {
            this.idUser = sessionStorage.getItem(JwtUtils.WEB_TOKEN);
          } else {
            this.idUser = localStorage.getItem(JwtUtils.WEB_TOKEN);
          }
          this.variableGlobal.consultarUsuario();
        }
      }
    });
  }
  abrirDialog() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      width: '500px',
    });
  }
  traerOpcionSolicitud(nombreTaller: string = '', servicio?: string) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.idAdmin) {
        this.dialog
          .open(DialogOpcionSolicitudComponent, {
            width: '400px',
            height: 'max-content',
            data: { nombreTaller, servicio },
          })
          .afterClosed()
          .subscribe((resp) => {
            if (resp) {
              if (this.idUser) {
                this.router.navigateByUrl(`/mis-vehiculos/solicitar-servicio`);
              } else {
                if (isPlatformBrowser(this.platformId)) {
                  location.href = environment.urlLogin;
                }
              }
            }
          });
      } else {
        window.open(
          `https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0ANecesito+cotizar+o+solicitar+un+servicio+${
            nombreTaller ? 'en+%2A' + nombreTaller + '%2A' : ''
          }.&app_absent=0`,
          '_blank'
        );
      }
    }
  }
}

<div class="container">
  <h1 class="text-center">Editar vehículo</h1>
  <form [formGroup]="formEditVehicle" (ngSubmit)="guardarVehiculo()">
    <mat-form-field class="w-100" color="warn" appearance="outline">
      <mat-label>Tipo de vehículo</mat-label>
      <input
        type="text"
        matInput
        formControlName="typeVehicle"
        placeholder="Escoge un tipo de vehículo"
        [matAutocomplete]="autoTipoVehicle"
      />
      <mat-autocomplete #autoTipoVehicle="matAutocomplete">
        <mat-option
          *ngFor="let typeVehicle of typeVehicle$ | async"
          [value]="typeVehicle.name"
          (onSelectionChange)="setTypeVehicle(typeVehicle)"
        >
          {{ typeVehicle.name }}</mat-option
        >
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      class="w-100"
      color="warn"
      appearance="outline"
      *ngIf="this.selectTypeVehicle"
    >
      <mat-label>Marca</mat-label>
      <input
        type="text"
        matInput
        formControlName="marca"
        placeholder="Escoge una marca"
        [matAutocomplete]="autoMarca"
      />
      <mat-autocomplete #autoMarca="matAutocomplete">
        <mat-option
          *ngFor="let marca of marca$ | async"
          [value]="marca.name"
          (onSelectionChange)="setMarca(marca)"
        >
          {{ marca.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field
      class="w-100"
      color="warn"
      appearance="outline"
      *ngIf="this.selectMarca"
    >
      <mat-label>Linea</mat-label>
      <input
        type="text"
        matInput
        formControlName="linea"
        placeholder="Escoge una línea"
        [matAutocomplete]="autoLinea"
      />
      <mat-autocomplete #autoLinea="matAutocomplete">
        <mat-option
          *ngFor="let linea of linea$ | async"
          [value]="linea.name"
          (onSelectionChange)="setLinea(linea)"
        >
          {{ linea.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="w-100" color="warn" appearance="outline">
      <mat-label>Modelo</mat-label>
      <input
        type="text"
        matInput
        formControlName="model"
        placeholder="Escoge una modelo"
        [matAutocomplete]="autoModelo"
      />
      <mat-autocomplete #autoModelo="matAutocomplete">
        <mat-option *ngFor="let model of models$ | async" [value]="model">{{
          model
        }}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="w-100" color="warn" appearance="outline">
      <mat-label>Placa</mat-label>
      <input
        type="text"
        matInput
        formControlName="placa"
        placeholder="Escoge una placa"
      />
    </mat-form-field>
    <mat-form-field class="w-100" color="warn" appearance="outline">
      <mat-label>Color</mat-label>
      <input type="color" list="colors" matInput formControlName="color" />
    </mat-form-field>
    <datalist id="colors">
      <ng-container *ngFor="let color of colores">
        <option [value]="color.color"></option>
      </ng-container>
    </datalist>
    <mat-form-field class="w-100" color="warn" appearance="outline">
      <mat-label>Combustible</mat-label>
      <input
        type="text"
        matInput
        formControlName="fuel"
        placeholder="Escoge un tipo de combustible"
        [matAutocomplete]="autoFuel"
      />
      <mat-autocomplete #autoFuel="matAutocomplete">
        <mat-option
          *ngFor="let fuel of fuel$ | async"
          [value]="fuel.name"
          (onSelectionChange)="setFuel(fuel)"
        >
          {{ fuel.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <button mat-raised-button color="warn" class="w-100">
      Guardar cambios
    </button>
  </form>
</div>

export const environment = {
  firebase: {
    projectId: 'inndex-aliados',
    appId: '1:175107949846:web:6526c0ef456f778848fc37',
    databaseURL: 'https://inndex-aliados-default-rtdb.firebaseio.com',
    storageBucket: 'inndex-aliados.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyASY5SKJ8HG4NGozMtJ3k663hm4iFOaIc8',
    authDomain: 'inndex-aliados.firebaseapp.com',
    messagingSenderId: '175107949846',
  },
  production: true,
  despliegue: true,
  apiUrlFullPitsNest: 'https://nest.fullpits.com/',
  backoffice: 'https://backoffice.fullpits.com',
  urlLogin: 'https://login.fullpits.com/',
  distancia: 10,
  cantidadNotificacionMostrar: 20,
  coleccionNotificaciones: 'notificaciones',
  documentoNotificaciones: 'notificaciones',
  colleccionPropuestas: 'propuestas',
  coleccionFirebaseTalleres: 'talleres',
  coleccionChat: 'chat',
  coleccionFidelizacion: 'fidelizacion',
  documentosFirebaseTalleres: 'nuevasSolicitudes',
  storageRoute: 'images',
  storageRouteAudio: 'audio',
  urlHome: 'https://fullpits.com/',
  phoneNumberFullpits: '3016116589',
  countryCallCode: '57',
};

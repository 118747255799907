import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WorkshopsService } from 'fullpits-commons';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { environment } from 'src/environments/environment';
import { Workshop } from 'fullpits-commons';
import { ToastrService } from 'ngx-toastr';
import { UbicacionResponse } from '../../../../models/ubicacion.response';

@Component({
  selector: 'app-autolavados',
  templateUrl: './autolavados.component.html',
  styleUrls: ['./autolavados.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutolavadosComponent implements OnInit, OnDestroy {
  @Input() titleTaller?: string;
  @Input() categoria: string;
  @Input() tipo?: string;
  @Input('idBussiness') idBussiness: number;
  ubicacion$ = new Subject<void>();
  listTalleres: Workshop[];
  listTalleresCercanos: Workshop[] = [];
  ubicacionActual: UbicacionResponse;
  slugTalleres: string[] = [];

  constructor(
    private workshopService: WorkshopsService,
    private variableGlobal: VariableGlobalService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}
  ngOnDestroy(): void {
    this.ubicacion$.next();
    this.ubicacion$.complete();
  }

  ngOnInit(): void {
    this.validarTipoVehiculo();
  }
  listadoTaller() {
    this.router.navigateByUrl(`/${this.categoria}`);
  }
  // tslint:disable-next-line:typedef
  async validarTipoVehiculo() {
    await this.workshopService
      .getAllPaginated(2, 0)
      .toPromise()
      .then((resp) => {
        this.listTalleres = resp;
        this.cd.markForCheck();
      })
      .catch((error) => {
        this.toastr.error(error);
      });
    await this.variableGlobal.currentMessage
      .pipe(takeUntil(this.ubicacion$))
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.ubicacionActual = JSON.parse(
            `${sessionStorage.getItem('ubicacion')}`
          );
          this.listTalleresCercanos = [];
          if (this.ubicacionActual) {
            this.listTalleres.map((taller) => {
              if (
                this.getKilometros(
                  this.ubicacionActual.latitud,
                  this.ubicacionActual.longitud,
                  taller.latitude!,
                  taller.longitude!
                ) <= environment.distancia
              ) {
                let distancia = this.getKilometros(
                  this.ubicacionActual.latitud,
                  this.ubicacionActual.longitud,
                  taller.latitude!,
                  taller.longitude!
                );
                if (distancia > 1) {
                  taller.distancia = distancia + 'Km';
                  taller.distanciaReal = distancia;
                  taller.esCercano = true;
                } else {
                  taller.distancia = distancia * 1000 + 'm  ';
                  taller.distanciaReal = distancia;
                  taller.esCercano = true;
                }
              } else {
                let distancia = this.getKilometros(
                  this.ubicacionActual.latitud,
                  this.ubicacionActual.longitud,
                  taller.latitude!,
                  taller.longitude!
                );
                taller.distanciaReal = distancia;
                taller.esCercano = false;
              }
              this.listTalleresCercanos.push(taller);
            });
          }
          if (this.listTalleresCercanos.length > 0) {
            this.listTalleres = this.listTalleresCercanos.sort(
              (a: Workshop, b: Workshop) => {
                let distancia1: any = a.distanciaReal;
                let distancia2: any = b.distanciaReal;
                if (distancia1 > distancia2) {
                  return 1;
                }
                if (distancia1 < distancia2) {
                  return -1;
                }
                return 0;
              }
            );
          }
        }
        this.assignSlugForWorkshop();
        this.cd.markForCheck();
      });
  }

  assignSlugForWorkshop() {
    this.listTalleres.map((taller) => {
      this.slugTalleres.push(
        taller.name!.toLowerCase().replace(/ /g, '-').trimEnd()
      );
    });
  }

  getKilometros(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    let rad = (x: number) => {
      return (x * Math.PI) / 180;
    };
    let R = 6378.137; //Radio de la tierra en km
    let dLat = rad(lat2 - lat1);
    let dLong = rad(lon2 - lon1);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(lat1)) *
        Math.cos(rad(lat2)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return parseFloat(d.toFixed(1)); //Retorna tres decimales
  }
}

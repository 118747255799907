<div class="container-fluid p-0">
  <div class="row">
    <div class="col-lg-8">
      <h1>
        <a
          href="javascript: history.go(-1)"
          class="nav-link d-inline text-dark p-0 me-2 d-lg-none"
        >
          <i class="fas fa-arrow-left"></i>
        </a>
        <strong>¿Qué servicio necesitas hoy para tu vehículo?</strong>
      </h1>
    </div>
    <div class="col-lg-8">
      <div
        (click)="seleccionarVehiculo()"
        class="d-flex justify-content-between align-items-center p-1 flex-wrap cursor-pointer tarjeta-vehiculos"
        [ngClass]="{ invalid: errorSolicitud.vehiculo }"
      >
        <div class="ms-0 ms-lg-3">
          <ng-container
            *ngIf="vehiculoSeleccionado; else noVehiculoSeleccionado"
          >
            <div
              class="d-flex justify-content-center align-items-center gap-1 flex-wrap fw-bold"
            >
              <div>
                <div
                  class="circle"
                  [style]="{
                    'background-color': '#' + vehiculoSeleccionado.color
                  }"
                ></div>
              </div>
              <div>{{ vehiculoSeleccionado.linea!.marca!.name }},</div>
              <div>{{ vehiculoSeleccionado.linea!.name }},</div>
              <div>{{ vehiculoSeleccionado.model }},</div>
              <div>
                {{ vehiculoSeleccionado.placa }}
              </div>
            </div>
          </ng-container>
          <ng-template #noVehiculoSeleccionado>
            <span><strong>Seleccione un vehículo</strong></span>
          </ng-template>
        </div>
        <div class="me-0 me-lg-3">
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="border-top my-4"></div>
    </div>
    <div class="col-lg-8 mb-3">
      <div
        (click)="seleccionarServicios()"
        class="d-flex justify-content-between align-items-center p-1 flex-wrap cursor-pointer tarjeta-vehiculos"
        [ngClass]="{ invalid: errorSolicitud.servicio }"
      >
        <div class="ms-lg-3 ms-0">
          <span><strong>Selecciona los servicios que necesitas</strong></span>
        </div>
        <div class="me-lg-3 me-0">
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap gap-3 mt-3">
        <ng-container
          *ngFor="let servicio of etiquetaServicioSeleccionado; let i = index"
        >
          <div
            class="d-flex align-items-center justify-content-between gap-13 border px-2"
          >
            <div>
              {{ servicio.title }}
            </div>
            <div>
              <i
                class="fas fa-times cursor-pointer"
                (click)="eliminarServicioSeleccionado(i)"
              ></i>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="col-lg-8 mb-3" *ngIf="cambioAceiteSeleccionado">
      <div
        (click)="seleccionarTipoAceite()"
        class="d-flex justify-content-between align-items-center p-1 flex-wrap cursor-pointer tarjeta-vehiculos"
        [ngClass]="{ invalid: errorSolicitud.lubricante }"
      >
        <div class="ms-lg-3 ms-0">
          <ng-container
            *ngIf="
              lubricanteSeleccionado != null && lubricanteSeleccionado.id != 0;
              else noLubricanteSeleccionado
            "
          >
            <span
              ><strong>{{ lubricanteSeleccionado!.name }}</strong></span
            >
          </ng-container>
          <ng-template #noLubricanteSeleccionado>
            <span><strong>Tipo de lubricante</strong></span>
          </ng-template>
        </div>
        <div class="me-lg-3 me-0">
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-8 mb-3">
      <h1>¿Donde quieres tus servicios?</h1>

      <mat-radio-group name="homeService" [(ngModel)]="homeService">
        <div class="d-flex px-3 pb-3 pt-2 gap-3">
          <mat-radio-button
            [value]="1"
            [ngClass]="{ invalid: errorSolicitud.homeService }"
            >Domicilio</mat-radio-button
          >
          <mat-radio-button
            [value]="0"
            [ngClass]="{ invalid: errorSolicitud.homeService }"
            >En taller o lavadero</mat-radio-button
          >
        </div>
      </mat-radio-group>
    </div>

    <div class="col-lg-8 mb-3">
      <div
        (click)="seleccionarUbicacion()"
        class="d-flex justify-content-between align-items-center p-1 flex-wrap cursor-pointer tarjeta-vehiculos"
        [ngClass]="{ invalid: errorSolicitud.ubicacion }"
      >
        <div class="ms-lg-3 ms-0">
          <ng-container *ngIf="textoUbicacion; else noDireccion">
            <span
              ><strong>{{ textoUbicacion }}</strong></span
            >
          </ng-container>
          <ng-template #noDireccion>
            <span><strong>Dirección</strong></span>
          </ng-template>
        </div>
        <div class="me-lg-3 me-0">
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="col-lg-8 mb-3">
      <h1>¿Necesitas otro servicio? <small>(Opcional)</small></h1>
      <textarea
        cols="30"
        rows="5"
        class="form-control"
        placeholder="Escribe que otro servicio necesitas o añade comentarios"
        name="comentarios"
        [(ngModel)]="comentario"
      ></textarea>
    </div>
    <div class="col-lg-8 mb-3">
      <h1>¿Para cuando necesitas el servicio? <small>(Opcional)</small></h1>
      <div class="row align-items-center">
        <div class="col-6">
          <input
            type="date"
            class="p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3"
            [ngClass]="{ 'has-value': fecha }"
            name="fecha"
            [(ngModel)]="fecha"
            placeholder="dd/mm/aaaa"
            [min]="minimaFecha"
          />
        </div>
        <div class="col-6">
          <ng-container *ngIf="fecha">
            <input
              type="time"
              class="p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3"
              [ngClass]="{ 'has-value': hora }"
              name="hora"
              placeholder="hh:mm"
              [(ngModel)]="hora"
            />
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-lg-8 mb-3" (click)="seleccionarMetodoPago()">
      <div class="p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3">
        <strong>{{ textPaymentMethod }}</strong>
      </div>
    </div>
    <div
      class="col-lg-8 mb-3"
      *ngIf="saldoDisponible ? saldoDisponible.disponible : 0 > 0"
    >
      <h1>Bono fidelización</h1>
      <mat-radio-group [(ngModel)]="saldoBool">
        <div class="d-flex px-3 pb-3 pt-2 gap-3">
          <mat-radio-button [value]="true"
            >Aplicar bono de
            {{ saldoDisponible.disponible | currency }}</mat-radio-button
          >
          <mat-radio-button [value]="false">Seguir acumulando</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
    <div class="col-lg-8 my-3">
      <button
        class="btn btn-naranja p-0 px-lg-5 px-3 rounded w-100 d-lg-block d-none"
        style="box-shadow: none"
        [disabled]="inactivarBotonSolicitar"
        (click)="solicitar()"
      >
        <div class="d-flex justify-content-center align-items-center gap-3">
          <div *ngIf="!inactivarBotonSolicitar">
            <img
              src="./assets/images/solicitar_servicio/puntos_cercanos.webp"
              alt="puntos_cercanos"
              class="img-fluid icon-solicitar"
            />
          </div>
          <div>
            <ng-container
              *ngIf="!inactivarBotonSolicitar && datosTaller; else loading"
            >
              <span
                >Enviar solicitud a
                {{ datosTaller.name + " " + datosTaller.lastName }}</span
              >
            </ng-container>
            <ng-template #loading>
              <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </ng-template>
          </div>
        </div>
      </button>
      <button
        class="btn btn-naranja p-0 px-lg-5 px-3 rounded w-100 d-lg-none d-block"
        style="box-shadow: none"
        (click)="solicitar()"
        [disabled]="inactivarBotonSolicitar"
      >
        <div
          class="d-flex justify-content-center align-items-center gap-3 lh-1"
          style="font-size: small"
        >
          <div *ngIf="!inactivarBotonSolicitar">
            <img
              src="./assets/images/solicitar_servicio/puntos_cercanos.webp"
              alt="puntos_cercanos"
              class="img-fluid icon-solicitar"
            />
          </div>
          <div>
            <ng-container
              *ngIf="!inactivarBotonSolicitar && datosTaller; else loading"
            >
              <span>Enviar solicitud a {{ datosTaller.name }}</span>
            </ng-container>
            <ng-template #loading>
              <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </ng-template>
          </div>
        </div>
      </button>
    </div>
  </div>
</div>

export class ETypeService {
  static typeServiceArr = [
    {
      service: new ETypeService(
        1,
        './assets/iconos_de_servicios/aire_acondicionado.webp'
      ),
    },
    {
      service: new ETypeService(
        2,
        './assets/iconos_de_servicios/alineacion_y_balanceo.webp'
      ),
    },
    {
      service: new ETypeService(
        3,
        './assets/iconos_de_servicios/amortiguadores.webp'
      ),
    },
    {
      service: new ETypeService(
        4,
        './assets/iconos_de_servicios/cambio_de_aceite.webp'
      ),
    },
    {
      service: new ETypeService(
        5,
        './assets/iconos_de_servicios/cambio_de_bateria.webp'
      ),
    },
    {
      service: new ETypeService(
        6,
        './assets/iconos_de_servicios/cambio_de_llantas.webp'
      ),
    },
    {
      service: new ETypeService(
        7,
        './assets/iconos_de_servicios/cambio_de_plumillas.webp'
      ),
    },
    {
      service: new ETypeService(
        8,
        './assets/iconos_de_servicios/correa_de_reparticion.webp'
      ),
    },
    {
      service: new ETypeService(
        9,
        './assets/iconos_de_servicios/Diagnostico_automotriz.webp'
      ),
    },
    {
      service: new ETypeService(
        10,
        './assets/iconos_de_servicios/discos_de_frenos.webp'
      ),
    },
    {
      service: new ETypeService(
        11,
        './assets/iconos_de_servicios/kit_de_embrague.webp'
      ),
    },
    {
      service: new ETypeService(
        12,
        './assets/iconos_de_servicios/latoneria_y_pintura.webp'
      ),
    },
    {
      service: new ETypeService(
        13,
        './assets/iconos_de_servicios/autolavado.webp'
      ),
    },
    {
      service: new ETypeService(
        14,
        './assets/iconos_de_servicios/liquido_de_frenos.webp'
      ),
    },
    {
      service: new ETypeService(
        15,
        './assets/iconos_de_servicios/liquido_refrigerante.webp'
      ),
    },
    {
      service: new ETypeService(
        16,
        './assets/iconos_de_servicios/mecanica_general.webp'
      ),
    },
    {
      service: new ETypeService(
        17,
        './assets/iconos_de_servicios/pastillas_de_frenos.webp'
      ),
    },
    {
      service: new ETypeService(
        18,
        './assets/iconos_de_servicios/peritajes.webp'
      ),
    },
    {
      service: new ETypeService(
        19,
        './assets/iconos_de_servicios/reparacion_de_motor.webp'
      ),
    },
    {
      service: new ETypeService(
        20,
        './assets/iconos_de_servicios/revision_de_bateria.webp'
      ),
    },
    {
      service: new ETypeService(
        21,
        './assets/iconos_de_servicios/revision_de_frenos.webp'
      ),
    },
    {
      service: new ETypeService(
        22,
        './assets/iconos_de_servicios/revision_de_suspension.webp'
      ),
    },
    {
      service: new ETypeService(
        23,
        './assets/iconos_de_servicios/revision_general.webp'
      ),
    },
    {
      service: new ETypeService(
        24,
        './assets/iconos_de_servicios/revision_por_kilometraje.webp'
      ),
    },
    {
      service: new ETypeService(
        25,
        './assets/iconos_de_servicios/revision_sistema_electrico.webp'
      ),
    },
    {
      service: new ETypeService(
        26,
        './assets/iconos_de_servicios/revision_tecnomecanica.webp'
      ),
    },
    {
      service: new ETypeService(
        27,
        './assets/iconos_de_servicios/servicio_de_scanner.webp'
      ),
    },
    {
      service: new ETypeService(
        28,
        './assets/iconos_de_servicios/sincronizacion.webp'
      ),
    },
    {
      service: new ETypeService(
        29,
        './assets/iconos_de_servicios/venta_de_accesorios.webp'
      ),
    },
    {
      service: new ETypeService(
        30,
        './assets/iconos_de_servicios/venta_de_repuestos.webp'
      ),
    },
    {
      service: new ETypeService(31, './assets/iconos_de_servicios/otros.webp'),
    },
    {
      service: new ETypeService(
        32,
        './assets/iconos_de_servicios/venta_de_aceite.webp'
      ),
    },
    {
      service: new ETypeService(
        33,
        './assets/iconos_de_servicios/venta_de_llantas.webp'
      ),
    },
    {
      service: new ETypeService(
        34,
        './assets/iconos_de_servicios/venta_de_rines.webp'
      ),
    },
    {
      service: new ETypeService(
        35,
        './assets/iconos_de_servicios/motolavado.webp'
      ),
    },
    {
      service: new ETypeService(
        61,
        './assets/iconos_servicios_categorias/polarizados.webp'
      ),
    },
    {
      service: new ETypeService(36, './assets/iconos_de_servicios/otros.webp'),
    },
  ];

  constructor(public id: number, public image: string) {}

  static getImagenCategoria(idImage: number) {
    for (const key of ETypeService.typeServiceArr) {
      if (key.service.id == idImage) {
        return key.service.image;
      }
    }
    return '';
  }
}

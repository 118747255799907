import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductosRoutingModule } from './productos-routing.module';
import { TalleresComponent } from './talleres/talleres.component';
import { DialogModule } from '../dialog/dialog.module';
import { SharedModule } from 'src/app/core/shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { HomeModule } from '../home/home.module';

@NgModule({
  declarations: [TalleresComponent],
  imports: [
    CommonModule,
    ProductosRoutingModule,
    DialogModule,
    SharedModule,
    SwiperModule,
    HomeModule,
  ],
})
export class ProductosModule {}

<div class="container-fluid">
  <div class="row" *ngIf="fullpits">
    <div class="col-lg-12">
      <h1 class="text-center">
        <strong>{{ fullpits.workshop?.name }}</strong>
      </h1>
    </div>
    <div class="col-lg-12 my-2 text-center">
      <ng-container *ngIf="cantidadEstrellas >= 1; else vacio1">
        <img
          (click)="cantidadEstrellas = 1"
          src="./assets/images/home/estrella-completa.webp"
          alt="estrella-completa"
          style="width: 35px"
        />
      </ng-container>
      <ng-template #vacio1>
        <img
          (click)="cantidadEstrellas = 1"
          src="./assets/images/home/estrella-vacia.webp"
          alt="estrella-vacia"
          style="width: 35px"
        />
      </ng-template>
      <ng-container *ngIf="cantidadEstrellas >= 2; else vacio2">
        <img
          (click)="cantidadEstrellas = 2"
          src="./assets/images/home/estrella-completa.webp"
          alt="estrella-completa"
          style="width: 35px"
        />
      </ng-container>
      <ng-template #vacio2>
        <img
          (click)="cantidadEstrellas = 2"
          src="./assets/images/home/estrella-vacia.webp"
          alt="estrella-vacia"
          style="width: 35px"
        />
      </ng-template>
      <ng-container *ngIf="cantidadEstrellas >= 3; else vacio3">
        <img
          (click)="cantidadEstrellas = 3"
          src="./assets/images/home/estrella-completa.webp"
          alt="estrella-completa"
          style="width: 35px"
        />
      </ng-container>
      <ng-template #vacio3>
        <img
          (click)="cantidadEstrellas = 3"
          src="./assets/images/home/estrella-vacia.webp"
          alt="estrella-vacia"
          style="width: 35px"
        />
      </ng-template>
      <ng-container *ngIf="cantidadEstrellas >= 4; else vacio4">
        <img
          (click)="cantidadEstrellas = 4"
          src="./assets/images/home/estrella-completa.webp"
          alt="estrella-completa"
          style="width: 35px"
        />
      </ng-container>
      <ng-template #vacio4>
        <img
          (click)="cantidadEstrellas = 4"
          src="./assets/images/home/estrella-vacia.webp"
          alt="estrella-vacia"
          style="width: 35px"
        />
      </ng-template>
      <ng-container *ngIf="cantidadEstrellas >= 5; else vacio5">
        <img
          (click)="cantidadEstrellas = 5"
          src="./assets/images/home/estrella-completa.webp"
          alt="estrella-completa"
          style="width: 35px"
        />
      </ng-container>
      <ng-template #vacio5>
        <img
          (click)="cantidadEstrellas = 5"
          src="./assets/images/home/estrella-vacia.webp"
          alt="estrella-vacia"
          style="width: 35px"
        />
      </ng-template>
    </div>
    <div class="col-lg-12">
      <form [formGroup]="formulario" (ngSubmit)="calificarServicio()">
        <div class="form-group">
          <textarea
            cols="30"
            rows="5"
            placeholder="Escribe aquí un comentario"
            class="form-control"
            formControlName="observacion"
          ></textarea>
        </div>
        <div class="form-group my-2">
          <button
            class="btn btn-negro w-100"
            type="button"
            (click)="cerrarDialog()"
          >
            Cancelar
          </button>
        </div>
        <div class="form-group">
          <button class="btn btn-naranja w-100">Calificar</button>
        </div>
      </form>
    </div>
  </div>
</div>

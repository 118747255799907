import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Person, PersonaService } from 'fullpits-commons';
import { VariableGlobalService } from '../../services/variable-global.service';
import { JwtUtils } from '../../utils/jwt-utils';
import { UserValidate } from '../../../../models/userValidate';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-offcanvas',
  templateUrl: './offcanvas.component.html',
  styleUrls: ['./offcanvas.component.scss'],
})
export class OffcanvasComponent implements OnInit, OnDestroy {
  @Input() usuario: Person;
  token: string;
  urlLogin: string = environment.urlLogin;
  constructor(
    private variableGlobal: VariableGlobalService,
    private persona: PersonaService,
    private variableService: VariableGlobalService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      $('body').removeAttr('style');
    }
  }

  ngOnInit(): void {}
  cerrarSesion() {
    this.variableService.actualToken.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem(JwtUtils.WEB_TOKEN)) {
          let tokenValidado = JwtUtils.validarToken();
          if (!tokenValidado.message) {
            let token = localStorage.getItem(JwtUtils.WEB_TOKEN);
            localStorage.removeItem('user');
            localStorage.removeItem(JwtUtils.WEB_TOKEN);
            if (isPlatformBrowser(this.platformId)) {
              window.location.href = `${environment.urlLogin}/user/${token}`;
            }
          }
        }
      }
    });
  }
}

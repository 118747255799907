import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Workshop } from 'fullpits-commons';
import { UbicacionActualComponent } from 'src/app/modulos/dialog/ubicacion-actual/ubicacion-actual.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tarjetas-listados',
  templateUrl: './tarjetas-listados.component.html',
  styleUrls: ['./tarjetas-listados.component.scss'],
})
export class TarjetasListadosComponent implements OnInit {
  @Input() talleres: Workshop[] = [];
  @Input() search: string = '';
  @Input() slugTalleres: string[] = [];
  @Output() solicitudTaller = new EventEmitter<Workshop>();

  distancia: number = environment.distancia;
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  traerOpcionSolicitud(taller: Workshop) {
    this.solicitudTaller.emit(taller);
  }

  abrirDialog() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      maxWidth: '400px',
      maxHeight: '400px',
    });
  }
}

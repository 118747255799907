import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-indicador',
  templateUrl: './dialog-indicador.component.html',
  styleUrls: ['./dialog-indicador.component.scss'],
})
export class DialogIndicadorComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogIndicadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  latitud: number = 0;
  longitud: number = 0;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      let ubicacionActual = JSON.parse(
        `${sessionStorage.getItem('ubicacion')}`
      );
      this.latitud = ubicacionActual.latitud;
      this.longitud = ubicacionActual.longitud;
    }
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}

<div *ngIf="servicioFullpit?.stateId">
  <button
    mat-icon-button
    aria-label="Example icon button with a vertical three dot icon"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button
      *ngIf="
        servicioFullpit?.stateId === 1 ||
        servicioFullpit?.stateId === 2 ||
        servicioFullpit?.stateId === 3 ||
        servicioFullpit?.stateId === 7
      "
      (click)="goToWhatsapp()"
      mat-menu-item
    >
      Abrir whatsapp
    </button>
    <button
      *ngIf="
        servicioFullpit?.stateId === 1 ||
        servicioFullpit?.stateId === 2 ||
        servicioFullpit?.stateId === 3 ||
        servicioFullpit?.stateId === 7
      "
      (click)="crearLinkWhatsappParaTaller()"
      mat-menu-item
    >
      Enviar información - servicio en taller
    </button>
    <button
      *ngIf="
        servicioFullpit?.stateId === 1 ||
        servicioFullpit?.stateId === 2 ||
        servicioFullpit?.stateId === 3 ||
        servicioFullpit?.stateId === 7
      "
      (click)="crearLinkWhatsappDomicilio(servicioFullpit.workshop?.name!)"
      mat-menu-item
    >
      Enviar información - servicio a domicilio
    </button>
    <button
      *ngIf="servicioFullpit?.stateId === 4"
      (click)="crearLinkWhatsappTerminado()"
      mat-menu-item
    >
      Enviar información - servicio Terminado
    </button>
  </mat-menu>
</div>

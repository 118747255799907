<div *ngIf="!imagenWebcam" class="row">
  <div class="col-12">
    <webcam
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      (initError)="handleInitError($event)"
    ></webcam>
  </div>
  <div class="col-lg-12 my-2 text-center">
    <button type="button" class="btn btn-naranja" (click)="triggerCaptura()">
      Tomar foto
    </button>
  </div>
</div>
<div *ngIf="imagenWebcam" class="row">
  <div class="col-12">
    <div>
      <img
        class="m-auto d-block"
        [src]="imagenWebcam.imageAsDataUrl"
        alt="captura_camara"
        width="350"
        height="auto"
      />
    </div>
  </div>
  <div class="col-lg-12 my-2">
    <div class="d-flex justify-content-center align-items-center gap-13">
      <div>
        <button type="button" class="btn btn-naranja" (click)="guardarFoto()">
          Enviar foto
        </button>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-negro"
          (click)="restablecerImagenWebcam()"
        >
          Tomar otra foto
        </button>
      </div>
    </div>
  </div>
</div>

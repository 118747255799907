import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PersonaService, Vehiculo, VehiculoService } from 'fullpits-commons';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { UserValidate } from '../../../../models/userValidate';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-seleccionar-vehiculo',
  templateUrl: './seleccionar-vehiculo.component.html',
  styleUrls: ['./seleccionar-vehiculo.component.scss'],
})
export class SeleccionarVehiculoComponent implements OnInit {
  constructor(
    private vehiculoService: VehiculoService,
    private variableGlobal: VariableGlobalService,
    private persona: PersonaService,
    private dialogRef: MatDialogRef<SeleccionarVehiculoComponent>,
    private router: Router,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  vehiculos: Vehiculo[] = [];

  ngOnInit(): void {
    this.crearTabla();
  }

  agregarVehiculo() {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('solicitar-general', 'true');
      this.router.navigateByUrl('/mis-vehiculos/lista-vehiculos');
    }
  }

  crearTabla() {
    this.variableGlobal.actualUser.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem('user') || sessionStorage.getItem('user')) {
          let user: UserValidate;
          if (sessionStorage.getItem('user')) {
            user = JSON.parse(<string>sessionStorage.getItem('user'));
          } else {
            user = JSON.parse(`${localStorage.getItem('user')}`);
          }
          this.persona.getByUserId(user.user.id).subscribe(
            (resp) => {
              this.vehiculoService
                .getByPersonId(resp[0].id)
                .subscribe((vehiculos: Vehiculo[]) => {
                  this.vehiculos = vehiculos;
                });
            },
            (error) => {
              console.error(error);
              this.toastr.error('Error', 'Ha ocurrido un error');
            }
          );
        }
      }
    });
  }
  datosSeleccionados(vehiculo: Vehiculo) {
    this.data = vehiculo;
    this.dialogRef.close(this.data);
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { Observable } from 'rxjs';
import {} from 'src/app/models/country';
import {} from 'fullpits-commons';
import { VariableGlobalService } from '../../services/variable-global.service';
import {
  Country,
  CountryService,
  Person,
  PersonaService,
} from 'fullpits-commons';
import { map, startWith } from 'rxjs/operators';
import { RestCountriesService } from '../../services/rest-countries.service';

export interface indicativeCountry {
  name: string;
  flag: string;
  code: string;
}

@Component({
  selector: 'app-interna-mi-perfil',
  templateUrl: './interna-mi-perfil.component.html',
  styleUrls: ['./interna-mi-perfil.component.scss'],
})
export class InternaMiPerfilComponent implements OnInit {
  @Input() perfil: Observable<Person>;
  formulario: UntypedFormGroup;
  countries: Observable<Country[]>;
  indicativeCountries: indicativeCountry[] = [];
  filterOptions?: Observable<indicativeCountry[]>;
  codeSelected: string = '57';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private countryService: CountryService,
    private personaService: PersonaService,
    private variableGlobalService: VariableGlobalService,
    private restCountriesService: RestCountriesService
  ) {}

  ngOnInit(): void {
    this.getCountries();
    this.traerPaís();
    this.crearFormulario();
  }

  filterOptionsAutoComplete() {
    this.filterOptions = this.formulario
      .get('countryCallCode')
      ?.valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value))
      );
  }

  _filter(value: string) {
    return this.indicativeCountries.filter((option) => {
      return option.code.includes(value);
    });
  }

  saveCode(code: string) {
    this.codeSelected = code.split('+')[1];
  }

  getCountries() {
    this.restCountriesService
      .getAllCountries()
      .pipe(
        map((response) => {
          const countries = response.filter((country) => {
            return country.idd.root !== undefined;
          });
          return countries.map((country) =>
            country.idd.suffixes.map((suffix) => ({
              name: country.name.nativeName.spa
                ? country.name.nativeName.spa?.official ??
                  country.name.nativeName.spa?.common
                : country.name?.common ?? country.name?.official,
              flag: country.flag,
              code: `${country.idd.root}${suffix}`,
            }))
          );
        })
      )
      .subscribe((countries) => {
        countries.map((country) => {
          for (const iterator of country) {
            this.indicativeCountries.push(iterator);
            this.filterOptionsAutoComplete();
          }
        });
        this.traerPerfil();
      });
  }

  traerPerfil() {
    this.perfil.subscribe((resp) => {
      this.formulario.reset({
        id: resp.id,
        nombres: resp.nombres,
        apellidos: resp.apellidos,
        identificacion: resp.identificacion,
        email: resp.email,
        countryCallCode: this.indicativeCountries.find((country) => {
          this.saveCode(
            country.code.includes(`+${resp.countryCallCode!}`)
              ? country.code
              : ''
          );
          return country.code.includes(`+${resp.countryCallCode!}`);
        })?.flag,
        phoneNumber: resp.phoneNumber,
        fechaNacimiento: resp.fechaNacimiento
          ? moment(resp.fechaNacimiento).add(1, 'd').format('yyyy-MM-DD')
          : '',
        genero: resp.genero,
      });
    });
  }

  crearFormulario() {
    this.formulario = this.formBuilder.group({
      id: ['', Validators.required],
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      identificacion: [''],
      email: ['', [Validators.required, Validators.email]],
      country: [''],
      countryCallCode: [''],
      phoneNumber: [''],
      fechaNacimiento: [''],
      genero: [''],
    });
  }

  guardarPerfil() {
    if (this.formulario.invalid) {
      alert('Los campos con * no deben estar vacios');
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
    this.personaService
      .save({
        ...this.formulario.value,
        countryCallCode: this.codeSelected,
      })
      .subscribe(() => {
        alert('Cambios guardados correctamente');
        this.traerPerfil();
        this.variableGlobalService.consultarUsuario();
      });
  }

  cambioPais(e: any) {
    this.formulario.get('country')?.setValue(e.target.value);
  }

  cambiarGenero(tipo: number) {
    this.formulario.get('genero')?.setValue(tipo);
  }

  traerPaís() {
    this.countries = this.countryService.getAll();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fullpit, PersonWorkshopQualification } from 'fullpits-commons';
import { PersonWorkshopQualificationService } from 'fullpits-commons';
import { SnackbarService } from 'src/app/core/shared/services/snackbar.service';
import { CalificarServicio } from 'src/app/models/calificar-servicio';

@Component({
  selector: 'app-dialog-calificacion-servicio',
  templateUrl: './dialog-calificacion-servicio.component.html',
  styleUrls: ['./dialog-calificacion-servicio.component.scss'],
})
export class DialogCalificacionServicioComponent implements OnInit {
  fullpits: Fullpit;
  calificarPersona: PersonWorkshopQualification;
  formulario: UntypedFormGroup;
  cantidadEstrellas: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: CalificarServicio,
    public dialogRef: MatDialogRef<DialogCalificacionServicioComponent>,
    private personQualification: PersonWorkshopQualificationService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.fullpits = this.data.fullpits;
    this.crearFormulario();
  }

  crearFormulario() {
    this.formulario = this.fb.group({
      calificacion: ['', Validators.required],
      observacion: [''],
    });
  }

  calificarServicio() {
    this.formulario.get('calificacion')?.setValue(this.cantidadEstrellas);
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAllAsTouched;
      });
    }
    this.calificarPersona = {
      qualification: this.formulario.get('calificacion')?.value,
      observation: this.formulario.get('observacion')?.value,
      dateQualification: new Date(),
      fullpit: this.fullpits,
      qualificationState: {
        id: 1,
      },
    };
    this.personQualification.save(this.calificarPersona).subscribe(() => {
      this.snackbarService.mostrarSnackBar('Acabas de calificar');
      this.dialogRef.close(this.cantidadEstrellas);
    });
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MarcaVehiculoService } from 'fullpits-commons';
import { Marca } from '../../../../models/vehiculo';
declare var $: any;

@Component({
  selector: 'app-marca-vehiculo',
  templateUrl: './marca-vehiculo.component.html',
  styleUrls: ['./marca-vehiculo.component.scss'],
})
export class MarcaVehiculoComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MarcaVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private marcaVehiculos: MarcaVehiculoService
  ) {}
  marcas: Marca[] = [];

  ngOnInit(): void {
    this.traerMarcasPorVehiculo();
    this.scrollInicio();
  }

  traerMarcasPorVehiculo() {
    this.marcaVehiculos
      .getByTypeVehicleId(this.data.typeVehicleId)
      .subscribe((marcas: Marca[]) => {
        this.marcas = marcas;
      });
  }
  datosSeleccionados(marca: Marca) {
    this.data = marca;
    this.dialogRef.close(this.data);
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
  scrollInicio() {
    $('body', 'html').animate({
      scrollTop: 0,
    });
  }
}

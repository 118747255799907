import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Linea } from 'src/app/models/vehiculo';
import { LineaVehiculoService } from 'fullpits-commons';
declare var $: any;

@Component({
  selector: 'app-linea-vehiculo',
  templateUrl: './linea-vehiculo.component.html',
  styleUrls: ['./linea-vehiculo.component.scss'],
})
export class LineaVehiculoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<LineaVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lineaVehiculoService: LineaVehiculoService
  ) {}
  lineas: Linea[] = [];

  ngOnInit(): void {
    this.traerLineasVehiculos();
  }
  traerLineasVehiculos() {
    this.lineaVehiculoService
      .getByMarcaId(this.data.idmarcaVehiculo)
      .subscribe((lineas: Linea[]) => {
        this.lineas = lineas;
      });
  }
  datosSeleccionados(linea: Linea) {
    this.data = linea;
    this.dialogRef.close(this.data);
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
  scrollInicio() {
    $('body', 'html').animate({
      scrollTop: 0,
    });
  }
}

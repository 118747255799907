<!-- BEGIN FOOTER SECTION -->
<footer *ngIf="!isChat">
  <div class="bg p-4">
    <div class="row align-items-center d-none d-lg-flex">
      <ng-container *ngFor="let menu of menuJson; let i = index">
        <div class="col-lg-4 text-white h5">
          <ul class="list-style-none">
            <ng-container *ngFor="let lista of menu.Lista">
              <li>
                <ng-container *ngIf="lista.href; else rutaAngular">
                  <a
                    [href]="lista.ruta"
                    target="_blank"
                    [ngClass]="{ 'd-none': lista.hidden }"
                    class="nav-link p-0 text-white"
                    >{{ lista.texto }}</a
                  >
                </ng-container>
                <ng-template #rutaAngular>
                  <a
                    [routerLink]="[lista.ruta]"
                    class="nav-link p-0 text-white"
                    [ngClass]="{ 'd-none': lista.hidden }"
                    >{{ lista.texto }}</a
                  >
                </ng-template>
              </li>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </div>
    <div
      class="justify-content-between align-items-center px-3 d-none d-lg-flex"
      style="padding-right: 4rem !important"
    >
      <div>
        <p class="h5 mb-0 text-muted">&copy; Fullpits. All rights reserved.</p>
      </div>
      <div class="d-flex align-items-center" style="gap: 10px">
        <div>
          <p class="h4 mb-0 text-white">Síguenos en:</p>
        </div>
        <div>
          <a href="https://www.facebook.com/fullpits" target="_blank">
            <img
              src="./assets/images/footer/facebook-fullpits.webp"
              class="img-fluid"
              width="35px"
              alt="facebook-fullpits"
            />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/fullpits" target="_blank">
            <img
              src="./assets/images/footer/twitter-fullpits.webp"
              class="img-fluid"
              width="35px"
              alt="twitter-fullpits"
            />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/company/fullpits" target="_blank">
            <img
              src="./assets/images/footer/linkedin-fullpits.webp"
              class="img-fluid"
              width="35px"
              alt="linkedin-fullpits"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.youtube.com/channel/UC4Z5zOAbZ8xpPJYdJKVMI1g/featured"
            target="_blank"
          >
            <img
              src="./assets/images/footer/youtube-fullpits.webp"
              class="img-fluid"
              width="35px"
              alt="linkedin-fullpits"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      class="d-flex d-lg-none flex-wrap flex-column justify-content-center align-items-center"
      style="gap: 13px"
    >
      <div
        class="d-flex flex-wrap justify-content-center align-items-center"
        style="gap: 10px"
      >
        <div>
          <p class="mb-0 h5 text-white">Síguenos en:</p>
        </div>
        <div>
          <a href="https://www.facebook.com/fullpits" target="_blank">
            <img
              src="./assets/images/footer/facebook-fullpits.webp"
              class="img-fluid"
              width="30px"
              alt="facebook-fullpits"
            />
          </a>
        </div>
        <div>
          <a href="https://twitter.com/fullpits" target="_blank">
            <img
              src="./assets/images/footer/twitter-fullpits.webp"
              class="img-fluid"
              width="30px"
              alt="twitter-fullpits"
            />
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/company/fullpits" target="_blank">
            <img
              src="./assets/images/footer/linkedin-fullpits.webp"
              class="img-fluid"
              width="30px"
              alt="linkedin-fullpits"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.youtube.com/channel/UC4Z5zOAbZ8xpPJYdJKVMI1g/featured"
            target="_blank"
          >
            <img
              src="./assets/images/footer/youtube-fullpits.webp"
              class="img-fluid"
              width="30px"
              alt="linkedin-fullpits"
            />
          </a>
        </div>
      </div>
      <div>
        <p class="h5 mb-0 text-muted">&copy; Fullpits. All rights reserved.</p>
      </div>
    </div>
  </div>
</footer>
<!-- END FOOTER SECTION -->

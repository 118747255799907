import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import {
  Fullpit,
  FullpitProblem,
  FullpitService,
  Workshop,
} from 'fullpits-commons';
import { SnackbarService } from 'src/app/core/shared/services/snackbar.service';
import { CalificarServicio } from 'src/app/models/calificar-servicio';

@Component({
  selector: 'app-dialog-reportar-problema',
  templateUrl: './dialog-reportar-problema.component.html',
  styleUrls: ['./dialog-reportar-problema.component.scss'],
})
export class DialogReportarProblemaComponent implements OnInit, OnDestroy {
  fullpitReport: FullpitProblem;
  servicioFullpits: Fullpit;
  fullpits$ = new Subject<void>();
  formulario: UntypedFormGroup;

  constructor(
    private fullpitsService: FullpitService,
    private dialogRef: MatDialogRef<DialogReportarProblemaComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CalificarServicio,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService
  ) {}
  ngOnDestroy(): void {
    this.fullpits$.next();
    this.fullpits$.complete();
  }

  ngOnInit(): void {
    this.data.fullpits;
    this.servicioFullpits = this.data.fullpits;
    this.crearFormulario();
  }

  crearFormulario() {
    this.formulario = this.fb.group({
      observacion: ['', Validators.required],
    });
  }

  reportarProblema() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach((control) => {
        control.markAllAsTouched();
      });
    }
    this.fullpitReport = {
      observation: this.formulario.get('observacion')?.value,
      workshop: <Workshop>this.servicioFullpits.workshop,
      workShopPersonService: this.servicioFullpits,
    };
    this.fullpitsService
      .reportWorkshopPersonProblem(this.fullpitReport)
      .subscribe(() => {
        this.snackbarService.mostrarSnackBar('Se ha enviado el reporte');
        this.dialogRef.close();
      });
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}

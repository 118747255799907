import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UbicacionActualComponent } from 'src/app/modulos/dialog/ubicacion-actual/ubicacion-actual.component';
import { environment } from 'src/environments/environment';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { Workshop } from 'fullpits-commons';

declare var $: any;
SwiperCore.use([Navigation]);

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() listTalleres: Workshop[] = [];
  @Input() imagenDefault: string;
  @Input() idBussiness: number = 0;
  @Input() slugTalleres: string[] = [];
  distancia: number = environment.distancia;
  config: SwiperOptions = {
    breakpoints: {
      0: {
        loop: false,
        slidesPerView: 2.6,
        spaceBetween: 20,
      },
      450: {
        loop: false,
        slidesPerView: 3.6,
        spaceBetween: 20,
      },
      768: {
        loop: false,
        slidesPerView: 4.6,
        spaceBetween: 20,
      },
      1200: {
        loop: false,
        speed: 10000,
        slidesPerView: 6.6,
        spaceBetween: 20,
      },
      1900: {
        loop: false,
        slidesPerView: 7.6,
        spaceBetween: 20,
      },
    },
  };
  constructor(
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  slideNext() {
    if (isPlatformBrowser(this.platformId)) {
      this.swiper?.swiperRef.slideNext(1500);
      if (!this.swiper?.swiperRef.slideNext(1500)) {
        $(`.slidePrev${this.idBussiness}`).removeClass('disabled');
        $(`.slideNext${this.idBussiness}`).addClass('disabled');
      } else {
        $(`.slidePrev${this.idBussiness}`).removeClass('disabled');
        $(`.slideNext${this.idBussiness}`).removeClass('disabled');
      }
    }
  }
  slidePrev() {
    if (isPlatformBrowser(this.platformId)) {
      this.swiper?.swiperRef.slidePrev(1500);
      if (!this.swiper?.swiperRef.slidePrev(1500)) {
        $(`.slideNext${this.idBussiness}`).removeClass('disabled');
        $(`.slidePrev${this.idBussiness}`).addClass('disabled');
      } else {
        $(`.slideNext${this.idBussiness}`).removeClass('disabled');
        $(`.slidePrev${this.idBussiness}`).removeClass('disabled');
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.removeItem('imgDefault');
    }
  }

  guardarImagenDefecto(imagen: string) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('imgDefault', imagen);
    }
  }
  abrirDialog() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      maxWidth: '500px',
      maxHeight: '500px',
    });
  }
}

<ngx-dropzone
  (change)="onSelect($event)"
  accept="image/jpeg,image/jpg,image/png,image/gif"
>
  <ngx-dropzone-label>Seleccionar imagen!</ngx-dropzone-label>
  <ngx-dropzone-preview
    *ngFor="let f of files"
    [removable]="true"
    (removed)="onRemove(f)"
  >
    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
  </ngx-dropzone-preview>
</ngx-dropzone>

<div class="my-4 text-center">
  <button type="button" (click)="enviarFotos()" class="btn btn-naranja">
    Enviar imagenes
  </button>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bono-fidelizacion-tyc',
  templateUrl: './bono-fidelizacion-tyc.component.html',
  styleUrls: ['./bono-fidelizacion-tyc.component.scss'],
})
export class BonoFidelizacionTycComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<BonoFidelizacionTycComponent>) {}

  ngOnInit(): void {}

  cerrarDialog() {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  mostrarSnackBar(mensaje: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      data: mensaje,
    });
  }
}

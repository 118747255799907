<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <h1 class="text-center fw-bold d-lg-block d-none">
        Selecciona por dónde desea realizar la solicitud
      </h1>
      <h4 class="text-center fw-bold d-block d-lg-none">
        Selecciona por dónde desea realizar la solicitud
      </h4>
    </div>
    <a
      class="btn btn-link text-naranja text-black shadow-none"
      (click)="seleccionaOpcion(true)"
    >
      <div
        class="col-lg-12 d-flex flex-row gap-3 flex-wrap align-items-center justify-content-center mb-3"
      >
        <div>
          <img
            src="./assets/icons/logo-fullpits-72x72.webp"
            alt="fullpit"
            class="img-fluid"
            style="width: 72px; height: 72px"
          />
        </div>
        <div class="width-texto text-start">Por Fullpits</div>
      </div>
    </a>
    <ng-container *ngIf="data.servicio; else servicioNoExiste">
      <a
        class="btn btn-link text-naranja text-black shadow-none"
        (click)="seleccionaOpcion(false)"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0ANecesito+cotizar+o+solicitar+{{
          '%2A' + data.servicio + '%2A'
        }}{{ '+en+%2A' + data.nombreTaller + '%2A' }}.&app_absent=0"
      >
        <div
          class="col-lg-12 d-flex flex-row flex-wrap gap-3 align-items-center justify-content-center"
        >
          <div
            style="background-color: #2db742; padding: 9px"
            class="rounded-circle"
          >
            <img
              src="./assets/images/footer/whatsapp-fullpits.webp"
              alt="fullpit"
              class="img-fluid"
              style="width: 40px; height: 40px"
            />
          </div>
          <div class="width-texto text-start">Por WhatsApp</div>
        </div>
      </a>
    </ng-container>
    <ng-template #servicioNoExiste>
      <a
        class="btn btn-link text-naranja text-black shadow-none"
        (click)="seleccionaOpcion(false)"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0ANecesito+cotizar+o+solicitar+un+servicio{{
          data.nombreTaller ? '+en+%2A' + data.nombreTaller + '%2A' : ''
        }}.&app_absent=0"
      >
        <div
          class="col-lg-12 d-flex flex-row flex-wrap gap-3 align-items-center justify-content-center"
        >
          <div
            style="background-color: #2db742; padding: 9px"
            class="rounded-circle"
          >
            <img
              src="./assets/images/footer/whatsapp-fullpits.webp"
              alt="fullpit"
              class="img-fluid"
              style="width: 40px; height: 40px"
            />
          </div>
          <div class="width-texto text-start">Por WhatsApp</div>
        </div>
      </a>
    </ng-template>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Person,
  PersonWorkshopQualification,
  PersonWorkshopQualificationService,
} from 'fullpits-commons';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-dialog-ver-comentarios',
  templateUrl: './dialog-ver-comentarios.component.html',
  styleUrls: ['./dialog-ver-comentarios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es',
    },
  ],
})
export class DialogVerComentariosComponent implements OnInit {
  calificaciones: PersonWorkshopQualification[] = [];
  persona: Person[] = [];
  constructor(
    private calificacionPersona: PersonWorkshopQualificationService,
    private dialogRef: MatDialogRef<DialogVerComentariosComponent>,
    @Inject(MAT_DIALOG_DATA) private data: number,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.calificacionPersona
      .getPaginatedByWorkshopId(this.data, 0, 1000)
      .subscribe((resp) => {
        this.calificaciones = resp;
        for (const calificacion of this.calificaciones) {
          this.persona.push(<Person>calificacion!.fullpit!.person);
        }
        this.cd.markForCheck();
      });
  }

  cerrarDialog() {
    this.dialogRef.close();
  }
}

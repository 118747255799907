import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { City, EFullpitState, FranchiseService } from 'fullpits-commons';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-filter-city-by-franchise',
  templateUrl: './filter-city-by-franchise.component.html',
  styleUrls: ['./filter-city-by-franchise.component.scss'],
})
export class FilterCityByFranchiseComponent implements OnInit {
  @Input() city: City;
  @Output() citySelected = new EventEmitter<City>();
  @Input() stateId: number;
  cities: City[] = [];
  filteredOptions: Observable<City[]>;
  formFilterCity: FormGroup;
  editCity: boolean = false;

  constructor(
    private readonly franchiseService: FranchiseService,
    private readonly formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}
  ngOnInit(): void {
    this.getAllCities();
    this.initForm();
    this.citySelected.emit(this.city);
    this.editCity =
      this.stateId === EFullpitState.CERRADO ||
      this.stateId === EFullpitState.CANCELADO
        ? false
        : true;
  }

  initForm() {
    this.formFilterCity = this.formBuilder.group({
      city: [this.city.name, Validators.required],
    });
  }

  saveCity() {
    if (this.formFilterCity.invalid) return;
    this.toastr.success('Ciudad seleccionada correctamente');
    this.citySelected.emit(this.city);
  }

  selectCity(city: City) {
    this.city = city;
    this.saveCity();
  }

  getAllCities() {
    this.franchiseService
      .getAllFranchiseDistinctCities()
      .subscribe((cities) => {
        this.cities = cities as City[];
        this.getFilteredOption();
      });
  }

  getFilteredOption() {
    this.filteredOptions = this.formFilterCity.get('city')!.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value!))
    );
  }

  private _filter(value: string): City[] {
    const filterValue = value!.toLocaleLowerCase();
    return this.cities.filter(
      (city) => city.name!.toLocaleLowerCase().indexOf(filterValue) === 0
    );
  }
}

<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <ng-container *ngIf="calificaciones.length > 0; else noCalificado">
        <hr />
        <ng-container
          *ngFor="let calificacion of calificaciones; let i = index"
        >
          <div class="row mt-3 align-items-center">
            <div class="col-2 pe-0">
              <img
                src="./assets/images/mis-vehiculos/usuario-fullpits.webp"
                alt="privacidad-fullpits"
                class="img-fluid bg-naranja rounded-circle"
              />
            </div>
            <div class="col-10 ps-2">
              <div>
                <p class="mb-0 fs-6">
                  <strong
                    >{{ persona[i].nombres }} {{ persona[i].apellidos }}</strong
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-10 offset-2 ps-2">
              <div class="d-flex flex-column justify-content-center flex-wrap">
                <div
                  class="d-flex justify-content-between align-items-center pe-3"
                >
                  <div>
                    <ng-container
                      *ngIf="calificacion.qualification! >= 1; else vacio1"
                    >
                      <img
                        src="./assets/images/home/estrella-completa.webp"
                        alt="estrella-completa"
                        style="width: 20px"
                      />
                    </ng-container>
                    <ng-template #vacio1>
                      <img
                        src="./assets/images/home/estrella-vacia.webp"
                        alt="estrella-vacia"
                        style="width: 20px"
                      />
                    </ng-template>
                    <ng-container
                      *ngIf="calificacion.qualification! >= 2; else vacio2"
                    >
                      <img
                        src="./assets/images/home/estrella-completa.webp"
                        alt="estrella-completa"
                        style="width: 20px"
                      />
                    </ng-container>
                    <ng-template #vacio2>
                      <img
                        src="./assets/images/home/estrella-vacia.webp"
                        alt="estrella-vacia"
                        style="width: 20px"
                      />
                    </ng-template>
                    <ng-container
                      *ngIf="calificacion.qualification! >= 3; else vacio3"
                    >
                      <img
                        src="./assets/images/home/estrella-completa.webp"
                        alt="estrella-completa"
                        style="width: 20px"
                      />
                    </ng-container>
                    <ng-template #vacio3>
                      <img
                        src="./assets/images/home/estrella-vacia.webp"
                        alt="estrella-vacia"
                        style="width: 20px"
                      />
                    </ng-template>
                    <ng-container
                      *ngIf="calificacion.qualification! >= 4; else vacio4"
                    >
                      <img
                        src="./assets/images/home/estrella-completa.webp"
                        alt="estrella-completa"
                        style="width: 20px"
                      />
                    </ng-container>
                    <ng-template #vacio4>
                      <img
                        src="./assets/images/home/estrella-vacia.webp"
                        alt="estrella-vacia"
                        style="width: 20px"
                      />
                    </ng-template>
                    <ng-container
                      *ngIf="calificacion!.qualification! >= 5; else vacio5"
                    >
                      <img
                        src="./assets/images/home/estrella-completa.webp"
                        alt="estrella-completa"
                        style="width: 20px"
                      />
                    </ng-container>
                    <ng-template #vacio5>
                      <img
                        src="./assets/images/home/estrella-vacia.webp"
                        alt="estrella-vacia"
                        style="width: 20px"
                      />
                    </ng-template>
                  </div>
                  <div>
                    <small
                      ><p class="m-0">
                        {{
                          calificacion.dateQualification
                            | date: "dd 'de' MMM 'de' yyyy"
                        }}
                      </p></small
                    >
                  </div>
                </div>
                <div>
                  <p
                    class="mb-0 text-muted"
                    [innerText]="calificacion.observation"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noCalificado>
        <h2 class="text-center"><strong>No hay comentarios</strong></h2>
      </ng-template>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ETypeVehicleList,
  WorkshopsService,
  ETallerSinImagen,
  Workshop,
  Horario,
} from 'fullpits-commons';
import dayjs from 'dayjs';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { MatDialog } from '@angular/material/dialog';
import { UbicacionActualComponent } from '../../dialog/ubicacion-actual/ubicacion-actual.component';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  SwiperOptions,
} from 'swiper';
import { DialogIndicadorComponent } from '../../dialog/dialog-indicador/dialog-indicador.component';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/core/shared/services/seo.service';
import { ConfigMeta } from 'src/app/models/config-meta';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { DialogVerComentariosComponent } from '../../dialog/dialog-ver-comentarios/dialog-ver-comentarios.component';
import { DialogOpcionSolicitudComponent } from '../../dialog/dialog-opcion-solicitud/dialog-opcion-solicitud.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UbicacionResponse } from '../../../models/ubicacion.response';
import { ToastrService } from 'ngx-toastr';
import { DistanciaKilometrosService } from '../../../core/shared/services/distancia-kilometros.service';
import { WorkshopTypeServiceLocal } from 'src/app/models/workshopTypeService';
import { ETypeService } from 'src/app/core/shared/enum/e-type-service';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
declare var $: any;

@Component({
  selector: 'app-talleres',
  templateUrl: './talleres.component.html',
  styleUrls: ['./talleres.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TalleresComponent implements OnInit, OnDestroy {
  activatedRoute$ = new Subject<void>();
  image_tipo_taller: string | null;
  idTaller: string | null;
  datosTaller: Workshop;
  diaActual: Horario;
  diaSiguiente: string;
  cerradoTodoDia: boolean = false;
  abiertoTodoDia: boolean = false;
  abierto_cerrado: boolean = false;
  abiertoDiaSiguiente: boolean = false;
  distancia: number = environment.distancia;
  domicilio: boolean;
  servicios: WorkshopTypeServiceLocal[];
  enumImagen: any = ETypeService;
  servicioObj: WorkshopTypeServiceLocal;
  idAdmin: string;
  ubicacionActual: UbicacionResponse;
  dia: string[] = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  config: SwiperOptions = {
    slidesPerView: 1,
    breakpoints: {
      320: {
        navigation: false,
        loop: false,
        slidesPerView: 2.5,
        spaceBetween: 0,
      },
    },
  };
  isToday: boolean[] = [];
  distanciaKilometros: number = 0;

  constructor(
    private workshopsService: WorkshopsService,
    private activatedRouter: ActivatedRoute,
    private variableGlobal: VariableGlobalService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any,
    private seoService: SeoService,
    private title: Title,
    private router: Router,
    private cd: ChangeDetectorRef,
    private toastr: ToastrService,
    private distanciaKilometrosService: DistanciaKilometrosService,
    private sanitizer: DomSanitizer
  ) {
    //this.datosTaller = new Workshop();
  }

  ngOnDestroy(): void {
    this.activatedRoute$.next();
    this.activatedRoute$.complete();
  }

  ngOnInit(): void {
    this.imagenPorDefecto();
    this.traerTaller();
    this.mostrarOcultarHorarios();
    this.mostrarOcultarHorariosMovil();
    this.scrollInicio();
  }

  imagenPorDefecto() {
    this.activatedRouter.params
      .pipe(takeUntil(this.activatedRoute$))
      .subscribe((resp) => {
        this.idTaller = resp.id.split('-')[0];
        if (isPlatformBrowser(this.platformId)) {
          this.idAdmin = <string>sessionStorage.getItem('idAdmin');
          this.image_tipo_taller = sessionStorage.getItem('imgDefault');
          if (!this.image_tipo_taller) {
            if (resp.id.split('-')[1] === '1') {
              // Carros
              this.image_tipo_taller = ETallerSinImagen.getImagenCategoria(
                parseInt(resp.id.split('-')[1])
              );
            }
            if (resp.id.split('-')[1] === '2') {
              // motos
              this.image_tipo_taller = ETallerSinImagen.getImagenCategoria(
                parseInt(resp.id.split('-')[1])
              );
            }
            if (resp.id.split('-')[1] === '3') {
              // Autolavados
              this.image_tipo_taller = ETallerSinImagen.getImagenCategoria(
                parseInt(resp.id.split('-')[1])
              );
            }
            if (resp.id.split('-')[1] === '4') {
              // Motolavados
              this.image_tipo_taller = ETallerSinImagen.getImagenCategoria(
                parseInt(resp.id.split('-')[1])
              );
            }
          }
        }
      });
  }

  traerOpcionSolicitud(nombreTaller: string, servicio?: string) {
    if (this.idAdmin) {
      this.dialog
        .open(DialogOpcionSolicitudComponent, {
          width: '400px',
          height: 'max-content',
          data: {
            nombreTaller: nombreTaller.replace(/&/g, '%26').replace(/ /g, '+'),
            servicio: servicio?.replace(/ /g, '+'),
          },
        })
        .afterClosed()
        .subscribe((resp) => {
          if (resp) {
            this.router.navigateByUrl(
              `/solicitar-servicio/${this.datosTaller.id}`
            );
          }
        });
    } else {
      if (servicio) {
        window.open(
          `https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0ANecesito+cotizar+o+solicitar+%2A${servicio}%2A+en+%2A${nombreTaller
            .replace(/&/g, '%26')
            .replace(/ /g, '+')}%2A.&app_absent=0`,
          '_blank'
        );
      } else {
        window.open(
          `https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0ANecesito+cotizar+o+solicitar+un+servicio+${
            nombreTaller
              ? 'en+%2A' +
                nombreTaller.replace(/&/g, '%26').replace(/ /g, '+') +
                '%2A'
              : ''
          }.&app_absent=0`,
          '_blank'
        );
      }
    }
  }

  getUbicacionActual() {
    this.variableGlobal.currentMessage.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.ubicacionActual = JSON.parse(
          `${sessionStorage.getItem('ubicacion')}`
        );
      }
      this.cambiarDistancia();
      this.cd.markForCheck();
    });
  }

  cambiarDistancia() {
    if (this.ubicacionActual) {
      this.distanciaKilometros = this.distanciaKilometrosService.getKilometros(
        this.datosTaller.latitude!,
        this.datosTaller.longitude!,
        this.ubicacionActual.latitud,
        this.ubicacionActual.longitud
      );
      if (this.distanciaKilometros <= environment.distancia) {
        this.distanciaKilometros > 1
          ? (this.datosTaller.distancia = this.distanciaKilometros + 'Km')
          : (this.datosTaller.distancia =
              this.distanciaKilometros * 1000 + 'm');
        this.datosTaller.esCercano = true;
      } else {
        this.datosTaller.esCercano = false;
      }
    }
  }

  metaEtiquetas() {
    let title: string = `${this.datosTaller.name} ${this.datosTaller.lastName}  | Fullpits`;
    this.title.setTitle(title);
    let metaDatos: ConfigMeta = {
      title: `${this.datosTaller.name} ${this.datosTaller.lastName} | Fullpits`,
      description: `Solicita el servicio que necesitas en ${this.datosTaller.name} ${this.datosTaller.lastName} a través de Fullpits. Serás atendido por personal calificado para garantizar el cuidado de tu vehículo.`,
      image: this.datosTaller.profilePicture!,
      slug: `${this.idTaller}-${this.datosTaller
        .name!.toLowerCase()
        .replace(/ /g, '-')}`,
    };
    this.seoService.generateTags(metaDatos);
    this.seoService.createLinkForCanonicalURL();
  }

  async getTypeServices() {
    await this.workshopsService
      .getworkShopTypeService(parseInt(this.idTaller!), 0, 0)
      .toPromise()
      .then((servicios) => {
        this.servicios = servicios;
        servicios.map((value, index) => {
          this.servicios[index].description =
            this.sanitizer.bypassSecurityTrustHtml(value.description!);
        });
        this.cd.markForCheck();
      })
      .catch((error) => this.toastr.error(error));
    this.getImageTypeServices();
  }

  getImageTypeServices() {
    this.servicios.map((servicio) => {
      servicio.typeVehicleList!.map((vehiculo) => {
        vehiculo!.imagen = ETypeVehicleList.getImageVehicle(vehiculo.id!);
      });
    });
    this.servicios.map((servicio) => {
      if (servicio.homeService === true) {
        this.domicilio = servicio.homeService;
      }
      servicio.typeService!.image = ETypeService.getImagenCategoria(
        servicio.typeService!.id!
      );
    });
  }

  logicaHorario(index: number, hora: number, hora_init: number) {
    this.abierto_cerrado = false;
    if (hora > hora_init) {
      if (this.datosTaller.listHorarios!.length === index + 1) {
        this.cerradoTodoDia = <boolean>this.datosTaller.listHorarios![0].closed;
        this.abiertoTodoDia = <boolean>this.datosTaller.listHorarios![0].allDay;
        this.diaSiguiente = this.datosTaller.listHorarios![0].init!;
        this.abiertoDiaSiguiente = true;
      } else {
        this.cerradoTodoDia = <boolean>(
          this.datosTaller.listHorarios![index + 1].closed
        );
        this.abiertoTodoDia = <boolean>(
          this.datosTaller.listHorarios![index + 1].allDay
        );
        this.diaSiguiente = this.datosTaller.listHorarios![index + 1].init!;
        this.abiertoDiaSiguiente = true;
      }
    } else {
      this.abiertoDiaSiguiente = false;
      this.diaSiguiente = this.diaActual.init!;
    }
  }

  getListSchedule() {
    if (
      this.datosTaller.listHorarios &&
      this.datosTaller.listHorarios.length > 0
    ) {
      this.datosTaller.listHorarios.forEach((horario, index) => {
        if (horario.name === this.dia[dayjs().day()]) {
          this.isToday.push(true);
          this.diaActual = horario;
          if (this.diaActual.allDay) {
            this.abierto_cerrado = true;
          }
          if (this.diaActual.closed) {
            this.abierto_cerrado = false;
          }
          if (
            !this.diaActual.closed &&
            !this.diaActual.allDay &&
            this.diaActual.init &&
            this.diaActual.end
          ) {
            let hora: number = dayjs().hour();
            let minuto: number = dayjs().minute();
            let hora_init: number = parseInt(this.diaActual.init.split(':')[0]);
            let hora_end: number = parseInt(this.diaActual.end.split(':')[0]);
            let minuto_end: number = parseInt(this.diaActual.end.split(':')[1]);
            let minuto_init: number = parseInt(
              this.diaActual.init.split(':')[1]
            );
            if (hora >= hora_init && hora < hora_end) {
              if (minuto >= minuto_init) {
                this.abierto_cerrado = true;
              } else {
                if (hora < hora_init) {
                  if (minuto < minuto_init) {
                    this.logicaHorario(index, hora, hora_init);
                  }
                } else if (hora === hora_init) {
                  if (minuto < minuto_init) {
                    this.logicaHorario(index, hora, hora_init);
                  }
                } else if (hora > hora_init && hora < hora_end) {
                  if (minuto < minuto_init) {
                    this.abierto_cerrado = true;
                  }
                }
              }
            } else if (hora === hora_end) {
              if (minuto >= minuto_end) {
                this.logicaHorario(index, hora, hora_init);
              } else {
                this.abierto_cerrado = true;
              }
            } else if (hora > hora_end) {
              this.logicaHorario(index, hora, hora_init);
            } else if (hora < hora_init) {
              this.logicaHorario(index, hora, hora_init);
            } else if (!this.diaActual.closed && this.diaActual.allDay) {
              this.abierto_cerrado = true;
            } else {
              this.abierto_cerrado = false;
            }
          }
        } else {
          this.isToday.push(false);
        }
      });
    }
  }

  async traerTaller() {
    await this.workshopsService
      .getWorkshopById(parseInt(this.idTaller!))
      .toPromise()
      .then((taller) => {
        if (!taller) {
          this.router.navigateByUrl('/');
          return;
        }
        this.datosTaller = taller;

        this.cd.markForCheck();
      })
      .catch((error) => this.toastr.error(error));
    this.getUbicacionActual();
    this.metaEtiquetas();
    this.getTypeServices();
    this.getListSchedule();
  }

  agendarServicio(servicio: WorkshopTypeServiceLocal) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('servicio', JSON.stringify(servicio));
      this.traerOpcionSolicitud(this.datosTaller.name!, <string>servicio.title);
    }
  }

  mostrarOcultarHorarios() {
    if (isPlatformBrowser(this.platformId)) {
      $('#horario_principal').click(function () {
        $('#lista_horarios').toggleClass('d-none');
      });
    }
  }

  mostrarOcultarHorariosMovil() {
    if (isPlatformBrowser(this.platformId)) {
      $('#horario_principal_movil').click(function () {
        $('#lista_horarios_movil').toggleClass('d-none');
      });
    }
  }

  scrollInicio() {
    if (isPlatformBrowser(this.platformId)) {
      $('html, body').animate({
        scrollTop: 0,
      });
    }
  }

  abrirDialog() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      maxWidth: '400px',
      maxHeight: '400px',
    });
  }

  abrirInidicaciones(lat: number, lng: number) {
    const dialogIndicaciones = this.dialog.open(DialogIndicadorComponent, {
      maxWidth: '400px',
      maxHeight: '400px',
      data: { latitud: lat, longitud: lng, idAdmin: this.idAdmin },
    });
  }

  verComentarios() {
    this.dialog.open(DialogVerComentariosComponent, {
      width: '400px',
      height: '400px',
      data: this.datosTaller.id,
    });
  }
}

export const environment = {
  firebase: {
    projectId: 'inndex-aliados',
    appId: '1:175107949846:web:6526c0ef456f778848fc37',
    databaseURL: 'https://inndex-aliados-default-rtdb.firebaseio.com',
    storageBucket: 'inndex-aliados.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyASY5SKJ8HG4NGozMtJ3k663hm4iFOaIc8',
    authDomain: 'inndex-aliados.firebaseapp.com',
    messagingSenderId: '175107949846',
  },
  production: false,
  despliegue: false,
  apiUrlFullPitsNest: 'https://nest.devinndexco.link/',
  backoffice: 'https://backoffice.devinndexco.link',
  urlLogin: 'https://login.devinndexco.link/',
  distancia: 10,
  cantidadNotificacionMostrar: 20,
  coleccionNotificaciones: 'notificacionesdev',
  documentoNotificaciones: 'notificaciones',
  colleccionPropuestas: 'propuestasdev',
  coleccionFirebaseTalleres: 'talleresdev',
  coleccionChat: 'chatdev',
  coleccionFidelizacion: 'fidelizaciondev',
  documentosFirebaseTalleres: 'nuevasSolicitudes',
  storageRoute: 'imagesDev',
  storageRouteAudio: 'audioDev',
  urlHome: 'https://fullpits.devinndexco.link/',
  phoneNumberFullpits: '3016116589',
  countryCallCode: '57',
};

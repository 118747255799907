<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h1 class="text-center">Terminar servicio</h1>
      <form
        [formGroup]="terminarServicioForm"
        (ngSubmit)="terminarServicio()"
        autocomplete="off"
      >
        <div class="form-group mb-3">
          <input
            type="number"
            formControlName="total"
            class="form-control"
            placeholder="Total"
            [ngClass]="{ 'is-invalid': invalidTotal, 'is-valid': validTotal }"
          />
          <ng-container *ngIf="invalidTotal">
            <small *ngIf="messageTotal?.required"
              ><span class="text-danger"
                >Debes de colocar un precio</span
              ></small
            >
            <small *ngIf="messageTotal?.min"
              ><span class="text-danger"
                >El precio debe ser mayor o igual a
                {{ messageTotal?.min.min }}</span
              ></small
            >
          </ng-container>
        </div>
        <div class="form-group mb-3">
          <select
            formControlName="paymentMethod"
            class="form-select"
            [ngClass]="{
              'is-invalid': invalidPaymentMethod,
              'is-valid': validPaymentMethod
            }"
          >
            <option value="">Seleccione método de pago</option>
            <option hidden [ngValue]="fullpits.paymentMethod">
              {{ fullpits.paymentMethod?.name }}
            </option>
            <ng-container *ngFor="let metodoPago of metodosPago">
              <option [ngValue]="metodoPago">
                {{ metodoPago.name }}
              </option>
            </ng-container>
          </select>
          <!-- <ng-container *ngIf="fullpits.paymentMethod">
            <small
              ><span class="text-info">{{
                fullpits.paymentMethod.name
              }}</span></small
            >
          </ng-container> -->
          <ng-container *ngIf="invalidPaymentMethod">
            <small *ngIf="messagePaymentMethod?.required"
              ><span class="text-danger"
                >Debes de colocar un método de pago</span
              ></small
            >
          </ng-container>
        </div>
        <div class="form-group mb-3">
          <input
            type="text"
            formControlName="additionalServices"
            class="form-control"
            placeholder="Comentarios adicionales"
          />
        </div>
        <div class="form-group mb-3">
          <button
            type="submit"
            class="btn btn-naranja"
            [disabled]="terminarServicioForm.invalid"
          >
            Terminar servicio
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

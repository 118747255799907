import { Component, OnInit, Inject, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Fullpit, FullpitService } from 'fullpits-commons';

@Component({
  selector: 'app-dialog-changed-price',
  templateUrl: './dialog-changed-price.component.html',
  styleUrls: ['./dialog-changed-price.component.scss'],
})
export class DialogChangedPriceComponent implements OnInit, OnDestroy {
  formPrice: FormGroup;
  precioCambio: boolean = false;

  fullpitsService = inject(FullpitService);
  fb = inject(FormBuilder);

  constructor(
    @Inject(MAT_DIALOG_DATA) public fullpit: Fullpit,
    public dialogRef: MatDialogRef<DialogChangedPriceComponent>
  ) {}
  ngOnDestroy(): void {
    if (!this.precioCambio) this.dialogRef.close();
  }

  ngOnInit() {
    this.initForm();
  }

  get precioBase() {
    return this.formPrice.get('precioBase');
  }

  get tieneIva() {
    return this.formPrice.get('tieneIva');
  }

  get total() {
    return this.formPrice.get('total');
  }

  initForm() {
    this.formPrice = this.fb.group({
      precioBase: [this.fullpit?.precioBase, Validators.required],
      tieneIva: [this.fullpit.tieneIva, Validators.required],
      total: [this.fullpit?.total, Validators.required],
    });
  }

  calculateTotal() {
    const precioBase = this.formPrice.get('precioBase')?.value;
    const tieneIva = this.formPrice.get('tieneIva')?.value;
    const codeAmount = this.fullpit.offerCode?.amount || 0;
    this.formPrice
      .get('total')
      ?.setValue(
        tieneIva
          ? precioBase * 1.19 + this.fullpit.costoDesplazamiento! - codeAmount
          : precioBase + this.fullpit.costoDesplazamiento! - codeAmount
      );
  }

  save() {
    if (this.formPrice.invalid) return;
    this.fullpit.precioBase = this.formPrice.get('precioBase')?.value;
    this.fullpit.tieneIva = this.formPrice.get('tieneIva')?.value;
    this.fullpit.total = this.formPrice.get('total')?.value;
    this.precioCambio = true;
    this.dialogRef.close(this.fullpit);
  }
}

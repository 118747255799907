<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="row">
  <ng-container *ngFor="let color of colores">
    <div class="col-lg-2 col-4">
      <div
        class="circle mx-auto my-2 cursor-pointer"
        [style]="{ 'background-color': color.color }"
        (click)="datosSeleccionados(color)"
      ></div>
    </div>
  </ng-container>
</div>

<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-lg-12">
      <h1 class="text-center fw-bold d-lg-block d-none">
        Selecciones por donde desea ver las indicaciones
      </h1>
      <h4 class="text-center fw-bold d-block d-lg-none">
        Selecciones por donde desea ver las indicaciones
      </h4>
    </div>
    <div
      class="col-lg-6 col-6 d-flex flex-column align-items-center justify-content-center"
    >
      <a
        href="https://www.google.com/maps/dir/?api=1&destination={{
          data.latitud
        }},{{ data.longitud }}"
        class="nav-link text-dark"
        target="_blank"
        [mat-dialog-close]
      >
        <img
          src="./assets/images/talleres-interna/navegar-por-google-maps.webp"
          class="img-fluid"
          style="width: 50px; height: 50px"
          alt="navegar-por-google-maps"
        />
      </a>
      <a
        href="https://www.google.com/maps/dir/?api=1&destination={{
          data.latitud
        }},{{ data.longitud }}"
        class="nav-link text-dark"
        target="_blank"
        [mat-dialog-close]
      >
        <p class="text-center">Google maps</p>
      </a>
    </div>
    <div
      class="col-lg-6 col-6 d-flex flex-column align-items-center justify-content-center"
    >
      <a
        href="https://www.waze.com/ul?ll={{ data.latitud }}%2C{{
          data.longitud
        }}&navigate=yes&zoom=17"
        class="nav-link text-dark"
        target="_blank"
        [mat-dialog-close]
      >
        <img
          src="./assets/images/talleres-interna/navegar-por-waze.webp"
          class="img-fluid"
          style="width: 50px; height: 50px"
          alt="navegar-por-waze"
        />
      </a>
      <a
        href="https://www.waze.com/ul?ll={{ data.latitud }}%2C{{
          data.longitud
        }}&navigate=yes&zoom=17"
        class="nav-link text-dark"
        target="_blank"
        [mat-dialog-close]
      >
        <p class="text-center">Waze</p>
      </a>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UbicacionActualComponent } from './ubicacion-actual/ubicacion-actual.component';
import { MaterialModule } from 'src/app/material/material.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogServiciosComponent } from './dialog-servicios/dialog-servicios.component';
import { DialogIndicadorComponent } from './dialog-indicador/dialog-indicador.component';
import { TipoVehiculoComponent } from './agregar-vehiculo/tipo-vehiculo/tipo-vehiculo.component';
import { MarcaVehiculoComponent } from './agregar-vehiculo/marca-vehiculo/marca-vehiculo.component';
import { LineaVehiculoComponent } from './agregar-vehiculo/linea-vehiculo/linea-vehiculo.component';
import { ModeloVehiculoComponent } from './agregar-vehiculo/modelo-vehiculo/modelo-vehiculo.component';
import { ColorVehiculoComponent } from './agregar-vehiculo/color-vehiculo/color-vehiculo.component';
import { CombustibleVehiculoComponent } from './agregar-vehiculo/combustible-vehiculo/combustible-vehiculo.component';
import { SeleccionarVehiculoComponent } from './solicitar-servicio/seleccionar-vehiculo/seleccionar-vehiculo.component';
import { SeleccionarServiciosComponent } from './solicitar-servicio/seleccionar-servicios/seleccionar-servicios.component';
import { SeleccionarLubricanteComponent } from './solicitar-servicio/seleccionar-lubricante/seleccionar-lubricante.component';
import { RouterModule } from '@angular/router';
import { SubirImagenGaleriaComponent } from './subir-imagen-galeria/subir-imagen-galeria.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TomarFotoWebcamComponent } from './tomar-foto-webcam/tomar-foto-webcam.component';
import { WebcamModule } from 'ngx-webcam';
import { DialogCalificacionServicioComponent } from './dialog-calificacion-servicio/dialog-calificacion-servicio.component';
import { DialogReportarProblemaComponent } from './dialog-reportar-problema/dialog-reportar-problema.component';
import { DialogVerComentariosComponent } from './dialog-ver-comentarios/dialog-ver-comentarios.component';
import { SeleccionarVehiculoUnicoComponent } from './servicio-unico/seleccionar-vehiculo-unico/seleccionar-vehiculo-unico.component';
import { SeleccionarServicioUnicoComponent } from './servicio-unico/seleccionar-servicio-unico/seleccionar-servicio-unico.component';
import { SeleccionarTipoAceiteComponent } from './servicio-unico/seleccionar-tipo-aceite/seleccionar-tipo-aceite.component';
import { TerminosYCondicionesBonoComponent } from './terminos-y-condiciones-bono/terminos-y-condiciones-bono.component';
import { BonoFidelizacionTycComponent } from './bono-fidelizacion-tyc/bono-fidelizacion-tyc.component';
import { BonoSorteoTycComponent } from './bono-sorteo-tyc/bono-sorteo-tyc.component';
import { DialogOpcionSolicitudComponent } from './dialog-opcion-solicitud/dialog-opcion-solicitud.component';
import { DialogTerminarServicioComponent } from './dialog-terminar-servicio/dialog-terminar-servicio.component';
import { DialogReprogramarServicioComponent } from './dialog-reprogramar-servicio/dialog-reprogramar-servicio.component';
import { AsignarPersonaServicioComponent } from './asignar-persona-servicio/asignar-persona-servicio.component';
import { SelectPaymentMethodComponent } from './select-payment-method/select-payment-method.component';
import { AsinarServiciosComponent } from './asinar-servicios/asinar-servicios.component';
import { EditVehicleComponent } from './edit-vehicle/edit-vehicle.component';
import { DialogChangedPriceComponent } from './dialog-changed-price/dialog-changed-price.component';

@NgModule({
  declarations: [
    UbicacionActualComponent,
    DialogServiciosComponent,
    DialogIndicadorComponent,
    TipoVehiculoComponent,
    MarcaVehiculoComponent,
    LineaVehiculoComponent,
    ModeloVehiculoComponent,
    ColorVehiculoComponent,
    CombustibleVehiculoComponent,
    SeleccionarVehiculoComponent,
    SeleccionarServiciosComponent,
    SeleccionarLubricanteComponent,
    SubirImagenGaleriaComponent,
    TomarFotoWebcamComponent,
    DialogCalificacionServicioComponent,
    DialogReportarProblemaComponent,
    DialogVerComentariosComponent,
    SeleccionarVehiculoUnicoComponent,
    SeleccionarServicioUnicoComponent,
    SeleccionarTipoAceiteComponent,
    TerminosYCondicionesBonoComponent,
    BonoFidelizacionTycComponent,
    BonoSorteoTycComponent,
    DialogOpcionSolicitudComponent,
    DialogTerminarServicioComponent,
    DialogReprogramarServicioComponent,
    AsignarPersonaServicioComponent,
    SelectPaymentMethodComponent,
    AsinarServiciosComponent,
    EditVehicleComponent,
    DialogChangedPriceComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    RouterModule,
    FormsModule,
    NgxDropzoneModule,
    WebcamModule,
    MaterialModule,
  ],
  exports: [DialogServiciosComponent],
})
export class DialogModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { CategoriasModule } from '../categorias/categorias.module';
import { ServiciosModule } from '../servicios/servicios.module';
import { BannerComponent } from './layout/banner/banner.component';
import { CoreModule } from 'src/app/core/core.module';
import { MaterialModule } from 'src/app/material/material.module';
import { DialogModule } from '../dialog/dialog.module';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    BannerComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CategoriasModule,
    ServiciosModule,
    CoreModule,
    MaterialModule,
    DialogModule,
    SwiperModule,
  ],
  exports: [HeaderComponent, FooterComponent],
})
export class HomeModule {}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Modelo } from '../../../../models/vehiculo';
declare var $: any;

@Component({
  selector: 'app-modelo-vehiculo',
  templateUrl: './modelo-vehiculo.component.html',
  styleUrls: ['./modelo-vehiculo.component.scss'],
})
export class ModeloVehiculoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ModeloVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  arrModelos = Array(new Date().getFullYear() + 1 - 1930);
  modelos: Modelo[] = [];

  ngOnInit(): void {
    this.addModelToTable();
  }

  addModelToTable() {
    for (let i = 0; i <= this.arrModelos.length; i++) {
      this.modelos[i] = {
        id: i + 1,
        year: 1930 + i,
      };
    }
    this.modelos.sort(function (a, b) {
      if (a.year > b.year) {
        return -1;
      }
      if (a.year < b.year) {
        return 1;
      }
      return 0;
    });
  }

  datosSeleccionados(modelo: Modelo) {
    this.data = modelo;
    this.dialogRef.close(this.data);
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
  scrollInicio() {
    $('body', 'html').animate({
      scrollTop: 0,
    });
  }
}

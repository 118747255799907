<section *ngIf="datosTaller" class="d-none d-lg-block">
  <div class="row">
    <div class="col-lg-4 bg-column-gris pb-5 px-4">
      <a href="javascript: history.go(-1)" class="nav-link fw-bold text-black"
        ><i class="fa-solid fa-arrow-left"></i> Volver</a
      >
      <div class="row">
        <div class="col-lg-12">
          <div
            class="card imagen-categoria d-flex justify-content-center align-items-center rounded"
            style="width: 239px; height: 239px; position: relative"
          >
            <img
              [src]="
                datosTaller.profilePicture
                  ? datosTaller.profilePicture
                  : image_tipo_taller
              "
              alt="taller_lavadero"
              class="img-fluid rounded"
              style="width: 381px; height: 239px"
              loading="lazy"
            />
            <div
              class="position-absolute z-index-1 sombra px-1 ubicar-corazon"
              style="background-color: rgba(255, 255, 255, 0.8)"
            >
              <a class="nav-link fw-bold text-black p-1">
                <i class="far fa-heart"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <h1 class="fw-bold mb-0">
            {{ datosTaller ? datosTaller.name : "" }}
          </h1>
        </div>
        <div class="col-lg-12">
          <ng-container
            *ngIf="datosTaller ? datosTaller.qualification! > 0 : 0"
          >
            <div
              *ngIf="datosTaller ? datosTaller.qualification : 0"
              class="d-flex justify-content-center align-items-center"
            >
              <!-- INICIA ESTRELLAS -->
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 0.3 &&
                      datosTaller.qualification! <= 0.7;
                    else nuevaValidacion1
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion1>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 0.8; else vacio1"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio1>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 1.3 &&
                      datosTaller.qualification! <= 1.7;
                    else nuevaValidacion2
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion2>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 1.8; else vacio2"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio2>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 2.3 &&
                      datosTaller.qualification! <= 2.7;
                    else nuevaValidacion3
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion3>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 2.8; else vacio3"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio3>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 3.3 &&
                      datosTaller.qualification! <= 3.7;
                    else nuevaValidacion4
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion4>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 3.8; else vacio4"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 4.3 &&
                      datosTaller.qualification! <= 4.7;
                    else nuevaValidacion5
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion5>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 4.8; else vacio4"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <!-- FIN ESTRELLAS -->
              <span class="text-color fw-bold ms-1" style="font-size: 30px">{{
                (datosTaller.qualification ? datosTaller.qualification : "")
                  | number : "1.1-1"
              }}</span>
            </div>
          </ng-container>
          <div class="text-center">
            <a
              class="nav-link p-0 mb-1 cursor-pointer"
              (click)="verComentarios()"
            >
              <span class="text-color descripcion-taller">Ver comentarios</span>
            </a>
          </div>
          <ng-container *ngIf="datosTaller?.esCercano; else verDistancia">
            <div class="text-center fw-bold descripcion-taller">
              <a
                class="cursor-pointer nav-link p-0 text-center text-dark fw-bold"
                (click)="abrirDialog()"
              >
                A {{ datosTaller.distancia }} de tu ubicación
              </a>
            </div>
          </ng-container>
          <ng-template #verDistancia>
            <ng-container
              *ngIf="datosTaller?.esCercano === false; else noUbicacionActual"
            >
              <a
                class="cursor-pointer nav-link p-0 text-center text-dark fw-bold"
                (click)="abrirDialog()"
                >Está a mas de {{ distancia }}km de tu ubicación</a
              >
            </ng-container>
            <ng-template #noUbicacionActual>
              <a
                class="cursor-pointer text-dark text-center fw-bold d-block p-0"
                (click)="abrirDialog()"
                >Ver distancia</a
              >
            </ng-template>
          </ng-template>
        </div>
        <div class="col-lg-12 mt-4">
          <div class="d-flex justify-content-center align-items-center">
            <div>
              <img
                src="./assets/images/talleres-interna/ubicacion-en-negro.webp"
                alt="ubicacion-en-negro"
                class="img-fluid"
                style="width: 47px"
                loading="lazy"
              />
            </div>
            <div class="mx-4">
              <p class="mb-0 descripcion-taller">
                {{ datosTaller ? datosTaller.address : "" }},
                {{ datosTaller ? datosTaller.city!.name : "" }}
              </p>
            </div>
            <div>
              <a
                class="cursor-pointer"
                (click)="
                  abrirInidicaciones(
                    datosTaller.latitude!,
                    datosTaller.longitude!
                  )
                "
              >
                <img
                  src="./assets/images/talleres-interna/indicaciones-fullpits.webp"
                  alt="indicaciones-fullpits"
                  class="img-fluid"
                  style="width: 47px; border-radius: 15px"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-2">
          <div
            id="horario_principal"
            class="d-flex justify-content-center align-items-end cursor-pointer mt-3"
          >
            <div>
              <img
                src="./assets/images/talleres-interna/calendario-fullpits.webp"
                alt="calendario-fullpits"
                class="img-fluid me-1"
                width="42px"
                loading="lazy"
              />
            </div>
            <ng-container
              *ngIf="abierto_cerrado && datosTaller.listHorarios!.length > 0"
            >
              <div>
                <p class="fw-bold mb-0 descripcion-taller">Abierto</p>
                <ng-container *ngIf="diaActual.allDay; else horarioNormal">
                  <p class="mb-0 descripcion-taller">Abierto las 24 horas</p>
                </ng-container>
                <ng-template #horarioNormal>
                  <p class="mb-0 descripcion-taller">
                    Cierra a las {{ diaActual ? diaActual.end : "" }}
                  </p>
                </ng-template>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                !abierto_cerrado && datosTaller
                  ? datosTaller.listHorarios!.length
                  : 0 > 0
              "
            >
              <div>
                <ng-container
                  *ngIf="
                    diaActual?.init != null &&
                      diaActual?.init != '' &&
                      !diaActual.closed;
                    else cerrado
                  "
                >
                  <p class="fw-bold mb-0 descripcion-taller">Cerrado</p>
                  <p class="mb-0 descripcion-taller">
                    <ng-container *ngIf="cerradoTodoDia">
                      Cerrado mañana todo el día
                    </ng-container>
                    <ng-container *ngIf="abiertoTodoDia">
                      Abre mañana a las 00:00
                    </ng-container>
                    <ng-container *ngIf="!cerradoTodoDia && !abiertoTodoDia">
                      <ng-container
                        *ngIf="abiertoDiaSiguiente; else AbiertoHoy"
                      >
                        Abre mañana a las {{ diaSiguiente }}
                      </ng-container>
                      <ng-template #AbiertoHoy>
                        Abre a las {{ diaSiguiente }}
                      </ng-template>
                    </ng-container>
                  </p>
                </ng-container>
                <ng-template #cerrado>
                  <p class="fw-bold mb-0 descripcion-taller">Cerrado</p>
                  <p class="mb-0 descripcion-taller">Todo el día</p>
                </ng-template>
              </div>
            </ng-container>
            <ng-container *ngIf="datosTaller ? datosTaller.listHorarios : ''">
              <ng-container *ngIf="datosTaller.listHorarios!.length === 0">
                <div>
                  <p class="mb-0 descripcion-taller text-center">
                    No tiene horario
                  </p>
                  <p class="mb-0 descripcion-taller text-center">establecido</p>
                </div>
              </ng-container>
            </ng-container>
            <div>
              <i class="fas fa-sort-up"></i>
            </div>
          </div>
          <div
            id="lista_horarios"
            class="row d-none mt-2 d-flex justify-content-end"
          >
            <ng-container *ngIf="datosTaller ? datosTaller.listHorarios : ''">
              <ng-container
                *ngFor="let horario of datosTaller.listHorarios; let i = index"
              >
                <ng-container *ngIf="horario.allDay">
                  <ng-container *ngIf="isToday[i]; else allDayNoToday">
                    <div
                      class="offset-lg-3 col-lg-4 offset-3 col-4 text-naranja"
                    >
                      {{ horario.name }}
                    </div>
                    <div class="col-lg-4 col-4 text-naranja">
                      <span class="">Abierto 24 horas</span>
                    </div>
                  </ng-container>
                  <ng-template #allDayNoToday>
                    <div class="offset-lg-3 col-lg-4 offset-3 col-4">
                      {{ horario.name }}
                    </div>
                    <div class="col-lg-4 col-4">
                      <span class="">Abierto 24 horas</span>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container *ngIf="horario.closed">
                  <ng-container *ngIf="isToday[i]; else closedNoToday">
                    <div
                      class="offset-lg-3 col-lg-4 offset-3 col-4 text-naranja"
                    >
                      {{ horario.name }}
                    </div>
                    <div class="col-lg-4 col-4 text-naranja">
                      <span class="">Cerrado todo el día</span>
                    </div>
                  </ng-container>
                  <ng-template #closedNoToday>
                    <div class="offset-lg-3 col-lg-4 offset-3 col-4">
                      {{ horario.name }}
                    </div>
                    <div class="col-lg-4 col-4">
                      <span class="">Cerrado todo el día</span>
                    </div>
                  </ng-template>
                </ng-container>
                <ng-container
                  *ngIf="
                    horario?.init &&
                    horario.end &&
                    !horario.allDay &&
                    !horario.closed
                  "
                >
                  <ng-container *ngIf="isToday[i]; else isTodayNoHorario">
                    <div
                      class="offset-lg-3 col-lg-4 offset-3 col-4 text-naranja"
                    >
                      {{ horario.name }}
                    </div>
                    <div class="col-lg-4 col-4 text-naranja">
                      {{ horario?.init + " - " + horario.end }}
                    </div>
                  </ng-container>
                  <ng-template #isTodayNoHorario>
                    <div class="offset-lg-3 col-lg-4 offset-3 col-4">
                      {{ horario.name }}
                    </div>
                    <div class="col-lg-4 col-4">
                      {{ horario?.init + " - " + horario.end }}
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-12 mt-2">
          <ng-container *ngIf="domicilio">
            <div class="d-flex justify-content-center align-items-center mt-3">
              <div>
                <img
                  src="./assets/images/talleres-interna/servicio_domicilio.webp"
                  alt="ubicacion"
                  class="img-fluid me-1"
                  style="width: 47px"
                  loading="lazy"
                />
              </div>
              <div>
                <p class="mb-0 descripcion-taller">Este taller presta</p>
                <p class="mb-0 descripcion-taller">servicio a domicilio</p>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="col-lg-12 mt-4 px-2">
          <div class="text-center">
            <a
              class="btn btn-naranja"
              (click)="traerOpcionSolicitud(datosTaller.name!)"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                style="gap: 13px"
              >
                <div>
                  <img
                    src="./assets/images/footer/whatsapp-fullpits.webp"
                    alt="solicitar-servicio"
                    class="img-fluid"
                    width="40px"
                    loading="lazy"
                  />
                </div>
                <div style="line-height: 1">
                  <p class="mb-0 descripcion-taller">
                    Cotizar o agendar servicios
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-8 p-5">
      <h1 class="fw-bold ms-3">Servicios</h1>
      <section id="servicios">
        <div class="row">
          <ng-container *ngFor="let servicio of servicios">
            <div class="col-lg-6 mt-5">
              <div class="d-flex justify-content-start align-items-center">
                <div>
                  <img
                    [src]="servicio.typeService!.image"
                    [alt]="servicio.title"
                    class="img-fluid"
                    style="width: 120px"
                    loading="lazy"
                  />
                </div>
                <div class="text-center">
                  <ng-container *ngIf="servicio.title; else noTituloPC">
                    <h3 class="fw-bold mb-0" style="font-size: 21px">
                      {{ servicio.title }}
                    </h3>
                  </ng-container>
                  <ng-template #noTituloPC>
                    <h3 class="mb-0" style="height: 28px"></h3>
                  </ng-template>
                  <ng-container
                    *ngIf="servicio.description; else noDescripcionPC"
                  >
                    <div
                      class="mb-0 text-secondary text-oveflow-service"
                      style="font-size: 17px"
                      [innerHTML]="servicio.description"
                    ></div>
                  </ng-container>
                  <ng-template #noDescripcionPC>
                    <p class="mb-0" style="height: 20px; width: 300px"></p>
                  </ng-template>
                  <ng-container *ngIf="servicio.price != null; else noPrecioPC">
                    <h2 class="mb-0 fw-bold">
                      Precio: COP
                      {{ servicio.price | currency : "$" : "symbol" : "1.0-0" }}
                    </h2>
                  </ng-container>
                  <ng-template #noPrecioPC>
                    <h2 class="mb-0" style="height: 32px"></h2>
                  </ng-template>
                  <div class="row">
                    <div class="col-12">
                      <button
                        class="btn btn-negro p-0 px-5 mb-2"
                        style="width: 220px"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        (click)="servicioObj = servicio"
                      >
                        Ver detalles
                      </button>
                    </div>
                    <div class="col-12">
                      <a
                        class="btn btn-naranja p-0 px-4"
                        style="width: 220px"
                        (click)="agendarServicio(servicio)"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center gap-3 flex-wrap"
                        >
                          <div>
                            <img
                              src="./assets/images/footer/whatsapp-fullpits.webp"
                              alt="solicitar-servicio"
                              style="width: 23px; height: 23px"
                              loading="lazy"
                            />
                          </div>
                          <div>Cotizar servicio</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </section>
    </div>
  </div>
</section>

<app-dialog-servicios
  *ngIf="datosTaller && servicioObj"
  [idTaller]="datosTaller ? datosTaller.id! : 0"
  [servicioObj]="servicioObj ? servicioObj : {}"
  [nombreTaller]="datosTaller ? datosTaller.name! : ''"
></app-dialog-servicios>

<!-- Sección movil -->
<section *ngIf="datosTaller" class="d-lg-none d-block">
  <div class="container-fluid pt-4 px-2 pt-2 bg-column-gris position-relative">
    <div class="d-flex" style="gap: 5px">
      <div
        class="card imagen-categoria d-flex justify-content-center align-items-center flex-grow-1"
        style="position: relative"
      >
        <img
          [src]="
            datosTaller.profilePicture
              ? datosTaller.profilePicture
              : image_tipo_taller
          "
          alt="taller-auto"
          class="img-fluid"
          loading="lazy"
        />
        <div
          class="position-absolute z-index-1 sombra px-1 ubicar-flecha"
          style="background-color: rgba(255, 255, 255, 0.8)"
        >
          <a
            href="javascript: history.go(-1)"
            class="nav-link fw-bold text-black p-1"
            ><i class="fas fa-arrow-left"></i
          ></a>
        </div>
        <div
          class="position-absolute z-index-1 sombra px-1 ubicar-corazon"
          style="background-color: rgba(255, 255, 255, 0.8)"
        >
          <a class="nav-link fw-bold text-black p-1">
            <i class="far fa-heart"></i>
          </a>
        </div>
      </div>
      <div
        class="d-flex justify-content-start flex-column align-items-center"
        style="flex-grow: 6"
      >
        <h1 class="fw-bold mb-0 titulo text-center" style="line-height: 1">
          {{ datosTaller ? datosTaller.name : "" }}
        </h1>
        <a class="nav-link p-0 mb-1" (click)="verComentarios()">
          <ng-container
            *ngIf="datosTaller ? datosTaller.qualification! > 0 : 0"
          >
            <div
              *ngIf="datosTaller ? datosTaller.qualification : 0"
              class="d-flex justify-content-center align-items-center"
            >
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 0.3 &&
                      datosTaller.qualification! <= 0.7;
                    else nuevaValidacion1
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion1>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 1; else vacio1"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio1>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 1.3 &&
                      datosTaller.qualification! <= 1.7;
                    else nuevaValidacion2
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion2>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 2; else vacio2"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio2>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 2.3 &&
                      datosTaller.qualification! <= 2.7;
                    else nuevaValidacion3
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion3>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 3; else vacio3"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio3>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 3.3 &&
                      datosTaller.qualification! <= 3.7;
                    else nuevaValidacion4
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion4>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 4; else vacio4"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <div>
                <ng-container
                  *ngIf="
                    datosTaller.qualification! >= 4.3 &&
                      datosTaller.qualification! <= 4.7;
                    else nuevaValidacion5
                  "
                >
                  <img
                    src="./assets/images/home/media-estrella.webp"
                    alt="media-estrella"
                    style="width: 28px"
                    loading="lazy"
                  />
                </ng-container>
                <ng-template #nuevaValidacion5>
                  <ng-container
                    *ngIf="datosTaller.qualification! >= 5; else vacio4"
                  >
                    <img
                      src="./assets/images/home/estrella-completa.webp"
                      alt="estrella-completa"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-container>
                  <ng-template #vacio4>
                    <img
                      src="./assets/images/home/estrella-vacia.webp"
                      alt="estrella-vacia"
                      style="width: 28px"
                      loading="lazy"
                    />
                  </ng-template>
                </ng-template>
              </div>
              <span class="text-color fw-bold ms-1" style="font-size: 30px">{{
                (datosTaller.qualification ? datosTaller.qualification : "")
                  | number : "1.1-1"
              }}</span>
            </div>
          </ng-container>
          <div class="text-center">
            <span class="text-color">Ver comentarios</span>
          </div>
        </a>
        <ng-container *ngIf="datosTaller?.esCercano; else verDistanciamovil">
          <div class="text-center fw-bold">
            A {{ datosTaller.distancia }} de tu ubicación
          </div>
        </ng-container>
        <ng-template #verDistanciamovil>
          <ng-container
            *ngIf="
              datosTaller?.esCercano === false;
              else noUbicacionActualmovil
            "
          >
            <a
              class="cursor-pointer nav-link p-0 text-center text-dark fw-bold"
              (click)="abrirDialog()"
              >Está muy lejos de tu ubicación</a
            >
          </ng-container>
          <ng-template #noUbicacionActualmovil>
            <a
              class="cursor-pointer text-dark text-center fw-bold d-block p-0"
              (click)="abrirDialog()"
              >Ver distancia</a
            >
          </ng-template>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <hr />
      </div>
      <div class="col-lg-12">
        <div class="d-flex justify-content-center align-items-center">
          <div>
            <img
              src="./assets/images/talleres-interna/ubicacion-en-negro.webp"
              alt="ubicacion-en-negro"
              class="img-fluid"
              style="width: 47px"
              loading="lazy"
            />
          </div>
          <div class="mx-1">
            <p class="mb-0">
              {{ datosTaller ? datosTaller.address : "" }},
              {{ datosTaller ? datosTaller.city!.name : "" }}
            </p>
          </div>
          <div>
            <a
              (click)="
                abrirInidicaciones(
                  datosTaller.latitude!,
                  datosTaller.longitude!
                )
              "
            >
              <img
                src="./assets/images/talleres-interna/indicaciones-fullpits.webp"
                alt="indicaciones-fullpits"
                class="img-fluid"
                style="width: 47px; border-radius: 15px"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-6 px-0 ps-2">
        <div
          id="horario_principal_movil"
          class="d-flex justify-content-center align-items-end"
        >
          <div>
            <img
              src="./assets/images/talleres-interna/calendario-fullpits.webp"
              alt="calendario-fullpits"
              class="img-fluid me-1"
              style="width: 37px"
              loading="lazy"
            />
          </div>
          <ng-container
            *ngIf="abierto_cerrado && datosTaller.listHorarios!.length > 0"
          >
            <div class="horario-servicios">
              <p class="fw-bold mb-0 titulo-horario">Abierto</p>
              <ng-container *ngIf="diaActual.allDay; else horarioNormal">
                <p class="mb-0 texto-horario">Abierto las 24 horas</p>
              </ng-container>
              <ng-template #horarioNormal>
                <p class="mb-0 texto-horario">
                  Cierra a las {{ diaActual ? diaActual.end : "" }}
                </p>
              </ng-template>
            </div>
          </ng-container>
          <ng-container *ngIf="datosTaller ? datosTaller.listHorarios : ''">
            <ng-container
              *ngIf="
                !abierto_cerrado && datosTaller
                  ? datosTaller.listHorarios!.length
                  : 0 > 0
              "
            >
              <div>
                <ng-container
                  *ngIf="
                    diaActual?.init != null &&
                      diaActual?.init != '' &&
                      !diaActual.closed;
                    else cerrado
                  "
                >
                  <p class="fw-bold mb-0 descripcion-taller">Cerrado</p>
                  <p class="mb-0 descripcion-taller">
                    <ng-container *ngIf="cerradoTodoDia">
                      Cerrado mañana todo el día
                    </ng-container>
                    <ng-container *ngIf="abiertoTodoDia">
                      Abre mañana a las 00:00
                    </ng-container>
                    <ng-container *ngIf="!cerradoTodoDia && !abiertoTodoDia">
                      <ng-container
                        *ngIf="abiertoDiaSiguiente; else AbiertoHoy"
                      >
                        Abre mañana a las {{ diaSiguiente }}
                      </ng-container>
                      <ng-template #AbiertoHoy>
                        Abre a las {{ diaSiguiente }}
                      </ng-template>
                    </ng-container>
                  </p>
                </ng-container>
                <ng-template #cerrado>
                  <p class="fw-bold mb-0 descripcion-taller">Cerrado</p>
                  <p class="mb-0 descripcion-taller">Todo el día</p>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="datosTaller ? datosTaller.listHorarios : ''">
            <ng-container *ngIf="datosTaller.listHorarios!.length === 0">
              <div class="horario-servicios">
                <p class="mb-0 text-center texto-horario">No tiene horario</p>
                <p class="mb-0 text-center texto-horario">establecido</p>
              </div>
            </ng-container>
          </ng-container>
          <div>
            <i class="fas fa-sort-up"></i>
          </div>
        </div>
      </div>
      <div class="col-6 px-0 pe-2">
        <div class="d-flex justify-content-center align-items-center">
          <ng-container *ngIf="domicilio">
            <div>
              <img
                src="./assets/images/talleres-interna/servicio_domicilio.webp"
                alt="ubicacion"
                class="img-fluid me-1"
                style="width: 37px"
                loading="lazy"
              />
            </div>
            <div class="horario-servicios">
              <p class="mb-0 texto-servicios">Este taller presta</p>
              <p class="mb-0 texto-servicios">servicio a domicilio</p>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <div
          id="lista_horarios_movil"
          class="row d-none mt-2 d-flex justify-content-end"
        >
          <ng-container *ngIf="datosTaller ? datosTaller.listHorarios : ''">
            <ng-container
              *ngFor="let horario of datosTaller.listHorarios; let i = index"
            >
              <ng-container *ngIf="horario.allDay">
                <ng-container *ngIf="isToday[i]; else allDayNoToday">
                  <div class="col-6 ps-5 text-naranja">
                    {{ horario.name }}
                  </div>
                  <div class="col-6 text-naranja">
                    <span class="">Abierto 24 horas</span>
                  </div>
                </ng-container>
                <ng-template #allDayNoToday>
                  <div class="col-6 ps-5">
                    {{ horario.name }}
                  </div>
                  <div class="col-6">
                    <span class="">Abierto 24 horas</span>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container *ngIf="horario.closed">
                <ng-container *ngIf="isToday[i]; else closedNoToday">
                  <div class="col-6 ps-5 text-naranja">
                    {{ horario.name }}
                  </div>
                  <div class="col-6 text-naranja">
                    <span class="">Cerrado todo el día</span>
                  </div>
                </ng-container>
                <ng-template #closedNoToday>
                  <div class="col-6 ps-5">
                    {{ horario.name }}
                  </div>
                  <div class="col-6">
                    <span class="">Cerrado todo el día</span>
                  </div>
                </ng-template>
              </ng-container>
              <ng-container
                *ngIf="
                  horario?.init &&
                  horario.end &&
                  !horario.allDay &&
                  !horario.closed
                "
              >
                <ng-container *ngIf="isToday[i]; else isTodayNoHorario">
                  <div class="col-6 ps-5 text-naranja">
                    {{ horario.name }}
                  </div>
                  <div class="col-6 text-naranja">
                    {{ horario?.init + " - " + horario.end }}
                  </div>
                </ng-container>
                <ng-template #isTodayNoHorario>
                  <div class="col-6 ps-5">
                    {{ horario.name }}
                  </div>
                  <div class="col-6">
                    {{ horario?.init + " - " + horario.end }}
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div class="col-lg-12 my-4 px-3">
        <div class="text-center">
          <a
            class="btn btn-naranja w-100"
            (click)="traerOpcionSolicitud(datosTaller.name!)"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="gap: 26px"
            >
              <div>
                <img
                  src="./assets/images/footer/whatsapp-fullpits.webp"
                  alt="solicitar-servicio"
                  class="img-fluid"
                  width="40px"
                  loading="lazy"
                />
              </div>
              <div style="line-height: 1">
                <p class="mb-0">Cotizar o agendar servicios</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 my-4">
        <h1 class="fw-bold">Servicios</h1>
        <ng-container
          *ngIf="servicios?.length ? servicios?.length : 0 > 0; else noHayDatos"
        >
          <swiper [config]="config">
            <ng-container *ngFor="let servicio of servicios">
              <ng-template swiperSlide>
                <div
                  class="d-flex flex-column p-2 justify-content-center aling-items-center"
                  style="border-right: 3px solid #7c7c7c; height: 270px"
                >
                  <div class="flex-grow-1 d-block m-auto">
                    <img
                      [src]="servicio.typeService!.image"
                      [alt]="servicio.title"
                      style="width: 83px"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <h2
                      class="fw-bold mb-0 text-center"
                      style="
                        line-height: 1;
                        height: 32px;
                        font-size: 15px;
                        display: -webkit-box;
                        width: 100%;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      "
                    >
                      {{ servicio.title }}
                    </h2>
                  </div>
                  <div>
                    <ng-container
                      *ngIf="servicio.description; else noDescripcion"
                    >
                      <p
                        class="mb-0 text-center"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          width: 100%;
                          height: 2em;
                        "
                        [innerHTML]="servicio.description!"
                      ></p>
                    </ng-container>
                    <ng-template #noDescripcion>
                      <p class="mb-0" style="height: 20px"></p>
                    </ng-template>
                  </div>
                  <div>
                    <ng-container *ngIf="servicio.price; else noPrecio">
                      <h5
                        class="mb-0 fw-bold text-center"
                        style="font-size: 14px"
                      >
                        Precio: COP
                        {{
                          servicio.price | currency : "$" : "symbol" : "1.0-0"
                        }}
                      </h5>
                    </ng-container>
                    <ng-template #noPrecio>
                      <h5 class="mb-0" style="height: 40px"></h5>
                    </ng-template>
                  </div>
                  <div>
                    <div class="row">
                      <div class="col-12">
                        <button
                          class="btn btn-dark w-100 mb-2"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          (click)="servicioObj = servicio"
                          style="font-size: 11px"
                        >
                          Ver detalles
                        </button>
                      </div>
                      <div class="col-12">
                        <a
                          class="btn btn-naranja rounded w-100"
                          style="font-size: 11px"
                          (click)="agendarServicio(servicio)"
                        >
                          Cotizar Servicio
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </swiper>
        </ng-container>
        <ng-template #noHayDatos>
          <div class="d-flex">
            <div>
              <h3>No tiene actualmente servicios</h3>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12"></div>
    </div>
  </div>
</section>

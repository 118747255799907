<div class="d-flex justify-content-between align-items-center">
  <div>
    <a
      class="btn btn-link text-naranja shadow-none"
      (click)="agregarVehiculo()"
      (click)="cerrarDialog()"
    >
      Agregar vehiculo
    </a>
  </div>
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <ul class="list-group">
        <ng-container *ngFor="let miVehiculo of misVehiculos">
          <li
            class="list-group-item cursor-pointer bg-gris"
            (click)="seleccionarVehiculo(miVehiculo)"
          >
            <div
              class="d-flex justify-content-center align-items-center gap-1 flex-wrap fw-bold"
            >
              <div>
                <div
                  class="circle"
                  [style]="{ 'background-color': '#' + miVehiculo.color }"
                ></div>
              </div>
              <div>
                <span>{{ miVehiculo!.linea!.marca!.name }}, </span>
              </div>
              <div>
                <span>{{ miVehiculo!.linea!.name }}, </span>
              </div>
              <div>
                <span>{{ miVehiculo.model }}, </span>
              </div>
              <div>
                <span>{{ miVehiculo.placa }}</span>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { CardTipoTaller } from 'src/app/models/tarjetas-tipo-taller';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import tarjetasJSON from '../../../../../assets/json/tarjetas-tipo-taller.json';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'app-servicios-card',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss'],
})
export class ServiciosCardComponent implements OnInit {
  tarjetasTipoTaller: CardTipoTaller[] = tarjetasJSON;

  constructor() {}

  ngOnInit(): void {}
}

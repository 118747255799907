import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { RedireccionarUserGuard } from './core/shared/guard/redireccionar-user.guard';
import { RoleUserGuard } from './core/shared/guard/role-user.guard';

const routes: Routes = [
  {
    path: 'user/:token',
    loadChildren: () =>
      import('./modulos/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'user/:token/:idAdmin',
    loadChildren: () =>
      import('./modulos/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'talleres-para-carros',
    loadChildren: () =>
      import(
        './modulos/listados-talleres-carros/listados-talleres-carros.module'
      ).then((m) => m.ListadosTalleresCarrosModule),
  },
  {
    path: 'talleres-para-motos',
    loadChildren: () =>
      import(
        './modulos/listados-talleres-motos/listados-talleres-motos.module'
      ).then((m) => m.ListadosTalleresMotosModule),
  },
  {
    path: 'autolavados',
    loadChildren: () =>
      import('./modulos/listados-autolavados/listados-autolavados.module').then(
        (m) => m.ListadosAutolavadosModule
      ),
  },
  {
    path: 'motolavados',
    loadChildren: () =>
      import('./modulos/listados-motolavados/listados-motolavados.module').then(
        (m) => m.ListadosMotolavadosModule
      ),
  },
  {
    path: 'ver-mas',
    loadChildren: () =>
      import('./modulos/listado-ver-todo/listado-ver-todo.module').then(
        (m) => m.ListadoVerTodoModule
      ),
  },
  {
    path: 'talleres/:id',
    loadChildren: () =>
      import('./modulos/productos/productos.module').then(
        (m) => m.ProductosModule
      ),
  },
  {
    path: 'informacion',
    loadChildren: () =>
      import('./modulos/informacion/informacion.module').then(
        (m) => m.InformacionModule
      ),
  },
  {
    path: 'mis-vehiculos',
    loadChildren: () =>
      import('./modulos/mis-vehiculos/mis-vehiculos.module').then(
        (m) => m.MisVehiculosModule
      ),
    canActivate: [RedireccionarUserGuard],
  },
  {
    path: 'registra-tu-negocio',
    loadChildren: () =>
      import('./modulos/registra-tu-negocio/registra-tu-negocio.module').then(
        (m) => m.RegistraTuNegocioModule
      ),
  },
  {
    path: 'ayuda',
    loadChildren: () =>
      import('./modulos/ayuda/ayuda.module').then((m) => m.AyudaModule),
  },
  {
    path: 'mi-perfil',
    loadChildren: () =>
      import('./modulos/mi-perfil/mi-perfil.module').then(
        (m) => m.MiPerfilModule
      ),
    canActivate: [RedireccionarUserGuard],
  },
  {
    path: 'privacidad',
    loadChildren: () =>
      import('./modulos/privacidad/privacidad.module').then(
        (m) => m.PrivacidadModule
      ),
  },
  {
    path: 'solicitar-servicio/:idTaller',
    loadChildren: () =>
      import('./modulos/mi-servicio-unico/mi-servicio-unico.module').then(
        (m) => m.MiServicioUnicoModule
      ),
    canActivate: [RoleUserGuard],
  },
  {
    path: 'analitica-solicitar-servicio',
    loadChildren: () =>
      import(
        './modulos/analitica-solicitar-servicio/analitica-solicitar-servicio.module'
      ).then((m) => m.AnaliticaSolicitarServicioModule),
  },
  {
    path: 'notificaciones',
    loadChildren: () =>
      import(
        './modulos/listado-notificaciones/listado-notificaciones.module'
      ).then((m) => m.ListadoNotificacionesModule),
    canActivate: [RoleUserGuard],
  },
  {
    path: 'mis-servicios',
    loadChildren: () =>
      import(
        './modulos/mis-servicios-fullpits/mis-servicios-fullpits.module'
      ).then((m) => m.MisServiciosFullpitsModule),
    canActivate: [RedireccionarUserGuard],
  },
  {
    path: 'landing-pages',
    loadChildren: () =>
      import('./modulos/landingpages/landingpages.module').then(
        (m) => m.LandingpagesModule
      ),
  },
  {
    path: ':search',
    loadChildren: () =>
      import('./modulos/listados-taller/listados-taller.module').then(
        (m) => m.ListadosTallerModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modulos/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modulos/home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: QuicklinkStrategy,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

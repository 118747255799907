<swiper
  [slidesPerView]="7"
  [spaceBetween]="20"
  [breakpoints]="{
    '0': {
      navigation: false,
      loop: false,
      slidesPerView: 2.5,
      spaceBetween: 20
    },
    '576': {
      navigation: false,
      loop: false,
      slidesPerView: 3.5,
      spaceBetween: 20
    },
    '768': {
      navigation: false,
      loop: false,
      slidesPerView: 3.5,
      spaceBetween: 20
    },
    '1200': {
      loop: false,
      slidesPerView: 4.5,
      spaceBetween: 20
    },
    '1400': {
      loop: false,
      slidesPerView: 7.5,
      spaceBetween: 20
    }
  }"
  class="ms-3"
>
  <ng-container *ngFor="let tipoTaller of tarjetasTipoTaller">
    <ng-template swiperSlide>
      <ng-container *ngIf="tipoTaller.href; else rutaAngular">
        <a
          class="cursor-pointer"
          [href]="[tipoTaller.ruta]"
          target="_blank"
          style="text-decoration: none"
        >
          <div class="card mx-auto shadow align-items-center rounded">
            <img
              [src]="tipoTaller.imagen"
              loading="lazy"
              class="img-fluid tipo-taller"
              [alt]="tipoTaller.alt"
            />
            <div class="card-body p-3">
              <h5
                class="card-title text-dark fs-4 d-none d-lg-block text-center"
              >
                {{ tipoTaller.texto }}
              </h5>
              <h5
                class="card-title text-dark fs-6 lh-1 d-lg-none d-block mb-0 text-center"
              >
                {{ tipoTaller.texto }}
              </h5>
            </div>
          </div>
        </a>
      </ng-container>
      <ng-template #rutaAngular>
        <a
          class="cursor-pointer"
          [routerLink]="[tipoTaller.ruta]"
          style="text-decoration: none"
        >
          <div class="card mx-auto shadow align-items-center rounded">
            <img
              [src]="tipoTaller.imagen"
              loading="lazy"
              class="img-fluid tipo-taller"
              [alt]="tipoTaller.alt"
            />
            <div class="card-body p-3">
              <h5
                class="card-title text-dark fs-4 d-none d-lg-block text-center"
              >
                {{ tipoTaller.texto }}
              </h5>
              <h5
                class="card-title text-dark fs-6 lh-1 d-lg-none d-block mb-0 text-center"
              >
                {{ tipoTaller.texto }}
              </h5>
            </div>
          </div>
        </a>
      </ng-template>
    </ng-template>
  </ng-container>
</swiper>

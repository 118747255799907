import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Chat } from 'src/app/models/chat';

@Component({
  selector: 'app-chat-audio',
  templateUrl: './chat-audio.component.html',
  styleUrls: ['./chat-audio.component.scss'],
})
export class ChatAudioComponent implements OnInit {
  @Input() audio: Chat;
  @Input() id: number;
  iconoAudio: string = 'play_arrow';
  bucle: any;
  medio: HTMLMediaElement;
  progreso: HTMLElement;
  barra: HTMLElement;
  maximo: number = 600;
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit(): void {}

  // Audio personalizado

  reproducirAudio(medioAudio: string) {
    this.medio = <HTMLMediaElement>document.querySelector(`#${medioAudio}`);
    this.progreso = <HTMLElement>document.querySelector(`#progreso${this.id}`);
    this.barra = <HTMLElement>document.querySelector(`#barra${this.id}`);

    if (!this.medio.paused && !this.medio.ended) {
      this.medio.pause();
      this.iconoAudio = 'play_arrow';
      clearInterval(this.bucle);
    } else {
      this.medio.play();
      this.iconoAudio = 'pause';
      this.bucle = setInterval(() => {
        this.stateProgressBar();
      }, 100);
    }
  }

  stateProgressBar() {
    if (!this.medio.ended) {
      let total = (this.medio.currentTime * 100) / this.medio.duration;
      this.progreso.style.width = total + '%';
    } else {
      this.progreso.style.width = '0%';
      this.iconoAudio = 'play_arrow';
      if (isPlatformBrowser(this.platformId)) {
        window.clearInterval(this.bucle);
      }
    }
  }
  moveProgressBar(e: any) {
    if (!this.medio.paused && !this.medio.ended) {
      let ratonX = e.pageX - this.barra.offsetLeft;
      let nuevoTiempo = ratonX / 100 / this.medio.duration;
      this.medio.currentTime = nuevoTiempo;
      this.progreso.style.width = nuevoTiempo + '%';
    }
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ConfigMeta } from 'src/app/models/config-meta';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private meta: Meta, @Inject(DOCUMENT) private doc: any) {}

  generateTags(config: ConfigMeta) {
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    // this.meta.updateTag({name: 'twitter:site', content: '@mi-ng'});
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({
      name: 'twitter:description',
      content: config.description,
    });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Fullpits' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({
      property: 'og:description',
      content: config.description,
    });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({
      property: 'og:url',
      content: `${environment.urlHome}${config.slug}`,
    });
    this.meta.updateTag({ name: 'description', content: config.description });
  }
  createLinkForCanonicalURL() {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    let url = this.doc.URL.replace('http://', 'https://');
    link.setAttribute('href', url);
  }
}

import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'src/app/core/shared/services/location.service';
import { SeoService } from 'src/app/core/shared/services/seo.service';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { ConfigMeta } from 'src/app/models/config-meta';
import { JwtUtils } from '../../core/shared/utils/jwt-utils';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  latitude: any;
  longitude: any;
  titleTaller!: string;
  token$ = new Subject<void>();
  cantidadTalleres: number = 0;
  produccion: boolean = environment.production;
  constructor(
    public locationService: LocationService,
    private variableService: VariableGlobalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private seoService: SeoService,
    private title: Title,
    private cd: ChangeDetectorRef
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.activatedRoute.snapshot.paramMap.get('token')) {
        if (this.activatedRoute.snapshot.paramMap.get('idAdmin')) {
          sessionStorage.setItem(
            'idAdmin',
            <string>this.activatedRoute.snapshot.paramMap.get('idAdmin')
          );
          sessionStorage.setItem(
            JwtUtils.WEB_TOKEN,
            <string>this.activatedRoute.snapshot.paramMap.get('token')
          );
        } else {
          localStorage.setItem(
            JwtUtils.WEB_TOKEN,
            `${this.activatedRoute.snapshot.paramMap.get('token')}`
          );
        }
        this.router.navigateByUrl('');
      }
    }
  }
  ngOnDestroy(): void {
    this.token$.next();
    this.token$.complete();
  }

  ngOnInit(): void {
    let title: string = 'Red de Talleres Mécanicos y Autolavados | Fullpits';
    this.title.setTitle(title);
    let metaDatos: ConfigMeta = {
      title: 'Red de Talleres Mécanicos y Autolavados | Fullpits',
      description:
        'En Fullpits encuentras todo lo que necesitas para tu vehículo. Mecánica general, cambios de aceite, latonería y pintura, autolavados, motolavados, detailing y ¡mucho más!',
      image:
        'https://fullpits.com/assets/JPG/red-de-talleres-y-autolavados.jpg',
      slug: '',
    };
    this.seoService.generateTags(metaDatos);
    this.seoService.createLinkForCanonicalURL();
    this.variableService.actualToken
      .pipe(takeUntil(this.token$))
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (
            sessionStorage.getItem(JwtUtils.WEB_TOKEN) ||
            localStorage.getItem(JwtUtils.WEB_TOKEN)
          ) {
            JwtUtils.getUserId();
            this.variableService.consultarUsuario();
            this.cd.markForCheck();
          }
        }
      });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TypeLubricant, TypeLubricantService } from 'fullpits-commons';

@Component({
  selector: 'app-seleccionar-tipo-aceite',
  templateUrl: './seleccionar-tipo-aceite.component.html',
  styleUrls: ['./seleccionar-tipo-aceite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeleccionarTipoAceiteComponent implements OnInit, OnDestroy {
  tiposLubricantes: TypeLubricant[] = [];
  lubricante: TypeLubricant;
  constructor(
    private tipoLubricanteService: TypeLubricantService,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SeleccionarTipoAceiteComponent>
  ) {}

  ngOnDestroy(): void {
    if (this.lubricante) {
      this.dialogRef.close(this.lubricante);
    }
  }

  ngOnInit(): void {
    this.traerLubricante();
  }

  cerrarDialog() {
    this.dialogRef.close();
  }

  traerLubricante() {
    this.tipoLubricanteService.getAll().subscribe((resp) => {
      this.tiposLubricantes = resp;
      this.cd.markForCheck();
    });
  }

  seleccionarLubricante() {
    this.dialogRef.close(this.lubricante);
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeServiceService } from 'fullpits-commons';
import { TypeService } from 'fullpits-commons';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-seleccionar-servicios',
  templateUrl: './seleccionar-servicios.component.html',
  styleUrls: ['./seleccionar-servicios.component.scss'],
})
export class SeleccionarServiciosComponent implements OnInit, OnDestroy {
  constructor(
    private tipoServicios: TypeServiceService,
    private dialogRef: MatDialogRef<SeleccionarServiciosComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}
  columns: string[] = ['select', 'nombre'];
  dataSource: MatTableDataSource<TypeService> =
    new MatTableDataSource<TypeService>();
  selection = new SelectionModel<TypeService>(true, []);

  ngOnInit(): void {
    this.crearTabla();
  }

  ngOnDestroy(): void {
    if (this.selection.selected.length > 0) {
      this.data = this.selection.selected;
      this.dialogRef.close(this.data);
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: TypeService) {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id! + 1
    }`;
  }

  crearTabla() {
    this.tipoServicios.getAll().subscribe((servicios: TypeService[]) => {
      this.dataSource = new MatTableDataSource<TypeService>(servicios);
    });
  }

  datosSeleccionados() {
    this.data = this.selection.selected;
    this.dialogRef.close(this.data);
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}

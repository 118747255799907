<div class="container-fluid mt-5">
  <div class="row" *ngIf="servicioFullpits">
    <div class="col-lg-12">
      <h1 class="text-center">
        <strong>¿Que problema tienes con el servicio?</strong>
      </h1>
    </div>
    <div class="col-lg-12">
      <form [formGroup]="formulario" (ngSubmit)="reportarProblema()">
        <div class="form-group">
          <textarea
            cols="30"
            rows="5"
            class="form-control"
            formControlName="observacion"
            placeholder="Describe el inconveniente ocurrido durante el servicio"
          ></textarea>
        </div>
        <div class="form-group my-2">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center gap-4"
          >
            <div class="flex-grow-1">
              <button class="btn btn-negro w-100">Cancelar</button>
            </div>
            <div class="flex-grow-1">
              <button class="btn btn-naranja w-100">Enviar</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="row">
  <ng-container *ngFor="let linea of lineas">
    <div
      class="col-lg-12 p-3 border bg-gris cursor-pointer"
      [id]="linea?.id"
      (click)="datosSeleccionados(linea)"
    >
      {{ linea.name }}
    </div>
  </ng-container>
</div>

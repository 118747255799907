import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DistanciaKilometrosService {
  constructor() {}

  getKilometros(
    latitudTaller: number,
    longitudTaller: number,
    latitudActual: number,
    longitudActual: number
  ): number {
    let rad = (x: number) => {
      return (x * Math.PI) / 180;
    };
    let R = 6378.137; //Radio de la tierra en km
    let dLat = rad(latitudActual - latitudTaller);
    let dLong = rad(longitudActual - longitudTaller);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(latitudTaller)) *
        Math.cos(rad(latitudActual)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return parseFloat(d.toFixed(1)); //Retorna tres decimales
  }
}

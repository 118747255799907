<div class="d-lg-block d-none">
  <div class="d-flex justify-content-end mb-0">
    <div style="position: absolute; top: 1.5vw">
      <button
        class="btn btn-link text-dark slidePrev{{ idBussiness }}"
        style="box-shadow: none"
        aria-label="Atras"
        (click)="slidePrev()"
      >
        <i class="fas fa-chevron-left"></i>
      </button>
      <button
        class="btn btn-link text-dark slideNext{{ idBussiness }}"
        style="box-shadow: none"
        aria-label="Siguiente"
        (click)="slideNext()"
      >
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</div>
<swiper #swiper [config]="config">
  <ng-container *ngFor="let taller of listTalleres; let i = index">
    <ng-template swiperSlide>
      <div class="slide">
        <div
          class="card justify-content-center align-items-center"
          style="border-radius: 0; border: 0"
          [title]="taller.name"
        >
          <div>
            <a
              class="nav-link p-0 text-black"
              [routerLink]="['talleres', taller.id + '-' + slugTalleres[i]]"
              (click)="guardarImagenDefecto(imagenDefault)"
            >
              <img
                class="img-fluid sombra"
                [src]="
                  taller.profilePicture ? taller.profilePicture : imagenDefault
                "
                [alt]="taller.name ? taller.name : 'Taller'"
                style="border-radius: 0.25rem"
                loading="lazy"
              />
            </a>
            <div
              class="d-none mostrar position-absolute z-index-1 sombra px-1 ubicar-corazon"
              style="background-color: rgba(255, 255, 255, 0.8)"
            >
              <div class="nav-link fw-bold text-black p-1">
                <i class="far fa-heart"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-text">
          <div class="card-body p-1 px-md-3">
            <div
              class="informacion-servicio justify-content-center align-items-center"
            >
              <a
                class="nav-link px-0 py-1 text-black"
                [routerLink]="['talleres', taller.id]"
                (click)="guardarImagenDefecto(imagenDefault)"
              >
                <span class="text-color-titulo d-block">{{ taller.name }}</span>
              </a>
              <ng-container *ngIf="taller.qualification! > 0">
                <div
                  class="informacion-servicio-detalles justify-content-center justify-content-md-start align-items-center"
                >
                  <!-- INICIA ESTRELLAS -->
                  <div class="d-md-block d-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 0.3 &&
                          taller.qualification! <= 0.7;
                        else nuevaValidacion1
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 22px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion1>
                      <ng-container
                        *ngIf="taller.qualification! >= 0.8; else vacio1"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio1>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-md-block d-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 1.3 &&
                          taller.qualification! <= 1.7;
                        else nuevaValidacion2
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 22px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion2>
                      <ng-container
                        *ngIf="taller.qualification! >= 1.8; else vacio2"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio2>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-md-block d-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 2.3 &&
                          taller.qualification! <= 2.7;
                        else nuevaValidacion3
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 22px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion3>
                      <ng-container
                        *ngIf="taller.qualification! >= 2.8; else vacio3"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio3>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-md-block d-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 3.3 &&
                          taller.qualification! <= 3.7;
                        else nuevaValidacion4
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 22px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion4>
                      <ng-container
                        *ngIf="taller.qualification! >= 3.8; else vacio4"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio4>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-md-block d-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 4.3 &&
                          taller.qualification! <= 4.7;
                        else nuevaValidacion5
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 22px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion5>
                      <ng-container
                        *ngIf="taller.qualification! >= 4.8; else vacio4"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio4>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 22px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <!-- FIN ESTRELLAS -->
                  <!-- INICIA ESTRELLAS MOVIL -->
                  <div class="d-block d-md-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 0.3 &&
                          taller.qualification! <= 0.7;
                        else nuevaValidacion1movil
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 15px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion1movil>
                      <ng-container
                        *ngIf="taller.qualification! >= 0.8; else vacio1movil"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio1movil>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-block d-md-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 1.3 &&
                          taller.qualification! <= 1.7;
                        else nuevaValidacion2movil
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 15px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion2movil>
                      <ng-container
                        *ngIf="taller.qualification! >= 1.8; else vacio2movil"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio2movil>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-block d-md-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 2.3 &&
                          taller.qualification! <= 2.7;
                        else nuevaValidacion3movil
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 15px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion3movil>
                      <ng-container
                        *ngIf="taller.qualification! >= 2.8; else vacio3movil"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio3movil>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-block d-md-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 3.3 &&
                          taller.qualification! <= 3.7;
                        else nuevaValidacion4movil
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 15px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion4movil>
                      <ng-container
                        *ngIf="taller.qualification! >= 3.8; else vacio4movil"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio4movil>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <div class="d-block d-md-none">
                    <ng-container
                      *ngIf="
                        taller.qualification! >= 4.3 &&
                          taller.qualification! <= 4.7;
                        else nuevaValidacion5movil
                      "
                    >
                      <img
                        src="./assets/images/home/media-estrella.webp"
                        alt="media-estrella"
                        style="width: 15px"
                        loading="lazy"
                      />
                    </ng-container>
                    <ng-template #nuevaValidacion5movil>
                      <ng-container
                        *ngIf="taller.qualification! >= 4.8; else vacio4movil"
                      >
                        <img
                          src="./assets/images/home/estrella-completa.webp"
                          alt="estrella-completa"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-container>
                      <ng-template #vacio4movil>
                        <img
                          src="./assets/images/home/estrella-vacia.webp"
                          alt="estrella-vacia"
                          style="width: 15px"
                          loading="lazy"
                        />
                      </ng-template>
                    </ng-template>
                  </div>
                  <!-- FIN ESTRELLAS MOVIL -->
                  <div>
                    <span class="text-color ms-1">{{
                      taller.qualification! | number: "1.1-1"
                    }}</span>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="taller.esCercano; else verDistancia">
                <div
                  class="cursor-pointer nav-link p-0 text-center text-dark mt-0 mt-md-2 text-kilometros"
                  (click)="abrirDialog()"
                >
                  A {{ taller.distancia }} de tu ubicación
                </div>
              </ng-container>
              <ng-template #verDistancia>
                <ng-container
                  *ngIf="taller.esCercano === false; else noUbicacionActual"
                >
                  <div
                    class="cursor-pointer nav-link p-0 text-center text-dark mt-0 mt-md-2 text-kilometros"
                    (click)="abrirDialog()"
                  >
                    Está a mas de {{ distancia }}km de tu ubicación
                  </div>
                </ng-container>
                <ng-template #noUbicacionActual>
                  <a
                    class="cursor-pointer text-center text-dark mt-0 mt-md-2 text-kilometros"
                    (click)="abrirDialog()"
                  >
                    Ver distancia
                  </a>
                </ng-template>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</swiper>

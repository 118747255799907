<div class="container-fluid mt-5">
  <div class="row" *ngIf="fullpit">
    <div class="col-lg-12">
      <h1 class="text-center">
        <strong>¿Que problema tienes con el servicio?</strong>
      </h1>
    </div>
    <div class="col-lg-12">
      <form
        [formGroup]="reprogramarServicioForm"
        (ngSubmit)="reprogramarServicio()"
      >
        <div class="form-group">
          <input
            type="datetime-local"
            min="2022-01-01"
            name="dateScheduled"
            id="dateScheduled"
            formControlName="dateScheduled"
            class="form-control"
            placeholder="Fecha"
          />
        </div>
        <div class="form-group my-2">
          <div
            class="d-flex justify-content-between flex-wrap align-items-center gap-4"
          >
            <div class="flex-grow-1">
              <button
                class="btn btn-naranja w-100"
                type="submit"
                [disabled]="reprogramarServicioForm.invalid"
              >
                Reprogramar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <div
      *ngIf="installEvent"
      class="toast position-fixed bg-light bottom-0 start-50 translate-middle-x show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      style="z-index: 1"
    >
      <div class="toast-body">
        Instalar app a mi Home Screen.
        <div class="mt-2 pt-2 border-top">
          <button
            type="button"
            class="btn btn-secondary btn-sm me-2"
            data-bs-dismiss="toast"
          >
            Ahora no
          </button>
          <button
            type="button"
            class="btn btn-naranja rounded btn-sm"
            data-bs-dismiss="toast"
            (click)="installByUser()"
          >
            Instalar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

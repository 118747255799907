import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LocationService } from 'src/app/core/shared/services/location.service';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';

@Component({
  selector: 'app-ubicacion-actual',
  templateUrl: './ubicacion-actual.component.html',
  styleUrls: ['./ubicacion-actual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UbicacionActualComponent implements OnInit, OnDestroy {
  direccionActual$ = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<UbicacionActualComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private variableGlobal: VariableGlobalService,
    private locationService: LocationService,
    @Inject(PLATFORM_ID) private platformId: any,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.direccionActual$.next();
    this.direccionActual$.complete();
  }

  ngOnInit(): void {}

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  handleAddressChange(address: Address) {
    if (isPlatformBrowser(this.platformId)) {
      sessionStorage.setItem('direccion', address.formatted_address);
      sessionStorage.setItem(
        'ubicacion',
        JSON.stringify({
          latitud: address.geometry.location.lat(),
          longitud: address.geometry.location.lng(),
        })
      );
    }
    this.variableGlobal.consultarDireccion();
    this.variableGlobal.consultarUbicacion();
    this.dialogRef.close();
  }
  ubicacionActualGPS() {
    if (isPlatformBrowser(this.platformId)) {
      navigator.geolocation.getCurrentPosition((coords) => {
        if (isPlatformBrowser(this.platformId)) {
          sessionStorage.setItem(
            'ubicacion',
            JSON.stringify({
              latitud: coords.coords.latitude,
              longitud: coords.coords.longitude,
            })
          );
        }
        let url =
          'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
          coords.coords.latitude +
          ',' +
          coords.coords.longitude +
          '&key=AIzaSyBPhIn_vKKUhN1BeVahhjnvABroRmpyJPc';

        this.locationService
          .direccionUbicacionActual(url)
          .pipe(takeUntil(this.direccionActual$))
          .subscribe((resp: any) => {
            if (isPlatformBrowser(this.platformId)) {
              sessionStorage.setItem(
                'direccion',
                resp.results[0].formatted_address
              );
            }
            this.variableGlobal.consultarDireccion();
            this.variableGlobal.consultarUbicacion();
            this.dialogRef.close();
            this.cd.markForCheck();
          });
      });
    }
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Colores from '../../../../../assets/json/menu-colores.json';
import { Color } from '../../../../models/color';

@Component({
  selector: 'app-color-vehiculo',
  templateUrl: './color-vehiculo.component.html',
  styleUrls: ['./color-vehiculo.component.scss'],
})
export class ColorVehiculoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ColorVehiculoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  colores: Color[] = Colores;

  ngOnInit(): void {}

  datosSeleccionados(color: Color) {
    this.data = color;
    this.dialogRef.close(this.data);
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VariableGlobalService } from 'src/app/core/shared/services/variable-global.service';
import { MenuFooter } from 'src/app/models/menuFooter';
import menuFooterJSON from '../../../../../assets/json/menu-footer.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  menuJson: MenuFooter[] = menuFooterJSON;
  isChat: boolean = false;
  isChat$ = new Subject<void>();
  constructor(
    private variableGlobalService: VariableGlobalService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.variableGlobalService.actualOcultarFooter
      .pipe(takeUntil(this.isChat$))
      .subscribe((resp) => {
        this.isChat = resp;
        this.cd.markForCheck();
      });
  }
}

import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Person, Fullpit } from 'fullpits-commons';
import dayjs from 'dayjs';
import { CurrencyPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AsignarPersonaServicioComponent } from '../../../../modulos/dialog/asignar-persona-servicio/asignar-persona-servicio.component';
import { Subject } from 'rxjs';
import { AsignaPersona } from '../../../../models/asigna-persona';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-menu-chat',
  templateUrl: './menu-chat.component.html',
  styleUrls: ['./menu-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
})
export class MenuChatComponent implements OnInit, OnDestroy {
  @Input() persona: Person;
  @Input() servicioFullpit: Fullpit;
  linkArmado: string = '';
  asignaPersona$ = new Subject<void>();
  constructor(
    private currencyPipe: CurrencyPipe,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    this.asignaPersona$.next();
    this.asignaPersona$.complete();
  }

  ngOnInit(): void {
    setTimeout(() => {}, 1000);
  }

  goToWhatsapp() {
    if (this.persona && this.persona.phoneNumber) {
      let url = `https://api.whatsapp.com/send?phone=57${this.persona.phoneNumber}`;
      window.open(url, '_blank');
    }
  }

  armarServiciosParaLinkWhatsapp() {
    let servicios: string = '';
    this.servicioFullpit.serviceList?.forEach((services, index) => {
      if (this.servicioFullpit.serviceList!.length > 1 && index > 0) {
        servicios +=
          index + 1 === this.servicioFullpit.serviceList?.length
            ? '%0A'
            : '%0A';
      }
      servicios += '%2A' + services.title + '%2A';
    });
    return servicios.replace(/ /g, '+');
  }

  getLinkWaze() {
    return `https://www.waze.com/ul?ll=${this.servicioFullpit.workshop?.latitude}%2C${this.servicioFullpit.workshop?.longitude}%26navigate=yes%26zoom=17`;
  }

  getLinkGoogle() {
    return `https://www.google.com/maps/dir/?api=1%26destination=${this.servicioFullpit.workshop?.latitude},${this.servicioFullpit.workshop?.longitude}`;
  }

  crearLinkWhatsappParaTaller() {
    let texto = `¡Hola+${
      this.persona.nombres
    }!%0A%0ACompartimos+contigo+los+detalles+del+servicio+que+tenemos+agendado+para+ti+en+una+de+nuestras+sedes+-+%2AFullpits%2A.+🚗%0A%0A%2A${this.servicioFullpit.workshop?.name
      ?.replace(/&/g, '%26')
      .replace(
        / /g,
        '+'
      )}%2A%0A%2A${this.armarServiciosParaLinkWhatsapp()}%2A%0A➡️+${this.servicioFullpit.workshop?.address
      ?.replace(/#/g, 'No')
      .replace(/ /g, '+')}%0A📆+${dayjs(this.servicioFullpit.dateScheduled)
      .format('DD/MM/YYYY')
      .replace(/ /g, '+')}%0A🕗+${dayjs(this.servicioFullpit.dateScheduled)
      .format('HH:mm a')
      .replace(
        / /g,
        '+'
      )}%0A💵+Total+del+servicio:+${this.currencyPipe.transform(
      this.servicioFullpit.total,
      'COP$'
    )}%0A💵+Abonado+${this.currencyPipe.transform(
      this.servicioFullpit.adelanto,
      'COP$'
    )}%0A💵+Por+pagar+${this.currencyPipe.transform(
      this.servicioFullpit.total! - this.servicioFullpit.adelanto!,
      'COP$'
    )}%0A%0A¿Cómo+llegar?%0A📍+%2AWaze:%2A+${this.getLinkWaze()}%0A📍+%2AGoogleMaps:%2A+${this.getLinkGoogle()}%0A%0ATe+esperamos+con+todo+el+gusto+para+atenderte.+😊%0A%0A%2ARed+de+talleres+y+autolavados%2A+https://fullpits.com/%0ATenemos+todo+lo+que+necesites+para+tu+vehículo.`;
    this.linkArmado = `https://api.whatsapp.com/send/?phone=57${this.persona.phoneNumber}&text=${texto}&type=phone_number&app_absent=0`;
    window.open(this.linkArmado, '_blank');
  }

  crearLinkWhatsappDomicilio(personaEncargada: string) {
    let texto = `¡Hola+${
      this.persona.nombres
    }!%0A%0ACompartimos+contigo+los+detalles+del+servicio+que+tenemos+agendado+para+ti+-+%2AFullpits%2A.+🚗%0A%0ALa+persona+encargada+de+realizar+el+trabajo+se+llama+%2A${personaEncargada
      .trim()
      .replace(
        / /g,
        '+'
      )}%2A%0A%0A${this.armarServiciosParaLinkWhatsapp()}.+🚗%0A%0A💵+Total+del+servicio:+${this.currencyPipe.transform(
      this.servicioFullpit.total,
      'COP$'
    )}%0A💵+Estado de pago: ${
      this.servicioFullpit.paymentMethod?.id === 1 ? 'Pendiente' : 'Pagado'
    }%0A%0A➡️+${this.servicioFullpit.userAddress
      ?.replace(/#/g, 'No')
      .replace(/ /g, '+')}.%0A📆+${dayjs(this.servicioFullpit.dateScheduled)
      .format('DD/MM/YYYY')
      .replace(/ /g, '+')}%0A🕙+${dayjs(this.servicioFullpit.dateScheduled)
      .format('hh:mm a')
      .replace(
        / /g,
        '+'
      )}%0A%0A%2ALimpieza y CarDetailing a domicilio%2A+https://fullpits.com/%0AMantenimiento y embellecimiento de vehículos`;
    this.linkArmado = `https://api.whatsapp.com/send/?phone=57${this.persona.phoneNumber}&text=${texto}&type=phone_number&app_absent=0`;
    window.open(this.linkArmado, '_blank');
  }

  crearLinkWhatsappTerminado() {
    let texto = `¡Hola+${this.persona.nombres}!%0A%0APara+nosotros+es+muy+importante,+conocer+la+experiencia+y+la+calidad+del+servicio+que+se+te+prestó.%0A%0A%2A¿Nos+podrías+regalar+un+comentario+y+una+calificación+por+el+servicio+entre+1+y+5+(Siendo+5+la+mejor+calificación)?%2A%0A%0A%2A%2A_La+calificación+y+el+comentario,+serán+publicados+a+tu+nombre+en+las+calificaciones+del+taller+en+fullpits.com+para+ayudar+a+más+usuarios+a+tomar+un+buen+servicio_`;
    this.linkArmado = `https://api.whatsapp.com/send/?phone=57${this.persona.phoneNumber}&text=${texto}&type=phone_number&app_absent=0`;
    window.open(this.linkArmado, '_blank');
  }

  abrirDialogAsignado() {
    this.dialog
      .open<AsignarPersonaServicioComponent, any, AsignaPersona>(
        AsignarPersonaServicioComponent,
        {
          width: '400px',
          height: '400px',
        }
      )
      .afterClosed()
      .toPromise()
      .then((resp) => {
        this.crearLinkWhatsappDomicilio(resp!.nombre);
      })
      .catch((error) => {
        this.toastrService.error(error);
      });
    this.cd.markForCheck();
  }
}

import { Inject, PLATFORM_ID } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { isPlatformBrowser } from '@angular/common';

export class JwtUtils {
  public static readonly WEB_TOKEN = 'webToken';

  static validarToken() {
    let token = String(
      sessionStorage.getItem(this.WEB_TOKEN)
        ? sessionStorage.getItem(this.WEB_TOKEN)
        : localStorage.getItem(this.WEB_TOKEN)
    );
    let decoded: any;
    try {
      return (decoded = jwt_decode(token));
    } catch (error) {
      return (decoded = {
        message: 'No tiene un token valido',
        user: { id: null },
      });
    }
  }
  static getUserId() {
    let decoded = this.validarToken();
    if (!decoded.message) {
      if (sessionStorage.getItem('idAdmin')) {
        sessionStorage.setItem('user', JSON.stringify(decoded));
      } else {
        localStorage.setItem('user', JSON.stringify(decoded));
      }
    } else {
      if (localStorage.getItem('user')) {
        localStorage.removeItem('user');
      }
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { Action, DocumentSnapshot } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { EFullpitStateNotification } from 'fullpits-commons';
import { Fullpit } from 'fullpits-commons';
import { NotificationMessage } from 'fullpits-commons';
import { FullpitFirebaseService } from 'src/app/core/shared/services/fullpit-firebase.service';
import {
  FullpitFirestore,
  FullpitService,
  NotificationService,
  Taller,
} from 'fullpits-commons';
import { PushNotificationService } from 'src/app/core/shared/services/push-notification.service';
import { Chat } from 'src/app/models/chat';

declare var $: any;

@Component({
  selector: 'app-tomar-foto-webcam',
  templateUrl: './tomar-foto-webcam.component.html',
  styleUrls: ['./tomar-foto-webcam.component.scss'],
})
export class TomarFotoWebcamComponent implements OnInit {
  errors: WebcamInitError[] = [];
  imagenWebcam: WebcamImage;
  restablecerImagen: WebcamImage;
  mensaje: Chat;
  trigger: Subject<void> = new Subject<void>();
  file: File;
  tokenFirebase: string | null;
  propuestaColeccion: FullpitFirestore;
  notificationMessage: NotificationMessage;
  constructor(
    private fullpitsFirebase: FullpitFirebaseService,
    private dialogRef: MatDialogRef<TomarFotoWebcamComponent>,
    private pushNotification: PushNotificationService,
    private notificacion: NotificationService,
    private fullpitFirebaseService: FullpitFirebaseService,
    private fullpitService: FullpitService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.permisosDeNotificacion();
  }

  permisosDeNotificacion() {
    this.pushNotification.getToken().subscribe((resp) => {
      if (resp === 'granted') {
        this.pushNotification.getToken().subscribe((token) => {
          this.tokenFirebase = token;
        });
      }
    });
  }

  traerPropuestas() {
    if (this.propuestaColeccion === undefined) {
      this.fullpitFirebaseService
        .traerPropuestas(this.data.propuesta)
        .subscribe((resp: Action<DocumentSnapshot<any>>) => {
          this.propuestaColeccion = resp.payload.data();
          this.fullpitService
            .getById(parseInt(`${this.propuestaColeccion.id}`))
            .subscribe((fullpit: Fullpit) => {
              let fechaCreada: any = this.propuestaColeccion.dateCreated;
              this.propuestaColeccion.dateCreated = moment
                .unix(fechaCreada.seconds)
                .toDate();
              if (this.propuestaColeccion.propuestas?.length === 0) {
                this.propuestaColeccion.propuestas.push(<any>fullpit.workshop);
              }
              this.enviarNotificacion();
            });
        });
    }
  }

  enviarNotificacion() {
    this.propuestaColeccion.propuestas?.forEach((propuesta) => {
      let propuestaProgramada: Taller = <any>propuesta;
      if (
        propuesta.idWorkShop == this.data.taller ||
        propuestaProgramada.id == this.data.taller
      ) {
        this.notificationMessage = {
          whatsappNotificationData: {
            body: 'enviado una imagen',
            subTitle:
              this.propuestaColeccion.person.nombres +
              ' ' +
              this.propuestaColeccion.person.apellidos,
            title: 'Nuevo mensaje',
            templateName: 'taller_notificacion',
          },
          title:
            this.propuestaColeccion.person.nombres +
            ' ' +
            this.propuestaColeccion.person.apellidos,
          body: 'Foto',
          workshopId: propuesta.idWorkShop!,
          receiverType: EFullpitStateNotification.WORKSHOP,
          token: propuesta.firebaseMessagingToken!,
          data: {
            title:
              this.propuestaColeccion.person.nombres +
              ' ' +
              this.propuestaColeccion.person.apellidos,
            body: 'Foto',
            idService: this.propuestaColeccion.id,
            personId: this.propuestaColeccion.person.id,
            navegacion: '1',
            roomChatId: `${this.data.chat}`,
            tokenAdmin: `${this.tokenFirebase}`,
            tokenWorkShop: propuesta.firebaseMessagingToken,
            workshop: JSON.stringify(this.propuestaColeccion),
          },
        };
      }
    });
    this.notificacion.save(this.notificationMessage).subscribe();
  }

  triggerCaptura() {
    this.trigger.next();
  }

  restablecerImagenWebcam() {
    this.imagenWebcam = this.restablecerImagen;
  }

  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }

  handleImage(imagenWebcam: WebcamImage) {
    this.imagenWebcam = imagenWebcam;
  }
  get triggerObservable() {
    return this.trigger.asObservable();
  }
  guardarFoto() {
    this.mensaje = {
      audio: false,
      image: true,
      message: '',
      roomChatId: null,
      timeStamp: new Date(),
      user: true,
      userId: `${this.data.id}`,
    };
    this.file = this.dataURLtoFile(
      this.imagenWebcam.imageAsDataUrl,
      'image.jpeg'
    );
    this.fullpitsFirebase
      .uploadImage(this.mensaje, this.file, this.data.chat)
      .subscribe(() => {
        this.traerPropuestas();
        this.bajarScrollChat();
        this.dialogRef.close();
      });
  }
  dataURLtoFile(dataurl: string, filename: string) {
    let arr: any = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  bajarScrollChat() {
    //Fijo el scroll al fondo
    $('#contenedorChat').animate(
      { scrollTop: $('#contenedorChat').prop('scrollHeight') },
      1000
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestCountriesInterface } from 'src/app/models/rest-countries';

@Injectable({
  providedIn: 'root',
})
export class RestCountriesService {
  private url = 'https://restcountries.com/v3.1';

  constructor(private http: HttpClient) {}

  getAllCountries() {
    return this.http.get<RestCountriesInterface[]>(`${this.url}/all`);
  }
}

import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Workshop, WorkshopsService } from 'fullpits-commons';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-asignar-persona-servicio',
  templateUrl: './asignar-persona-servicio.component.html',
  styleUrls: ['./asignar-persona-servicio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsignarPersonaServicioComponent implements OnInit {
  formulario: UntypedFormGroup;
  operators: Workshop[];
  myControl = new FormControl('', [Validators.required]);
  filteredOperators: Observable<Workshop[]>;
  constructor(
    private dialogRef: MatDialogRef<AsignarPersonaServicioComponent>,
    private fb: UntypedFormBuilder,
    private workshopService: WorkshopsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.crearFormulario();
    this.getOperators();
  }

  getOperators() {
    this.workshopService.getActivePaginated(0, 0).subscribe(
      (resp) => {
        this.operators = resp;
        this.getFilteredOptions();
        this.cd.markForCheck();
      },
      (error) => {
        alert(error);
      }
    );
  }

  getFilteredOptions() {
    this.filteredOperators = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value!))
    );
  }

  private _filter(value: string): Workshop[] {
    const filterValue = value.toLowerCase();

    return this.operators.filter((operator) =>
      operator.name!.toLowerCase().includes(filterValue)
    );
  }

  crearFormulario() {
    this.formulario = this.fb.group({
      nombre: this.myControl,
    });
  }

  enviarAsignado() {
    if (this.formulario.invalid) {
      return;
    }
    this.dialogRef.close(this.formulario.value);
  }
}

import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fullpit, FullpitService, PaymentMethod } from 'fullpits-commons';
import MetodosPagoJSON from '../../../../assets/json/metodos-de-pago.json';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ResponseFormFullpitTerminados } from '../../../models/response-form-fullpit-terminados';
declare var $: any;

@Component({
  selector: 'app-dialog-terminar-servicio',
  templateUrl: './dialog-terminar-servicio.component.html',
  styleUrls: ['./dialog-terminar-servicio.component.scss'],
})
export class DialogTerminarServicioComponent implements OnInit {
  terminarServicioForm: UntypedFormGroup;
  metodosPago: PaymentMethod[] = MetodosPagoJSON;
  fullpits: Fullpit;
  indexPaymentMethod: number = 0;
  constructor(
    private dialogRef: MatDialogRef<
      DialogTerminarServicioComponent,
      ResponseFormFullpitTerminados
    >,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.fullpits = this.data.fullpit;
    this.crearFormulario();
    this.initTotal();
  }

  initTotal() {
    this.terminarServicioForm.controls['total'].setValue(this.fullpits.total);
  }
  // Mensajes invalidos
  get messageTotal() {
    return this.terminarServicioForm.get('total')?.errors;
  }
  get messagePaymentMethod() {
    return this.terminarServicioForm.get('paymentMethod')?.errors;
  }
  // campos invalidos
  get invalidTotal() {
    return (
      this.terminarServicioForm.get('total')?.invalid &&
      this.terminarServicioForm.get('total')?.touched
    );
  }

  get invalidPaymentMethod() {
    return (
      this.terminarServicioForm.get('paymentMethod')?.invalid &&
      this.terminarServicioForm.get('paymentMethod')?.touched
    );
  }

  // Campos validos
  get validTotal() {
    return this.terminarServicioForm.get('total')?.valid;
  }

  get validPaymentMethod() {
    return this.terminarServicioForm.get('paymentMethod')?.valid;
  }

  crearFormulario() {
    this.terminarServicioForm = this.fb.group({
      total: ['', [Validators.required, Validators.min(1000)]],
      paymentMethod: [this.fullpits.paymentMethod || '', Validators.required],
      additionalServices: [''],
    });
  }
  terminarServicio() {
    if (this.terminarServicioForm.invalid) {
      return Object.values(this.terminarServicioForm.controls).forEach(
        (control) => {
          control.markAsTouched();
        }
      );
    }
    this.dialogRef.close(this.terminarServicioForm.value);
  }

  resetForm() {
    this.terminarServicioForm.reset();
  }
}

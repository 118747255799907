import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-opcion-solicitud',
  templateUrl: './dialog-opcion-solicitud.component.html',
  styleUrls: ['./dialog-opcion-solicitud.component.scss'],
})
export class DialogOpcionSolicitudComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogOpcionSolicitudComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {}
  cerrarDialog() {
    this.dialogRef.close();
  }

  seleccionaOpcion(opcion: boolean) {
    if (opcion) {
      this.dialogRef.close(opcion);
    } else {
      this.dialogRef.close(opcion);
    }
  }
}

import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Solicitar } from 'src/app/models/solicitar-servicios';
import { SeleccionarServicioUnicoComponent } from 'src/app/modulos/dialog/servicio-unico/seleccionar-servicio-unico/seleccionar-servicio-unico.component';
import { SeleccionarTipoAceiteComponent } from 'src/app/modulos/dialog/servicio-unico/seleccionar-tipo-aceite/seleccionar-tipo-aceite.component';
import { SeleccionarVehiculoUnicoComponent } from 'src/app/modulos/dialog/servicio-unico/seleccionar-vehiculo-unico/seleccionar-vehiculo-unico.component';
import { UbicacionActualComponent } from 'src/app/modulos/dialog/ubicacion-actual/ubicacion-actual.component';
import {
  Fullpit,
  Person,
  Servicio,
  PersonaService,
  TypeService,
  TypeLubricant,
  Vehiculo,
  Workshop,
  PaymentMethod,
} from 'fullpits-commons';
import { SnackbarService } from '../../services/snackbar.service';
import { VariableGlobalService } from '../../services/variable-global.service';
import { JwtUtils } from '../../utils/jwt-utils';
import dayjs from 'dayjs';
import { EFullpitState } from 'fullpits-commons';
import { PushNotificationService } from '../../services/push-notification.service';
import {
  FullpitService,
  WorkshopsService,
  NotificationMessage,
} from 'fullpits-commons';
import { environment } from 'src/environments/environment';
import { ECategoryService } from 'fullpits-commons';
import { TypeVehicleService, VehiculoService } from 'fullpits-commons';
import { BonoFidelizacionService } from '../../services/bono-fidelizacion.service';
import { Acumulable } from 'src/app/models/acumulable';
import { FullpitFirestore } from 'fullpits-commons';
import { SelectPaymentMethodComponent } from 'src/app/modulos/dialog/select-payment-method/select-payment-method.component';
import { Constantes } from '../../utils/constantes';

declare var $: any;
@Component({
  selector: 'app-solicitar-servicio-unico',
  templateUrl: './solicitar-servicio-unico.component.html',
  styleUrls: ['./solicitar-servicio-unico.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolicitarServicioUnicoComponent implements OnInit, OnDestroy {
  @Input() idTaller: string = '';
  @Output() usuario = new EventEmitter<string>();
  vehiculoDialog$ = new Subject<void>();
  servicioDialog$ = new Subject<void>();
  tipoAceiteDialog$ = new Subject<void>();
  guardarUbicacion$ = new Subject<void>();
  guardarDireccion$ = new Subject<void>();
  serviciosFullpit$ = new Subject<void>();
  paymentMethodDestroy$ = new Subject<void>();
  dialogUbicacionActual$ = new Subject<void>();
  vehiculoSeleccionado: Vehiculo;
  vehiculosSeleccionados: Vehiculo[] = [];
  servicioSeleccionado: Servicio[] = [];
  lubricanteSeleccionado: TypeLubricant;
  etiquetaServicioSeleccionado: Servicio[] = [];
  cambioAceiteSeleccionado: boolean = false;
  datosTaller: Workshop;
  servicioPrestado: string = '';
  boolDomicilio: boolean = false;
  boolTaller: boolean = false;
  latitud: number;
  longitud: number;
  textoUbicacion: string = '';
  homeService: boolean;
  comentario: string = '';
  fecha: string = '';
  hora: string = '';
  minimaFecha: string = '';
  solicitarServicio: Solicitar;
  inactivarBotonSolicitar: boolean = false;
  errorSolicitud = {
    vehiculo: false,
    servicio: false,
    lubricante: false,
    homeService: false,
    ubicacion: false,
  };
  fullpit: Fullpit;
  fullpitFirestore: FullpitFirestore;
  persona: Person[] = [];
  tokenFirebase: string = '';
  notificacion: NotificationMessage;
  saldoDisponible: Acumulable;
  saldoBool: boolean = true;
  paymentMethodSelected: PaymentMethod;
  textPaymentMethod: string = Constantes.TEXT_PAYMENT_METHOD;
  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private tallerService: WorkshopsService,
    private variableGlobal: VariableGlobalService,
    @Inject(PLATFORM_ID) private platformId: any,
    private snackBarService: SnackbarService,
    private personaService: PersonaService,
    private pushNotification: PushNotificationService,
    private fullpitService: FullpitService,
    private vehiculoService: VehiculoService,
    private typeVehicleService: TypeVehicleService,
    private bonoFidelizacionService: BonoFidelizacionService
  ) {}
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('servicio')) {
        localStorage.removeItem('servicio');
      }
      if (
        this.vehiculoSeleccionado &&
        localStorage.getItem('campos-solicitar')
      ) {
        localStorage.removeItem('campos-solicitar');
      }
    }
    this.vehiculoDialog$.next();
    this.vehiculoDialog$.complete();
    this.servicioDialog$.next();
    this.servicioDialog$.complete();
    this.dialogUbicacionActual$.next();
    this.dialogUbicacionActual$.complete();
    this.serviciosFullpit$.next();
    this.serviciosFullpit$.complete();
    this.paymentMethodDestroy$.next();
    this.paymentMethodDestroy$.complete();
  }

  ngOnInit(): void {
    this.traerServicioAlInicializar();
    this.traerTaller();
    this.traerDireccion();
    this.traerPersona();
    this.generarTokenNotificacion();
    this.scrollInicio();
    this.initPaymentMethod();
    // this.traerSaldoDisponible();
    this.minimaFecha = dayjs().format('YYYY-MM-DD');
  }

  initPaymentMethod() {
    const paymentMethod: PaymentMethod = {
      id: 1,
      name: 'Efectivo',
    };
    this.paymentMethodSelected = paymentMethod;
  }
  traerServicioAlInicializar() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('servicio')) {
        let servicio: Servicio = JSON.parse(
          <string>localStorage.getItem('servicio')
        );
        if (
          !this.servicioSeleccionado.find(
            (find) => find.id === servicio.typeService!.id
          )
        ) {
          this.etiquetaServicioSeleccionado.push(servicio);
          this.servicioSeleccionado.push(servicio);
        }
        if (this.servicioSeleccionado.find((find) => find.id === 4)) {
          this.cambioAceiteSeleccionado = true;
        }
      }
    }
  }
  traerVehiculoDespuesDeRegistrar() {
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('add-vehicle')) {
        forkJoin({
          vehiculosPersona: this.vehiculoService.getByPersonId(
            this.persona[0].id!
          ),
        })
          .pipe(
            map((resp) => {
              let vehiculos: Vehiculo[] = resp.vehiculosPersona;
              return vehiculos[vehiculos.length - 1];
            })
          )
          .subscribe((vehiculo: Vehiculo) => {
            this.typeVehicleService
              .getByWorkShopId(parseInt(this.idTaller))
              .subscribe((resp) => {
                let existeTipoVehiculo = resp.find(
                  (filtro) => filtro.id === vehiculo.typeVehicle?.id
                );
                if (!existeTipoVehiculo) {
                  this.snackBarService.mostrarSnackBar(
                    'Este taller no atiende este tipo de vehiculo'
                  );
                  return;
                }
                this.vehiculoSeleccionado = vehiculo;
                this.vehiculoSeleccionado.fuel!.id = parseInt(
                  vehiculo.fuel!.id!.toString()
                );
                this.cd.markForCheck();
                if (isPlatformBrowser(this.platformId)) {
                  sessionStorage.removeItem('add-vehicle');
                }
              });
          });
      }
      if (localStorage.getItem('campos-solicitar')) {
        this.solicitarServicio = JSON.parse(
          <string>localStorage.getItem('campos-solicitar')
        );
        this.lubricanteSeleccionado = this.solicitarServicio.lubricante;
        this.servicioSeleccionado = this.solicitarServicio.servicio;
        this.comentario = this.solicitarServicio.comentario;
        this.homeService = this.solicitarServicio.homeService;
        this.fecha = this.solicitarServicio.fecha;
        this.hora = this.solicitarServicio.hora;
        this.cambioAceiteSeleccionado = <boolean>(
          this.solicitarServicio.cambioAceite
        );
        this.etiquetaServicioSeleccionado = <Servicio[]>(
          this.solicitarServicio.etiquetaServicio
        );
      }
    }
  }
  generarTokenNotificacion() {
    this.pushNotification.requestPermission().subscribe((resp) => {
      if (resp === 'granted') {
        this.pushNotification.getToken().subscribe((token) => {
          this.tokenFirebase = <string>token;
        });
      }
    });
  }

  validarToken() {
    if (isPlatformBrowser(this.platformId)) {
      if (JwtUtils.validarToken().message) {
        this.snackBarService.mostrarSnackBar(JwtUtils.validarToken().message);
        if (isPlatformBrowser(this.platformId)) {
          location.href = environment.urlLogin;
        }
        return;
      }
      return JwtUtils.validarToken();
    }
  }

  traerPersona() {
    this.personaService
      .getByUserId(this.validarToken().user.id)
      .subscribe((resp) => {
        this.persona = resp;
        if (this.persona && this.persona.length) {
          this.usuario.emit(
            this.persona[0].nombres + ' ' + this.persona[0].apellidos
          );
        }

        this.traerVehiculoDespuesDeRegistrar();
      });
  }

  traerSaldoDisponible() {
    this.bonoFidelizacionService
      .traerAcumuladoDisponible(this.validarToken().user.id)
      .subscribe((resp) => {
        this.saldoDisponible = <Acumulable>resp.payload.data();
        this.cd.markForCheck();
      });
  }

  seleccionarVehiculo() {
    if (isPlatformBrowser(this.platformId)) {
      this.camposSolicitar();
      localStorage.setItem(
        'campos-solicitar',
        JSON.stringify({
          ...this.solicitarServicio,
          etiquetaServicio: this.etiquetaServicioSeleccionado,
          cambioAceite: this.cambioAceiteSeleccionado,
        })
      );
    }
    const vehiculoDialog = this.dialog.open(SeleccionarVehiculoUnicoComponent, {
      width: '400px',
      height: '400px',
      data: this.idTaller,
    });
    vehiculoDialog
      .afterClosed()
      .pipe(takeUntil(this.vehiculoDialog$))
      .subscribe((resp) => {
        if (isPlatformBrowser(this.platformId)) {
          if (localStorage.getItem('campos-solicitar')) {
            localStorage.removeItem('campos-solicitar');
          }
        }
        if (resp) {
          this.vehiculoSeleccionado = resp;
          this.vehiculoSeleccionado.fuel!.id = parseInt(resp.fuel.id);
        }

        this.cd.markForCheck();
      });
  }

  seleccionarServicios() {
    const servicioDialog = this.dialog.open(SeleccionarServicioUnicoComponent, {
      width: '800px',
      height: '600px',
      data: this.idTaller,
    });
    servicioDialog
      .afterClosed()
      .pipe(takeUntil(this.servicioDialog$))
      .subscribe((resp) => {
        if (resp) {
          for (const re of resp) {
            this.etiquetaServicioSeleccionado.push(re);
            this.servicioSeleccionado.push(re);
          }
        }
        this.cd.markForCheck();
      });
  }
  eliminarServicioSeleccionado(index: number) {
    if (this.servicioSeleccionado[index].id === 4) {
      this.cambioAceiteSeleccionado = false;
    }
    this.etiquetaServicioSeleccionado.splice(index, 1);
    this.servicioSeleccionado.splice(index, 1);
  }

  seleccionarTipoAceite() {
    const tipoAceiteDialog = this.dialog.open(SeleccionarTipoAceiteComponent, {
      width: '400px',
      height: '400px',
    });
    tipoAceiteDialog
      .afterClosed()
      .pipe(takeUntil(this.tipoAceiteDialog$))
      .subscribe((resp) => {
        if (resp) {
          this.lubricanteSeleccionado = {
            id: parseInt(resp.id),
            name: resp.name,
          };
        }
        this.cd.markForCheck();
      });
  }

  traerTaller() {
    this.tallerService
      .getWorkshopById(parseInt(this.idTaller))
      .subscribe((resp) => {
        this.datosTaller = <Workshop>resp;
        this.cd.markForCheck();
        for (const typeService of this.datosTaller.workshopTypeServices!) {
          if (
            typeService.homeService &&
            !typeService.onSiteService &&
            typeService.stateId === 1
          ) {
            this.servicioPrestado = '1';
            this.boolDomicilio = true;
          }
          if (
            !typeService.homeService &&
            typeService.onSiteService &&
            typeService.stateId === 1
          ) {
            this.servicioPrestado = '0';
            this.boolTaller = true;
          }
          if (
            (typeService.homeService &&
              typeService.onSiteService &&
              typeService.stateId === 1) ||
            (this.boolDomicilio && this.boolTaller)
          ) {
            this.servicioPrestado = '';
            return;
          }
        }
      });
  }
  seleccionarUbicacion() {
    const dialogUbicacionActual = this.dialog.open(UbicacionActualComponent, {
      width: '400px',
      height: '400px',
    });
    dialogUbicacionActual
      .afterClosed()
      .pipe(takeUntil(this.dialogUbicacionActual$))
      .subscribe(() => {
        this.guardarUbicacion();
      });
  }

  guardarUbicacion() {
    this.variableGlobal.currentMessage
      .pipe(takeUntil(this.guardarUbicacion$))
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (sessionStorage.getItem('ubicacion')) {
            this.latitud = JSON.parse(
              `${sessionStorage.getItem('ubicacion')}`
            ).latitud;

            this.longitud = JSON.parse(
              `${sessionStorage.getItem('ubicacion')}`
            ).longitud;
          }
        }
      });
  }
  traerDireccion() {
    this.variableGlobal.actualDireccion
      .pipe(takeUntil(this.guardarUbicacion$))
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          if (sessionStorage.getItem('direccion')) {
            this.textoUbicacion = <string>sessionStorage.getItem('direccion');
            this.cd.markForCheck();
          }
        }
      });
  }

  seleccionarMetodoPago() {
    const dialogMetodoPago = this.dialog.open(SelectPaymentMethodComponent);
    dialogMetodoPago
      .afterClosed()
      .pipe(takeUntil(this.paymentMethodDestroy$))
      .subscribe((resp: PaymentMethod) => {
        this.textPaymentMethod = resp.name!;
        this.paymentMethodSelected = resp;
        this.cd.markForCheck();
      });
  }

  validarFormulario() {
    // if (!this.vehiculoSeleccionado) {
    //   this.errorSolicitud.vehiculo = true;
    // } else {
    //   this.errorSolicitud.vehiculo = false;
    // }
    if (this.servicioSeleccionado.length === 0) {
      this.errorSolicitud.servicio = true;
    } else {
      this.errorSolicitud.servicio = false;
    }
    if (this.servicioSeleccionado) {
      if (this.servicioSeleccionado.find((find) => find.id === 4)) {
        if (!this.lubricanteSeleccionado) {
          this.errorSolicitud.lubricante = true;
        } else {
          this.errorSolicitud.lubricante = false;
        }
      }
    }
    if (!this.latitud && !this.longitud) {
      this.errorSolicitud.ubicacion = true;
    } else {
      this.errorSolicitud.ubicacion = false;
    }

    if (this.servicioPrestado.length === 0) {
      if (this.homeService === undefined) {
        this.errorSolicitud.homeService = true;
      } else {
        this.errorSolicitud.homeService = false;
      }
    } else {
      this.errorSolicitud.homeService = false;
    }
  }
  camposSolicitar() {
    let typeService: TypeService[] = [];
    this.servicioSeleccionado.map((servicio) => {
      typeService.push(servicio.typeService!);
    });
    let horaAgendada =
      this.hora.length > 0 && this.fecha.length > 0 ? this.hora : '00:00:30';
    this.solicitarServicio = {
      vehiculo: this.vehiculoSeleccionado,
      servicio: typeService,
      lubricante: this.lubricanteSeleccionado
        ? this.lubricanteSeleccionado
        : { id: 0, name: '' },
      homeService: this.homeService,
      fecha: this.fecha,
      hora: this.hora,
      comentario: this.comentario,
      latitude: this.latitud,
      longitude: this.longitud,
      fechaAgenda:
        this.fecha.length > 0
          ? dayjs(`${this.fecha.replace('-', '/')} ${horaAgendada}`).format()
          : dayjs('01/01/1900 00:00:00').subtract(5, 'h').toString(),
      person: this.persona[0],
    };
  }

  solicitar() {
    this.inactivarBotonSolicitar = true;
    this.guardarUbicacion();
    this.validarFormulario();
    if (
      this.errorSolicitud.vehiculo ||
      this.errorSolicitud.servicio ||
      this.errorSolicitud.lubricante ||
      this.errorSolicitud.ubicacion ||
      this.errorSolicitud.homeService
    ) {
      this.snackBarService.mostrarSnackBar('Verifique los campos esten llenos');
      this.inactivarBotonSolicitar = false;
      return;
    }
    if (isPlatformBrowser(this.platformId)) {
      if (
        this.vehiculoSeleccionado &&
        localStorage.getItem('campos-solicitar')
      ) {
        localStorage.removeItem('campos-solicitar');
      }
    }
    forkJoin({
      serviciosFullpit: this.fullpitService.getByPersonId(
        this.persona[0].id!,
        0,
        100
      ),
    })
      .pipe(takeUntil(this.serviciosFullpit$))
      .pipe(
        map(() => {
          this.camposSolicitar();
          let person: Person = <Person>this.solicitarServicio.person;
          this.fullpit = {
            dateScheduled: dayjs(this.solicitarServicio.fechaAgenda).toDate(),
            additionalServices: this.solicitarServicio.comentario
              ? this.solicitarServicio.comentario
              : '',
            userAddress: this.textoUbicacion,
            latitude: this.solicitarServicio.latitude,
            longitude: this.solicitarServicio.longitude,
            typeServiceList: this.solicitarServicio.servicio,
            vehiculo: {
              id: this.solicitarServicio.vehiculo.id,
            },
            typeLubricant: this.solicitarServicio.lubricante,
            person: {
              id: person.id,
            },
            stateId: EFullpitState.PENDIENTE,
            stateName: 'Pendiente',
            firebaseMessagingToken: this.tokenFirebase,
            cotizacion: false,
            category: this.solicitarServicio.homeService
              ? ECategoryService.A_DOMICILIO
              : ECategoryService.EN_TALLER,
            workshopRequestList: <any>[this.datosTaller.id],
            serviceList: this.etiquetaServicioSeleccionado,
            paymentMethod: this.paymentMethodSelected,
          };
          forkJoin({
            fullpitCreate: this.fullpitService.save(this.fullpit),
          })
            .pipe(
              map((resp) => {
                /*this.fullpitService
                  .updateWorkshopsRequest(resp.fullpitCreate)
                  .subscribe();*/
                this.fullpitService
                  .updateServices(resp.fullpitCreate)
                  .subscribe();
                if (isPlatformBrowser(this.platformId)) {
                  this.inactivarBotonSolicitar = false;
                  location.href =
                    location.origin + '/analitica-solicitar-servicio';
                }
                return { mensaje: 'Se ha creado tu solicitud' };
              })
            )
            .subscribe((respFullpit) => {
              if (respFullpit.mensaje) {
                this.snackBarService.mostrarSnackBar(respFullpit.mensaje);
              }
            });
        })
      )
      .subscribe();
  }

  redimirSaldo() {
    this.bonoFidelizacionService
      .traerFidelizaciones(this.validarToken().user.id)
      .subscribe((resp: Fullpit[]) => {
        if (resp.length > 0) {
          this.bonoFidelizacionService.crearAncla(
            this.validarToken().user.id,
            <number>resp[resp.length - 1].id
          );
        }
        resp.forEach((servicio) => {
          this.bonoFidelizacionService.eliminarFidelizacion(
            this.validarToken().user.id,
            <number>servicio.id
          );
        });
        this.saldoDisponible = {
          acumulable: 0,
          disponible: this.saldoDisponible.disponible,
        };
        this.bonoFidelizacionService.saveAcumuladoDisponible(
          this.validarToken().user.id,
          this.saldoDisponible
        );
      });
  }

  scrollInicio() {
    if (isPlatformBrowser(this.platformId)) {
      $('html, body').animate({
        scrollTop: 0,
      });
    }
  }
}

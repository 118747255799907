import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TypeLubricant, TypeLubricantService } from 'fullpits-commons';

@Component({
  selector: 'app-seleccionar-lubricante',
  templateUrl: './seleccionar-lubricante.component.html',
  styleUrls: ['./seleccionar-lubricante.component.scss'],
})
export class SeleccionarLubricanteComponent implements OnInit, OnDestroy {
  lubricantes: TypeLubricant[] = [];
  constructor(
    private tipoLubricante: TypeLubricantService,
    private dialogRef: MatDialogRef<SeleccionarLubricanteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnDestroy(): void {
    if (this.data) {
      this.dialogRef.close(this.data);
    }
  }

  ngOnInit(): void {
    this.crearTabla();
  }

  crearTabla() {
    this.tipoLubricante.getAll().subscribe((lubricantes: TypeLubricant[]) => {
      this.lubricantes = lubricantes;
    });
  }
  datosSeleccionados() {
    this.dialogRef.close(this.data);
  }
  cerrarDialog() {
    this.dialogRef.close();
  }
}

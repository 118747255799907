<h1 class="fw-bold">
  <a href="javascript: history.go(-1)" class="text-black d-inline d-lg-none"
    ><i class="fa-solid fa-arrow-left"></i
  ></a>
  {{ titulo }}
</h1>
<!-- Interna mis vehículos -->
<ng-container *ngIf="idInterna == 1">
  <ng-container *ngIf="!esVistaAgregarVehiculo; else agregarVehiculo">
    <div class="row flex-column">
      <ng-container *ngFor="let vehiculo of vehiculos; let index = index">
        <div class="col-lg-8 p-1 tarjeta-vehiculos my-2">
          <div
            class="d-flex align-items-center justify-content-start ms-3 gap-13"
          >
            <div>
              <div
                class="circle"
                [style]="{ background: '#' + vehiculo.color }"
              ></div>
            </div>
            <div>
              <p class="mb-1 fw-bold">
                {{ vehiculo.linea!.marca!.name }}, {{ vehiculo.linea!.name }},
                {{ vehiculo.model }}, {{ vehiculo.placa }}
              </p>
            </div>
            <div class="flex-grow-1"></div>
            <div>
              <button
                mat-icon-button
                (click)="openEditVehicle(vehiculo, index)"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </div>
            <div>
              <button
                mat-icon-button
                (click)="openDeleteVehicle(vehiculo, index)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <hr class="my-5" />
      </div>
    </div>
  </ng-container>
  <div class="row">
    <div class="col-lg-8">
      <button
        class="btn btn-negro p-0 px-5 rounded w-100"
        style="box-shadow: none"
        (click)="vistaAgregarVehiculo()"
      >
        {{ textoBoton }}
      </button>
    </div>
  </div>
  <ng-template #agregarVehiculo>
    <div class="row">
      <div class="col-lg-8">
        <form
          [formGroup]="formulario"
          (ngSubmit)="guardarVehiculo()"
          class="my-3"
        >
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">Tipo de vehículo</h2>
            <div
              class="p-1 tarjeta-vehiculos cursor-pointer"
              [ngClass]="{
                invalid:
                  formulario.get('typeVehicle')!.invalid &&
                  formulario.get('typeVehicle')!.touched
              }"
              (click)="dialogDinamico(1)"
            >
              <div
                class="d-flex align-items-center justify-content-between mx-3 gap-13"
              >
                <div>
                  <p class="mb-0 fw-bold">
                    {{
                      textoFormulario.tipoVehiculo != ""
                        ? textoFormulario.tipoVehiculo
                        : "Selecciona el tipo de vehículo"
                    }}
                  </p>
                </div>
                <div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">Marca</h2>
            <div
              class="p-1 tarjeta-vehiculos cursor-pointer"
              [ngClass]="{
                invalid:
                  formulario.get('marca')!.invalid &&
                  formulario.get('marca')!.touched
              }"
              (click)="dialogDinamico(2)"
            >
              <div
                class="d-flex align-items-center justify-content-between mx-3 gap-13"
              >
                <div>
                  <p class="mb-0 fw-bold">
                    {{
                      textoFormulario.marca != ""
                        ? textoFormulario.marca
                        : "Selecciona la marca"
                    }}
                  </p>
                </div>
                <div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">Línea</h2>
            <div
              class="p-1 tarjeta-vehiculos cursor-pointer"
              [ngClass]="{
                invalid:
                  formulario.get('linea')!.invalid &&
                  formulario.get('linea')!.touched
              }"
              (click)="dialogDinamico(3)"
            >
              <div
                class="d-flex align-items-center justify-content-between mx-3 gap-13"
              >
                <div>
                  <p class="mb-0 fw-bold">
                    {{
                      textoFormulario.linea != ""
                        ? textoFormulario.linea
                        : "Selecciona una línea"
                    }}
                  </p>
                </div>
                <div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">Modelo</h2>
            <div
              class="p-1 tarjeta-vehiculos cursor-pointer"
              [ngClass]="{
                invalid:
                  formulario.get('model')!.invalid &&
                  formulario.get('model')!.touched
              }"
              (click)="dialogDinamico(4)"
            >
              <div
                class="d-flex align-items-center justify-content-between mx-3 gap-13"
              >
                <div>
                  <p class="mb-0 fw-bold">
                    {{
                      textoFormulario.modelo != 0
                        ? textoFormulario.modelo
                        : "Selecciona un año"
                    }}
                  </p>
                </div>
                <div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">
              Placa <small>(Opcional)</small>
            </h2>
            <input
              type="text"
              class="form-control"
              [ngClass]="{
                invalid:
                  formulario.get('placa')!.invalid &&
                  formulario.get('placa')!.touched
              }"
              placeholder="Ingresa la placa de tu vehículo"
              formControlName="placa"
            />
            <small
              *ngIf="
                formulario.get('placa')!.invalid &&
                formulario.get('placa')!.touched &&
                formulario.get('placa')!.errors?.maxlength
              "
              ><span class="text-danger"
                >Máximo seis (6) caracteres</span
              ></small
            >
            <small
              *ngIf="
                formulario.get('placa')!.invalid &&
                formulario.get('placa')!.touched &&
                formulario.get('placa')!.errors?.spacing
              "
              ><span class="text-danger">No debe llevar espacios</span></small
            >
          </div>
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">Color</h2>
            <div
              class="p-1 tarjeta-vehiculos cursor-pointer"
              [ngClass]="{
                invalid:
                  formulario.get('color')!.invalid &&
                  formulario.get('color')!.touched
              }"
              (click)="dialogDinamico(5)"
            >
              <div
                class="d-flex align-items-center justify-content-between mx-3 gap-13"
              >
                <div class="flex-grow-1">
                  <ng-container
                    *ngIf="textoFormulario.color != ''; else estaVacio"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <p class="mb-0 fw-bold">Seleccionaste :</p>
                      </div>
                      <div>
                        <div
                          class="circle mx-auto cursor-pointer"
                          [style]="{
                            'background-color': textoFormulario.color
                          }"
                        ></div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #estaVacio>
                    <p class="mb-0 fw-bold">
                      Selecciona el color de tu vehículo
                    </p>
                  </ng-template>
                </div>
                <div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <h2 class="fw-bold my-lg-3 mb-0 mt-3">Combustible</h2>
            <div
              class="p-1 tarjeta-vehiculos cursor-pointer"
              [ngClass]="{
                invalid:
                  formulario.get('fuel')!.invalid &&
                  formulario.get('fuel')!.touched
              }"
              (click)="dialogDinamico(6)"
            >
              <div
                class="d-flex align-items-center justify-content-between mx-3 gap-13"
              >
                <div>
                  <p class="mb-0 fw-bold">
                    {{
                      textoFormulario.fuel != ""
                        ? textoFormulario.fuel
                        : "Selecciona el tipo de combustible"
                    }}
                  </p>
                </div>
                <div>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-naranja p-0 px-5 rounded w-100 mt-5"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ng-container>
<!-- Interna historial -->
<ng-container *ngIf="idInterna == 2"> </ng-container>
<!-- Interna ayuda -->
<ng-container *ngIf="idInterna == 3"> </ng-container>
<!-- Solicitar servicio -->
<ng-container *ngIf="idInterna == 4">
  <div class="row">
    <div class="col-lg-8">
      <form>
        <div class="form-group">
          <div
            class="p-1 tarjeta-vehiculos cursor-pointer"
            [ngClass]="{ invalid: errorsSolicitar.vehiculoId }"
            (click)="dialogDinamicoSolicitarServicio(1)"
          >
            <div class="d-flex align-items-center justify-content-between mx-3">
              <div
                class="d-flex align-items-center justify-content-start gap-13"
              >
                <ng-container
                  *ngIf="
                    textoSolicitarServicio
                      ? textoSolicitarServicio.vehiculo.id != 0
                      : '';
                    else noSeleccionado
                  "
                >
                  <div>
                    <div
                      class="circle"
                      [style]="{
                        background: '#' + textoSolicitarServicio.vehiculo.color
                      }"
                    ></div>
                  </div>
                  <div>
                    <p class="mb-0 fw-bold">
                      {{ textoSolicitarServicio.vehiculo.linea!.marca!.name }},
                      {{ textoSolicitarServicio.vehiculo.linea!.name }},
                      {{ textoSolicitarServicio.vehiculo.model }},
                      {{ textoSolicitarServicio.vehiculo.placa }}
                    </p>
                  </div>
                </ng-container>
                <ng-template #noSeleccionado>
                  <p class="mb-0 fw-bold">Seleccione un vehículo</p>
                </ng-template>
              </div>
              <div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group border-top my-4"></div>
        <div class="form-group">
          <div
            class="p-1 tarjeta-vehiculos cursor-pointer"
            [ngClass]="{ invalid: errorsSolicitar.servicio }"
            (click)="dialogDinamicoSolicitarServicio(2)"
          >
            <div
              class="d-flex align-items-center justify-content-between mx-3 gap-13"
            >
              <div>
                <p class="mb-0 fw-bold">
                  Selecciona los servicios que necesitas
                </p>
              </div>
              <div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center gap-13 flex-wrap my-3">
            <ng-container
              *ngFor="
                let servicio of textoSolicitarServicio.servicio;
                let i = index
              "
            >
              <ng-container *ngIf="servicio.name != ''">
                <div
                  class="d-flex align-items-center justify-content-between gap-13 border px-2"
                >
                  <div>{{ servicio.name }}</div>
                  <div>
                    <i
                      class="fas fa-times cursor-pointer"
                      (click)="eliminarServicioSeleccionado(i)"
                    ></i>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="form-group">
          <ng-container
            *ngFor="let servicio of textoSolicitarServicio.servicio"
          >
            <ng-container *ngIf="servicio.id === 4">
              <div
                class="p-1 tarjeta-vehiculos cursor-pointer"
                [ngClass]="{ invalid: errorsSolicitar.lubricanteId }"
                (click)="dialogDinamicoSolicitarServicio(3)"
              >
                <div
                  class="d-flex align-items-center justify-content-between mx-3 gap-13"
                >
                  <div>
                    <p class="mb-0 fw-bold">
                      {{
                        textoSolicitarServicio.lubricante.id !== 0
                          ? textoSolicitarServicio.lubricante.name
                          : "Tipo de lubricante"
                      }}
                    </p>
                  </div>
                  <div>
                    <i class="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="form-group">
          <h1 class="mt-lg-3 mb-lg-1 mb-0 mt-3">
            ¿Donde quieres tus servicios?
          </h1>
          <div class="d-flex align-items-center gap-13">
            <mat-radio-group
              [(ngModel)]="textoSolicitarServicio.homeService"
              name="homeService"
            >
              <div class="d-flex px-3 pb-3 pt-2 gap-13">
                <mat-radio-button
                  [ngClass]="{ invalid: errorsSolicitar.homeService }"
                  [value]="1"
                  >Domicilio</mat-radio-button
                >
                <mat-radio-button
                  [ngClass]="{ invalid: errorsSolicitar.homeService }"
                  [value]="0"
                  >En taller o lavadero</mat-radio-button
                >
              </div>
            </mat-radio-group>
          </div>
        </div>
        <div class="form-group">
          <div
            class="p-1 tarjeta-vehiculos cursor-pointer"
            [ngClass]="{ invalid: errorsSolicitar.ubicacion }"
            (click)="abrirDialog()"
          >
            <div
              class="d-flex align-items-center justify-content-between mx-3 gap-13"
            >
              <div class="d-flex align-items-center gap-13">
                <div>
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div>
                  <p class="mb-0 fw-bold">{{ textoUbicacion }}</p>
                </div>
              </div>
              <div>
                <i class="fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <h1 class="mt-lg-3 mb-lg-1 mb-0 mt-3">
            ¿Necesitas otro servicio? <small>(Opcional)</small>
          </h1>
          <textarea
            cols="30"
            rows="10"
            [(ngModel)]="textoSolicitarServicio.comentario"
            name="comentario"
            placeholder="Escribe que otro servicio necesitas o añade comentarios"
            class="form-control"
            style="height: 120px"
          ></textarea>
        </div>
        <div class="form-group my-3">
          <h1 class="mb-1">
            ¿Para cuando necesitas el servicio? <small>(Opcional)</small>
          </h1>
          <div class="row">
            <div class="col-6">
              <input
                type="date"
                placeholder="dd/mm/aaaa"
                [(ngModel)]="textoSolicitarServicio.fecha"
                name="fecha"
                onchange="this.className=(this.value!=''?'has-value p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3':'')"
                [min]="minimaFecha"
                class="p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3"
              />
            </div>
            <div class="col-6">
              <input
                type="time"
                *ngIf="this.textoSolicitarServicio.fecha.length > 0"
                [(ngModel)]="textoSolicitarServicio.hora"
                name="hora"
                placeholder="hh:mm"
                class="p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3"
                onchange="this.className=(this.value!=''?'has-value p-1 tarjeta-vehiculos cursor-pointer border-0 w-100 px-3':'')"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <ng-container *ngIf="idAdmin">
            <button
              class="btn btn-negro p-0 px-lg-5 px-3 rounded w-100 d-lg-block d-none"
              (click)="buscarTalleres(1)"
              style="box-shadow: none"
            >
              <div
                class="d-flex align-items-center justify-content-center gap-13"
              >
                <div>
                  <img
                    src="./assets/images/home/lupa.webp"
                    alt="lupa"
                    class="img-fluid icon-lupa"
                  />
                </div>
                <div>
                  <span>Buscar lista de puntos cercanos</span>
                </div>
              </div>
            </button>
            <button
              class="btn btn-negro p-0 px-lg-5 px-3 rounded w-100 d-lg-none d-block"
              (click)="buscarTalleres(1)"
              style="box-shadow: none"
            >
              <div
                class="d-flex align-items-center justify-content-center gap-13 lh-1"
                style="font-size: small"
              >
                <div>
                  <img
                    src="./assets/images/home/lupa.webp"
                    alt="lupa"
                    class="img-fluid icon-lupa"
                  />
                </div>
                <div>
                  <span>Buscar lista de puntos cercanos</span>
                </div>
              </div>
            </button>
          </ng-container>
        </div>
        <div
          class="text-center my-3 d-flex justify-content-center align-items-center"
        >
          <div class="linea-horizontal">
            <hr />
          </div>
          <div class="px-2">o</div>
          <div class="linea-horizontal"><hr /></div>
        </div>
        <div class="form-group">
          <button
            class="btn btn-naranja p-0 px-lg-5 px-3 rounded w-100 d-lg-block d-none"
            style="box-shadow: none"
            [disabled]="inactivarBotonSolicitar"
            (click)="solicitarServicio()"
          >
            <div class="d-flex justify-content-center align-items-center gap-3">
              <div *ngIf="!inactivarBotonSolicitar">
                <img
                  src="./assets/images/solicitar_servicio/puntos_cercanos.webp"
                  alt="puntos_cercanos"
                  class="img-fluid icon-solicitar"
                />
              </div>
              <div>
                <ng-container *ngIf="!inactivarBotonSolicitar; else loading">
                  <span>Enviar solicitud a todos los puntos cercanos</span>
                </ng-container>
                <ng-template #loading>
                  <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </button>
          <button
            class="btn btn-naranja p-0 px-lg-5 px-3 rounded w-100 d-lg-none d-block"
            style="box-shadow: none"
            (click)="solicitarServicio()"
            [disabled]="inactivarBotonSolicitar"
          >
            <div
              class="d-flex justify-content-center align-items-center gap-3 lh-1"
              style="font-size: small"
            >
              <div *ngIf="!inactivarBotonSolicitar">
                <img
                  src="./assets/images/solicitar_servicio/puntos_cercanos.webp"
                  alt="puntos_cercanos"
                  class="img-fluid icon-solicitar"
                />
              </div>
              <div>
                <ng-container *ngIf="!inactivarBotonSolicitar; else loading">
                  <span>Enviar solicitud a todos los puntos cercanos</span>
                </ng-container>
                <ng-template #loading>
                  <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ETallerSinImagen, Workshop } from 'fullpits-commons';
import { MatDialog } from '@angular/material/dialog';
import { DialogOpcionSolicitudComponent } from 'src/app/modulos/dialog/dialog-opcion-solicitud/dialog-opcion-solicitud.component';
import { Router } from '@angular/router';
import { DistanciaKilometrosService } from './distancia-kilometros.service';
import { environment } from 'src/environments/environment';
import { UbicacionResponse } from '../../../models/ubicacion.response';

@Injectable({
  providedIn: 'root',
})
export class ListadosTalleresService {
  idAdmin: string = '';
  distanciaKilometros: number = 0;
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private dialog: MatDialog,
    private router: Router,
    private distanciaKilometrosService: DistanciaKilometrosService
  ) {}

  ifExistAdmin() {
    if (isPlatformBrowser(this.platformId)) {
      this.idAdmin = <string>sessionStorage.getItem('idAdmin');
    }
  }

  getIdAdmin() {
    return this.idAdmin;
  }

  getSortWorkshops(talleresCercanos: Workshop[]) {
    if (talleresCercanos.length > 0) {
      talleresCercanos.sort((a, b) => {
        if (a.distanciaReal! > b.distanciaReal!) {
          return 1;
        }
        if (a.distanciaReal! < b.distanciaReal!) {
          return -1;
        }
        return 0;
      });
    }
    return talleresCercanos;
  }

  profileNoFound(taller: Workshop) {
    if (!taller.profilePicture) {
      taller.profilePicture = ETallerSinImagen.getImagenCategoria(
        taller.bussinessTypeList![0].id!
      );
    }
  }

  changeDistance(taller: Workshop, ubicacionActual: UbicacionResponse) {
    this.distanciaKilometros = this.distanciaKilometrosService.getKilometros(
      taller.latitude!,
      taller.longitude!,
      ubicacionActual.latitud,
      ubicacionActual.longitud
    );
    if (this.distanciaKilometros <= environment.distancia) {
      if (this.distanciaKilometros > 1) {
        taller.distancia = this.distanciaKilometros + 'Km';
        taller.distanciaReal = this.distanciaKilometros;
        taller.esCercano = true;
      } else {
        taller.distancia = this.distanciaKilometros * 1000 + 'm';
        taller.distanciaReal = this.distanciaKilometros;
        taller.esCercano = true;
      }
    } else {
      taller.distanciaReal = this.distanciaKilometros;
      taller.esCercano = false;
    }
    return taller;
  }

  filterWorkshops(talleres: Workshop[], talleresBkp: Workshop[]) {
    let talleresFiltrados: Workshop[] = [];
    let isTrue: boolean = false;
    talleresBkp = talleres;
    talleres.map((taller) => {
      taller.workshopTypeServices?.find((domicilio) => {
        if (domicilio.homeService && !isTrue) {
          isTrue = true;
          talleresFiltrados.push(taller);
        }
      });
      isTrue = false;
    });
    talleres = talleresFiltrados;

    return talleres;
  }

  traerOpcionSolicitud(nombreTaller: string, id: number, servicio?: string) {
    if (this.idAdmin) {
      this.dialog
        .open(DialogOpcionSolicitudComponent, {
          width: '400px',
          height: '400px',
          data: {
            nombreTaller: nombreTaller.replace(/&/g, '%26').replace(/ /g, '+'),
            servicio: servicio?.replace(/ /g, '+'),
          },
        })
        .afterClosed()
        .subscribe((resp) => {
          if (resp) {
            this.router.navigateByUrl(`/solicitar-servicio/${id}`);
          }
        });
    } else {
      window.open(
        `https://api.whatsapp.com/send/?phone=573016116589&text=%C2%A1Hola+%2Afullpits%2A%21%0ANecesito+cotizar+o+solicitar+un+servicio+${
          nombreTaller
            ? 'en+%2A' +
              nombreTaller.replace(/&/g, '%26').replace(/ /g, '+') +
              '%2A'
            : ''
        }.&app_absent=0`,
        '_blank'
      );
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-texto-dinamico',
  templateUrl: './texto-dinamico.component.html',
  styleUrls: ['./texto-dinamico.component.scss'],
})
export class TextoDinamicoComponent implements OnInit {
  @Input() textoDinamico: string;
  constructor() {}

  ngOnInit(): void {}
}

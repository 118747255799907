<div class="d-flex justify-content-end align-items-center">
  <div>
    <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
      <i class="far fa-times-circle mb-0 h5"></i>
    </a>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let servicio">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(servicio) : null"
            [checked]="selection.isSelected(servicio)"
            [aria-label]="checkboxLabel(servicio)"
            >{{ servicio.title }}</mat-checkbox
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nombre">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let servicio"> </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columns"></mat-row>
    </mat-table>
  </div>
</div>
<div class="row fixed-bottom bg-white d-lg-none d-block">
  <div class="col-lg-12 d-flex justify-content-end align-items-center">
    <div class="flex-grow-1">
      <button
        mat-button
        color="warn"
        class="w-100"
        type="button"
        (click)="cerrarDialog()"
      >
        Cancelar
      </button>
    </div>
    <div class="flex-grow-1">
      <button
        mat-raised-button
        color="warn"
        class="w-100"
        type="button"
        (click)="datosSeleccionados()"
      >
        Aceptar
      </button>
    </div>
  </div>
</div>

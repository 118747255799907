<div class="container-fluid">
  <div class="row justify-content-end align-items-center">
    <div class="col-1">
      <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
        <i class="far fa-times-circle mb-0 h5"></i>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <h1>Términos y condiciones del BONO</h1>
      <ul>
        <li>
          Este BONO aplica para cualquier servicio agendado y prestado a través
          de fullpits.com. En cualquiera de nuestros negocios aliados.
        </li>
        <li>
          El valor del BONO, no será entregado en efectivo, solo será descontado
          del monto total del servicio prestado.
        </li>
        <li>
          Si el valor del servicio, es inferior al valor del BONO. Solo se
          cubrirá el 100% del servicio.
        </li>
        <li>
          El número de BONOS es limitado, una vez se completen, se quitará la
          promoción de fullpits.com.
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-end align-items-center">
    <div class="col-1">
      <a class="cursor-pointer text-naranja" (click)="cerrarDialog()">
        <i class="far fa-times-circle mb-0 h5"></i>
      </a>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <h1>T&C BONO DE FIDELIZACIÓN</h1>
      <ul>
        <li>
          El saldo acumulado en los primeros cinco (5) servicios, estará
          disponible para usar a partir del sexto (6) servicio.
        </li>
        <li>
          Después del quito servicio, el usuario puede decidir cuando usar el
          saldo, si prefiere puede seguir acumulando saldo y utilizarlo en el
          servicio que quiera.
        </li>
        <li>
          Una vez se utilice el saldo acumulado, se reiniciará el conteo de los
          cinco (5) servicios.
        </li>
        <li>
          Si aplica el descuento en un servicio, donde el saldo acumulado es
          mayor al valor del servicio. El saldo restante le quedará en la cuenta
          para que lo pueda gastar en otros servicios cuando lo desee.
        </li>
      </ul>
    </div>
  </div>
</div>

import {
  Component,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Servicio, WorkshopsService } from 'fullpits-commons';
import { Inject, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-asinar-servicios',
  templateUrl: './asinar-servicios.component.html',
  styleUrls: ['./asinar-servicios.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsinarServiciosComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['select', 'titulo', 'precio'];
  dataSource: MatTableDataSource<Servicio>;
  selection = new SelectionModel<Servicio>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<AsinarServiciosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Servicio[],
    private workshopsService: WorkshopsService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnDestroy(): void {
    if (this.selection.selected.length > 0) {
      this.dialogRef.close(this.selection.selected);
    }
  }
  ngOnInit(): void {
    this.getServices();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Servicio): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id! + 1
    }`;
  }

  getServices() {
    this.workshopsService.getworkShopTypeService(1, 0, 0).subscribe((resp) => {
      const val = resp.filter(
        (filter) => !this.data.some((obj) => obj.id === filter.id)
      );
      this.dataSource = new MatTableDataSource(val);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.cd.markForCheck();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

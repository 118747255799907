<div
  class="offcanvas offcanvas-start w-lg-84"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <div class="offcanvas-header align-items-start pb-0">
    <div>
      <img
        src="./assets/iconos-header/logo-naranja-fullpits.webp"
        alt="logo-naranja-fullpits"
        class="img-fluid"
        width="150px"
      />
    </div>
    <div>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="offcanvas-body pt-0">
    <div
      class="d-flex align-items-center justify-content-center flex-wrap"
      style="gap: 13px"
    >
      <div>
        <img
          src="./assets/images/home/sin_foto_perfil.png"
          alt="sin_foto"
          class="img-fluid rounded-circle"
          width="77px"
        />
      </div>
      <div>
        <ng-container *ngIf="usuario">
          <span class="h3 fw-bold">{{
            usuario.nombres + " " + usuario.apellidos
          }}</span>
          <span class="h6 fw-bold d-block correo-usuario">{{
            usuario.email
          }}</span>
        </ng-container>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div>
        <ul class="nav flex-column my-5 h5 w-75">
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['']"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Inicio
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/mi-perfil']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Mi perfil
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/mis-servicios']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Mis servicios
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/mis-vehiculos']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Mis vehículos
            </a>
          </li>
          <li class="ms-3">
            <hr class="linea-menu-lateral" />
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/talleres-para-carros']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Talleres para carros
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/autolavados']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Autolavados
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/talleres-para-motos']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Talleres para motos
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/motolavados']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Motolavados
            </a>
          </li>
          <li class="ms-3">
            <hr class="linea-menu-lateral" />
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/registra-tu-negocio']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Registra tu negocio
            </a>
          </li>
          <li class="ms-3">
            <hr class="linea-menu-lateral" />
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/privacidad']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Privacidad
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/ayuda']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Ayuda
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-black cursor-pointer"
              [routerLink]="['/informacion/terminos-y-condiciones']"
              routerLinkActive="active"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Información
            </a>
          </li>
          <li class="ms-3">
            <hr class="linea-menu-lateral" />
          </li>
          <li class="nav-item">
            <ng-container *ngIf="usuario; else ingresar">
              <div
                class="nav-link text-black cursor-pointer"
                (click)="cerrarSesion()"
              >
                Cerrar sesión
              </div>
            </ng-container>
            <ng-template #ingresar>
              <a class="nav-link text-black" [href]="urlLogin">Ingresar</a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { RegistraTuNegocio } from 'src/app/models/registraNegocio';
import { ServiciosHome } from 'src/app/models/servicios-home';

@Component({
  selector: 'app-img-circle',
  templateUrl: './img-circle.component.html',
  styleUrls: ['./img-circle.component.scss'],
})
export class ImgCircleComponent implements OnInit {
  @Input() boolRegistraNegocio: boolean;
  @Input() serviciosHome: ServiciosHome[];
  @Input() registraNegocio: RegistraTuNegocio[];
  constructor() {}

  ngOnInit(): void {}
}

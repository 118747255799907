<audio [id]="'medio' + id">
  <source [src]="audio.message" type="audio/mpeg" />
</audio>
<div class="row align-items-center">
  <div class="col-2 p-0">
    <button
      class="btn-link btn p-0 text-black"
      type="button"
      (click)="reproducirAudio('medio' + id)"
    >
      <mat-icon>{{ iconoAudio }}</mat-icon>
    </button>
  </div>
  <div class="col-10 p-0 mb-1">
    <div [id]="'barra' + id" class="progress" (click)="moveProgressBar($event)">
      <div
        class="progress-bar bg-naranja transition-bar"
        role="progressbar"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
        [id]="'progreso' + id"
      ></div>
    </div>
  </div>
</div>
